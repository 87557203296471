import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider, useSelector } from 'react-redux';
import { createStore as reduxCreateStore } from 'redux';
import rootReducer from './state';

class Refocus extends React.Component {

    componentDidUpdate() {
        
        var p = document.getElementById('Fnode');
      if (p) {
        p.focus();
      }
    }
  
    render() {
      return <div id="Fnode"  tabIndex={-1}>{this.props.children}</div>;
    }
  }

ReactDOM.render(
<Provider store={reduxCreateStore(rootReducer)}>
    
        <App />
    
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
