import React from 'react';
import { Link } from 'react-router-dom';
import {doGet, competitionsUrl} from '../../data/Protocol'
import "../../css/layout.css"
import { connect } from 'react-redux';
import { IonItem, IonLabel, IonList, IonLoading } from '@ionic/react';
import { addCompetitionToFavorites, getCompetitionsFavorites, isCompetitionFavorite } from '../../data/Favorites';

///////////////////////////     COMPONENT

class CompetitionsList extends React.Component {
  constructor(props) {
    super(props);
      
    this.state = {
      showLoading: true,
      nationals: [],
      internationals: [],
      selected_country: -1
    }

  }

  componentDidMount = () => {
    console.log("COMPETITIONS " + this.state.nationals.length + " - " + this.state.internationals.length)
    if (this.state.nationals.length === 0 && this.state.internationals.length === 0){
      this.getCompetitions(); 
      this.setState({selected_country: 0})  
    }
  }

  getCompetitions = async () => {    
    doGet(competitionsUrl(), this) 
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }


  networkSuccess(url, resp) { 
    
    if (resp != null){
      this.setShowLoading(false)
      if (resp.result && resp.result.countries && resp.result.countries.list){
        var countries = resp.result.countries.list
        var comps = resp.result.competitions.list
        var nationals = []
        var internationals = []


        comps.map(c => {
            var cc = countries.find(e => e.id === c.country_id);
            if (!cc.competitions)
              cc.competitions = []
            cc.competitions.push(c)
          }
        )

        var index = 0
        var p 
        countries.map(c => {
          if (!c.popularity)
            c.popularity = 0
          else
            c.popularity = Number(c.popularity)
          if (c.national){
            for (index = 0; index < nationals.length; index++) {
              p = nationals[index]
              
              if (c.popularity < p.popularity){  
                continue
              }else{
                if (c.popularity === p.popularity){  
                  if (p.name > c.name )
                    break
                }else{
                    break
                }
              }
            }
            nationals.splice(index, 0, c);

            //nationals.push(c)
          }else{
            for (index = 0; index < internationals.length; index++) {
              p = internationals[index]
              if (c.popularity < p.popularity){  
                continue
              }else{
                if (c.popularity === p.popularity){  
                  if (p.name > c.name )
                    break
                }else{
                    break
                }
              }
            }
            internationals.splice(index, 0, c);

            //internationals.push(c)
          }
        }
      )

      this.setState({
          nationals: nationals,
          internationals: internationals
        })
  
      }
    }
  }

  networkError(url,status){
  }

  openCountry(id){
    if (id === this.state.selected_country)
      this.setState({selected_country: 0})
    else
      this.setState({selected_country: id})
  }


  renderRow(country, i){
    const { favorites } = this.props

    return ( 
      <IonList key={i} style={{background: '#fff'}} lines="none" mode="ios">
        <IonItem key={"c" + i} className="no-padding" color="#fff" onClick={() => this.openCountry(country.id)}>
          <img className="countryImg" src={country.flag_url_medium} alt=""/>
          <p className="countryName">{country.name}</p>
        </IonItem>
        {country.id === this.state.selected_country && country.competitions.map((cc, i) => 
          <div key={"t" + i} style={{display: 'flex', height:'34px', alignItems:'center'}}>
            <svg onClick={() => addCompetitionToFavorites(this, cc)} style={{marginLeft: '5px'}} xmlns="http://www.w3.org/2000/svg" fill={isCompetitionFavorite(favorites, cc.id) ? "#0B1F64" : "#fff"} viewBox="0 0 24 24" stroke="#0B1F64" >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>           
            <Link style={{width: '100%', height:'34px', display: 'flex', alignItems:'center'}} to={"/competition/" + cc.id}>
              <IonLabel className="competitionName">
                <span>{cc.name}</span>
              </IonLabel>
            </Link>            
          </div>
        )}
      </IonList>            
    )
  }

  printFavorite(competition){
    const { nationals, internationals } = this.state
    var comp = null
    var country
    
    var i = 0

    for (i = 0; i < nationals.length; i++){
      comp = nationals[i].competitions.find(e => e.id === competition);
      if ( comp ){
        country =  nationals[i]
        break
      }
    }
    
    if (!comp){
      for (i = 0; i < internationals.length; i++){
        comp = internationals[i].competitions.find(e => e.id === competition);
        if ( comp ){
          country =  internationals[i]
          break
        }
      }  
    }

    if (comp)
    return(
      <IonItem key={competition} className="no-padding" color="#fff">
        <Link key={comp.id} style={{width: '100%', display:'flex'}} to={"/competition/" + comp.id}>
          <img className="countryImg" src={country.flag_url_medium} alt=""/>
          <p className="competitionFavName">{country.name} - <span>{comp.name}</span></p>
        </Link>
        <svg slot="end" onClick={() => addCompetitionToFavorites(this, comp)} style={{marginLeft: '5px'}} xmlns="http://www.w3.org/2000/svg" fill={"#0B1F64"} viewBox="0 0 24 24" stroke="#0B1F64" >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
              </svg>
      </IonItem>
    )
    
  }

  showFavorites(){
    const { favorites } = this.props
    var competitions = getCompetitionsFavorites(favorites)

    if (competitions && competitions.length > 0){
      return(
        <div>
          <h1>
            <div>Favorites</div>
          </h1>
          <IonList style={{background: '#fff'}} lines="none" mode="ios">
            {competitions.map(c => this.printFavorite(c) )}
          </IonList>
        </div>
      )
    }
  }

  render_explore(){
    const { update_favorites } = this.props
    const { nationals, internationals } = this.state

      return (
        <div style={{margin: "0px 5px 15px"}} key={update_favorites} className="content">
          {this.showFavorites()}
          <h1>
            <div>Internationals</div>
          </h1>
          {internationals.map((country,i) => 
            this.renderRow(country, i)
          )}
          <h1>
            <div>Nationals</div>
          </h1>
          {nationals.map((country, i) => 
            this.renderRow(country, i)
          )}
        </div>
      );    
  }

  render() {
    const { nationals, internationals } = this.state
    if (nationals.length > 0 || internationals.length > 0){
      return (this.render_explore())
    }

      return (
        <IonLoading
          cssClass='my-custom-class'
          isOpen={this.state.showLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={'loading...'}
          duration={5000}
        />
      );

    }
} // class

export default connect(state => ({
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites,
}), null)(CompetitionsList)

//export default MatchList
