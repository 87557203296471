//import { Link } from 'gatsby';
import React from 'react';
import { Link } from 'react-router-dom';
import Match from "../Matches/Match";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet"
import ReactDOM from 'react-dom'

export const compareCompetitions = (comp1, comp2)=>{

  if (comp1.id === comp2.id){
    return 0;
  }
  if (comp1.popularity > 200){
    if (comp1.popularity > comp2.popularity){
      return -1;
    }else if (comp1.popularity === comp2.popularity){
      if (comp1.country.popularity > comp2.popularity){
        return -1;
      }else if (comp1.country.popularity === comp2.popularity){
        return (comp1.country.name <= comp2.country.name) ? -1 : -2;
      }
    }else{
      if (comp2.popularity < 200){
        return -1;
      }
    }
  }else {
    if (comp2.popularity > 200){
      return 1;
    }
    if (comp1.country.name < comp2.country.name){
      return -1
    } else if (comp1.country.name === comp2.country.name)
      if (comp1.name < comp2.name){
        return -1
      }
  }
  return 1;
}

export const completeCompetition = (c, countries)=>{
  const country = countries.find(e => e.id === c.country_id);
  c.country = country || "";
  c.matches = new Map();
  c.n_matches = 0;
  if (c.popularity === undefined)
    c.popularity = 0
}

export const completeLeague = (l, competitions)=>{
  const c = competitions.find(e => e.id === l.competition_id);
  l.competition = c || "";
}


class Competition extends React.Component {

  constructor(props) {
    super(props);
    var s = this.props.showMatches
    this.state = {
      show: s,
    }
  }

  isFavorite(id) {
    const {favorites} =  this.props
  
    if (favorites && favorites.matches){
      const i = favorites.matches.competitions.findIndex((e) => e.id === id);
      if (i >= 0){
        return true
      }
    }
  
    return false
  }



  render() {
   const { competition, matches, liveOnly, showFavorites, day } = this.props;  
   //console.log("print Comp " + competition.name + " -> " + competition.country.flag_url_medium ); //JSON.stringify(matches))
   //console.log("print Comp " + competition.name); //JSON.stringify(matches))
   //console.log("COMPETITION " + JSON.stringify(competition, null, 2))

   if (showFavorites && !this.isFavorite(competition.id))
    return null;

    if (liveOnly && ((matches[0].length) < 1))
      return null;
    var n_matches = matches[0].length + matches[1].length + matches[2].length;
    if (liveOnly && n_matches < 1)
      return null;

    var table_url = "/competitionTable/" + competition.id ;

    if (!this.state.show)
    return null;

    if (liveOnly){
      return (
        <div style={{margin: '0 5px 10px 5px'}}>
          <Link to={table_url}>
            <h1>
            <strong>{competition.country.sname}</strong> - {competition.name}          
            </h1>
          </Link>      

          {matches[0].map((match, index)=><Match key={match.id+"_"+match.updated} match={match} i={index} showFavorites={showFavorites}/>)}      
        </div>
      )
    }

    var n_live  = matches[0].length;
    var n_sched = matches[1].length;
    
    return (
      <div style={{margin: '0 5px 15px 5px'}}>
        <Link to={table_url}>
          <h1>
            <img width="20px" src={competition.country.flag_url_medium} alt=""></img>
            {competition.country.sname} {competition.name}          
          </h1>
          </Link>      

          {!liveOnly && matches[0].map((match, index)=><Match key={match.id+"_"+match.updated} match={match} i={index} showFavorites={showFavorites}/>)}    
          {matches[1].map((match, index)=><Match key={match.id+"_"+match.updated} match={match} i={index+n_live} showFavorites={showFavorites}/>)}
          {!liveOnly && matches[2].map((match, index)=><Match key={match.id+"_"+match.updated} match={match} i={index+n_live+n_sched} showFavorites={showFavorites}/>)}
      </div>
    )
  }
}

export default connect(state => ({
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites
}), null)(Competition)

//export default Competition;
