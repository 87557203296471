import React from 'react';
import "../../css/layout.css"
import {doGet, competitionMatchesUrl} from '../../data/Protocol'
import { connect } from 'react-redux';
import { completeCompetition, completeLeague } from "../Competitions/Competition";
import { completeMatch, findMatch } from "../../data/MatchData";
import { completeTeam } from "../Teams/Team";
import Match from '../Matches/Match';
import { format } from "date-fns";
import MatchLight from '../Matches/MatchLight';

///////////////////////////     COMPONENT

const addDay = (day, date, days, type) => {
  for (var index = 0; index < days.length; index++) {
    var dist // = formatDistance(date, days[index][1], { addSuffix: true })
    dist = date - days[index][1]
    console.log("DAY " + day  + " - " + days[index][0] + " ==> " + dist)

    if (type === 0){
      if (dist > 0)
        break;
    }else{
      if (dist < 0)
        break;
    }
  }

  days.splice(index, 0, [day,date]);
  //days.push(day)
}

class CompetitionMatches extends React.Component {
  constructor(props) {
    super(props);
      
    this.state = {
      matches: null,
      start_day: 0
    }
  }

  componentDidMount = () => {
    if (this.props.resp){
      this.getMatches(this.props.resp)
    }else{
      this.getCompetitionMatches();  
    }
  }

  getCompetitionMatches = async () => {    
    doGet(competitionMatchesUrl(this.props.id), this) 
  }

  getMatches(resp){
    var HashMap = require('hashmap');
    var days_matches = new HashMap()
    var days = []
    var start_day = 0
    var today = new Date();
    var actual_diff = 10000000000

    let competitions = resp.result.competitions.list;
    let leagues = resp.result.leagues.list;
    let countries = resp.result.countries.list;
    let teams = resp.result.teams.list;

    let compname = null

    competitions.forEach(c => {      
      completeCompetition(c, countries);
    });

    leagues.forEach(l => {
        completeLeague(l, competitions);
    });

    teams.forEach(t => {
        completeTeam(t, countries);
    });



    resp.result.matches.list.forEach(m => {
      var epoch = new Date(m.start_date * 1000);
      var offset = epoch.getTimezoneOffset() * 60;
      var start_ts = new Date((m.start_date-offset) * 1000)
      var start = start_ts.toISOString().slice(0, 10);
      var diff = start_ts - today
      var check = 1

      if (diff >= 0 && this.props.type === 0)
        check = 0

      if (diff < 0 && this.props.type === 1)
        check = 0

      if (check > 0){
        if ( Math.abs(diff) < actual_diff ){
          start_day = start
          actual_diff = Math.abs(diff)
        }

        completeMatch(m, resp.result);
        
        if (days_matches.get(start)){
          var list = days_matches.get(start)
          list.push(m)
          days_matches.set(start, list);
        }else{
          var dm = []
          dm.push(m)
          days_matches.set(start, dm);
          addDay(start, start_ts, days, this.props.type)
        }  
      }
    })

    this.props.cb(this.props.context, resp, competitions[0].name)

    this.setState({
      matches: days_matches,
      days: days,
      start_day: start_day
    })

    //this.setState({matches: resp.result.matches.list})
  }
  
  networkSuccess(url, resp) { 
    if (resp !== null){
      this.getMatches(resp)
    }
  }

  networkError(url,status){
  }

  scrollToToday(){
    let y = document.getElementById(this.state.start_day).offsetTop;
    let content = document.querySelector("ion-content");
    content.scrollToPoint(0, y);
  }

  //           <IonButton onClick={() => this.gotoButton()}>GOTO ROW</IonButton>

  gotoButton = () => {
    
    //let y = document.getElementById(this.state.start_day).offsetTop;
    let content = document.querySelector("ion-content");
    content.scrollToBottom()
    
  };

  render() {
    const {matchesKickOff, update} =  this.props
    
    if (this.state.matches && matchesKickOff){

      this.state.matches.forEach(function(matches, day) {
        
        for (var mindex = 0; mindex < matches.length; mindex++) {
          var found = matchesKickOff[0].find(m=> m.id === matches[mindex].id)
          if (found){
            console.log("LAST_RESULT COMPS match found " + matches[mindex].id)
            matches[mindex] = found
          }
        }
      });
    }

    return (
        <div className="content" key={update}>
        {this.state.matches && 
          <div id="contentList">
          {
            this.state.days.map( (d , i) => 
              <div key={i} style={{margin: "0px 5px 15px"}}>
                <h1 id={d[0]}>{format(d[1], "dd.MM.yyyy")}</h1>
                {
                  this.state.matches.get(d[0]).map((match, index) => 
                    <MatchLight id={match.id} key={match.id} match={match} i={index}/>
                  )
                }
              </div>
              )
          }
          </div>
        }
        {!this.state.matches && <p>loading</p>}
        </div>      
      );
  }
} // class

export default connect(state => ({
  update: state.app.update,
  matchesKickOff: state.app.kickoffMatches,
}), null)(CompetitionMatches)

//export default MatchList

