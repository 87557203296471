import React from 'react';
import { connect } from 'react-redux';
import { informationCircle, checkmarkCircle } from 'ionicons/icons';
import icon_arrow from "../../images/icon_tick.png"
import Layout from '../layout';
import TabsHeader from '../TabsHeader';
import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { addCompetitionToFavorites, isCompetitionFavorite } from '../../data/Favorites';
import { addCompetitionToFilter, isCompetitionFiltered } from '../../data/Filters';
import CompetitionsFiltered from './CompetitionsFiltered';

class CompetitionsFilter extends React.Component {

  constructor(props){
    super(props)

    this.state={
      tab: 0,
    }
  }


  setTab(param){
    param[0].setState({tab: param[1]})
  }

  getToday(){
    return (
    <div key={this.props.update_filters} className="content">
      <h1>Competitions</h1>
      {this.props.orderedCompetitionsIDWithMatches.map(c => {            
          let filtered = false
          let selected = ""
          if (isCompetitionFiltered(this.props.filters, c.id)){
            filtered = true
            selected = "selected"
          }

          return <IonItem className="item">
            <IonIcon size="small" onClick={() => addCompetitionToFilter(this, c.competition)} className="padding-left" color={ filtered ? "medium" : "secondary"} icon={checkmarkCircle}></IonIcon>
            <img className="countryImg padding-left" src={c.competition.country.flag_url_medium} alt=""/>
            <IonLabel className={"competitionName " + selected}>{c.competition.name} - <span className="country no-padding">{c.competition.country.name}</span></IonLabel>
            <svg slot="end" xmlns="http://www.w3.org/2000/svg" fill={isCompetitionFavorite(this.props.favorites, c.id) ? "#0B1F64" : "#fff"} viewBox="0 0 24 24" stroke="#0B1F64" onClick={() => addCompetitionToFavorites(this, c.competition)}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>
          </IonItem>
        }
      )}
    </div>

    )
  }


  render() {
    const { closeAction, context } = this.props

    var close = { 
      icon: icon_arrow,
      text: "live",
      action: closeAction,
      context: context
    }

    var buttons = []
    buttons.push(close);
    
    var tab1 ={ 
      name: "Today",
      cb: this.setTab,
      params: [this, 0],
      selected: (this.state.tab === 0) ? "selected" : ""
    }

    var tab2 ={ 
      name: "All",
      cb: this.setTab,
      params: [this, 1],
      selected: (this.state.tab === 0) ? "" : "selected"
    }

    var tabs = []
    tabs.push(tab1);
    tabs.push(tab2);

    return (
      <Layout key={this.props.update_favorites} title="Latest results" buttons={buttons}>
        <TabsHeader tabs={tabs}/>
        <div style={{display: 'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingBottom:'8px'}}>
          <IonIcon color="secondary" icon={informationCircle}></IonIcon>
          <IonLabel className="infoText">your chioche will be remebered</IonLabel>
        </div>
        {this.state.tab === 0 ? this.getToday() : <CompetitionsFiltered></CompetitionsFiltered>}

      </Layout>
    )
  };

}

export default connect(state => ({
  orderedCompetitionsIDWithMatches: state.app.competitionsMatches,
  filters: state.app.filters,
  update_filters: state.app.update_filters,
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites
}), null)(CompetitionsFilter)
