import React from 'react';
import "../../css/layout.css"
import Competition from "../Competitions/Competition";
import KicoffMatches from "./KickoffMatches";
import { IonLoading } from '@ionic/react';
import { connect } from 'react-redux';
import { filterCompetitions, filterMatches, searchTeamName } from '../../data/MatchData';
import ExternalOdds from '../Advertising/ExternalOdds';

class MatchesContainer extends React.Component {
  
  constructor(props) {
    super(props);
      
    this.state = {
      showLoading: true
    }
  }

  componentDidUpdate(){
    console.log("COMPONENT DID UPDATE");
  }

  componentDidMount(){
    console.log("COMPONENT DID MOIUNT");
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }
  
  printMessage(){
    const { 
      search,
      favorites,
    } = this.props

    if (favorites)
      return <div className="errorMessage"><p className="errorMessage">no favorites</p></div>
    if (search !== null){
      if (search.length > 1)
        return <div className="errorMessage"><p className="errorMessage">no matches </p></div>
      else
        return <div className="errorMessage"><p className="errorMessage">type two or more </p></div>
    }
    return null;
  }

  render() {
    const { 
      day,
      update,
      competitions,
      matches,
      order,
      search,
      live,
      favorites,
      update_fiters,
      update_favorites
    } = this.props
    
    console.log("MATCHES_CONTAINER .......... " + update + " order " + order);

    // data not ready
    if (update === 0){
      return (
        <div>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={this.state.showLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={'loading...'}
          duration={5000}
        />
        </div>
      );
    }


    if (order === 1){
      var filtered_comps = filterCompetitions(competitions, 
                                  search, 
                                  live, 
                                  favorites,
                                  this.props.filters)

      
      if (filtered_comps.length <= 0){
        return this.printMessage()
      }

      var ref_index = 0;
      var m0 = 0;
      var m1 = 0;
      if (filtered_comps[0]){
        m0 = filtered_comps[0].matches[0].length +filtered_comps[0].matches[1].length+filtered_comps[0].matches[2].length;
      }
      if (filtered_comps[1]){
        m1 = filtered_comps[1].matches[0].length +filtered_comps[1].matches[1].length+filtered_comps[1].matches[2].length;
      }

      if (m0 < 3 && m1 < 3)
        ref_index = 1;

      return  filtered_comps.map((c, index) =>
                  <div key={c.id + "_" + update_favorites + "_" + update_fiters} >
                    <Competition key={c.id + "_" + update_favorites + "_" + update_fiters} competition={c.competition} matches={c.matches} showMatches={true} iter={index}/>
                    {index==ref_index && day == 0 && <ExternalOdds></ExternalOdds>}
                  </div>
                )
    
    }else{  
      var filtered_matches = filterMatches(matches, 
                                  search, 
                                  live, 
                                  favorites,
                                  this.props.filters)

      if (filtered_matches[0].length <= 0 && filtered_matches[1].length <= 0 && filtered_matches[2].length <= 0){
        return this.printMessage()                            
      }

      return <KicoffMatches key={ update_favorites + "_" + update_fiters} matches={filtered_matches}/>
    }
  }
} // class  
          
export default connect(state => ({
  update_favorites: state.app.update_favorites,
  update_fiters: state.app.update_filters,
  filters: state.app.filters,
}), null)(MatchesContainer)


