import React from "react"
import "../css/layout.css"
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBackOutline } from 'ionicons/icons'; // Ensure this import is correct

import { Link } from 'react-router-dom';

class F24HeaderEndirect extends React.Component {

  getButtons(){
    const {buttons} = this.props;

    if (buttons)
      return(
        buttons.map(b => 
          <div className="home-buttons">
          <ul>
          <li><span></span></li>
            <li>
              <div onClick={() => b.action(b.context ? b.context : "")}>
                <img src={b.icon}/>
              </div>
            </li>
          </ul>
        </div>
          )
      )
  
      return ""
  }

  getIonicButtons(icon){
    const {buttons} = this.props;

    if (buttons)
      return(
        buttons.map((b ,i) => 
          <IonButton key={i} color='white' onClick={() => b.action(b.context ? b.context : "")}>
            {!icon && <IonImg className="headerImage" slot="end" src={b.icon} />}
            {icon  && <IonIcon icon={b.icon} slot="icon-only" color="primary"></IonIcon>}
          </IonButton>
          )
      )
  
      return ""
  }

    getLogo(){
      return (<IonImg style={{marginLeft:'10px'}} slot="start" src="/assets/icon/endirect24/full_logo.png"></IonImg>)
    }

  render(){
        const {placement, title, buttons} = this.props;

        //console.log("F24HEADER " + buttons);

        if (placement === "main"){
          return (
            <IonHeader mode="ios">
            <div className="desktop-version-box">
              <IonButton href="http://m.endirect24.com/getversion?desktop=true" fill="clear" className="desktop-version-button">
                <div className="icon-circle">
                  <IonIcon slot="start" icon={arrowBackOutline} />
                </div>
                Retour à la version de bureau
              </IonButton>
            </div>
            <IonToolbar color="black" mode="ios">
               {this.getLogo()}
               <IonTitle style={{color: '#fff'}}>{title}</IonTitle>
               <IonButtons slot="end" >
                  {this.getIonicButtons()}
                 </IonButtons>
             </IonToolbar>
            </IonHeader>
          )
        }else{
          if (placement === "today"){
            return("")
            return (
              <IonHeader mode="ios">
              <IonToolbar color="black" mode="ios">
                 <IonTitle style={{color: '#fff'}}>{title}</IonTitle>
                 <IonButtons slot="end" >
                  {this.getIonicButtons()}
                 </IonButtons>
                 
               </IonToolbar>
              </IonHeader>
            )
          }
          return(
          <IonHeader mode="ios">
          <IonToolbar color="black" mode="ios">
             <IonButtons style={{color: '#fff'}} slot="start">
               <IonBackButton style={{color: '#fff'}} text=""  defaultHref="/" />
             </IonButtons>
             {this.getLogo()}
             <IonTitle style={{color: '#fff'}}>{title}</IonTitle>
             <IonButtons slot="end" >
              {this.getIonicButtons()}
            </IonButtons>
           </IonToolbar>
          </IonHeader>
          )
        }
    }
}

export default F24HeaderEndirect

const styles = `
  .desktop-version-box {
    background-color: #89C3EB; /* Light blue background */
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  .desktop-version-button {
    color: #003366; /* Adjust color as needed */
    font-size: 16px;
  }

  .icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #89C3EB; /* White background for the circle */
    border: 2px solid #000; /* Black border for the circle */
    margin-right: 8px; /* Spacing between icon and text */
  }
  .desktop-version-icon {
    margin-right: 8px; /* Spacing between icon and text */
  }
`;

// Inject the styles into the document's head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

/*            <button className="btnHamburger">
                <svg className="hamburger h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
*/