import {SITE} from '../../global_variables'
import React from 'react';
import "../../css/layout.css"
import { IonLoading, IonModal, IonSearchbar } from '@ionic/react';
import {doGet, matchesUrl} from '../../data/Protocol'
import { completeMatch, getMergedData, addMatchByDate, isJustFinished, checkMatchRange, checkMatchUpdate } from "../../data/MatchData";
import { orderCompetitions } from "../../data/CompetitionData";
import { completeTeam } from "../Teams/Team";
import { connect } from 'react-redux';
import { setDataUpdate, setMatchesUpdated, setOrder, setIsLive } from '../../state/app';
import { readFavorites } from '../../data/Favorites';

import Layout from '../layout'
import CompetitionsFilter from './CompetitionsFilter';
import { completeCompetition, completeLeague } from "../Competitions/Competition";
import MatchesCalendar from './Calendar';
import MatchesContainer from './MatchesContainer';
import LastResults from './LastResults';

import header_live from "../../images/header_stopwatch.png"
import header_live_on_f24 from "../../images/f24/header_stopwatch_on.png" 
import header_live_on_endirect from"../../images/endirect24/header_stopwatch_on.png"

import header_calendar from "../../images/header_calendar.png"
import header_globe from "../../images/header_globe.png"
import header_globe_on_f24 from "../../images/f24/header_globe_on.png"
import header_globe_on_endirect from"../../images/endirect24/header_globe_on.png"

import header_search from "../../images/header_magnifying.png"
import header_filter from "../../images/header_filter.png"

import header_volume_on from "../../images/header_volume_on.png" 
import header_volume_off from "../../images/header_volume_off.png"
import { readFilters } from '../../data/Filters';
import MatchesDay from './MatchesDay';
import EventsSound from './EventsSound';
import { App } from '@capacitor/core';
import ExternalOdds from '../Advertising/ExternalOdds';

const expire = (60000)

class MatchesToday extends React.Component {

  constructor(props) {
    super(props);

    var sf = false
    if (this.props.showFavorites === true)
      sf = true
    
    const soundEffect = new Audio();
    soundEffect.autoplay = false;
    soundEffect.src=""
  
    this.state = {
      showLoading: true,
      day: 0, 
      showModal: false,
      modalContent: 0,   //0 last result, 1 comps filter
      showFavorites: sf,
      search: false,
      searchText: null,
      soundEffect: soundEffect,
      volume: false
    }
    console.log("STORAGE check state...")
    readFavorites(this)
    readFilters(this)

    App.addListener('appStateChange', ({ isActive }) => {
      //console.log('SW App state changed. Is active?', isActive);  
      clearInterval(this.interval);
      if (isActive){
        this.getMatches();
        this.interval = setInterval(this.getMatches, 5000)
      }
    });
    
  }
  networkSuccess(url, resp, headers) { 
    const { update, data } = this.props;

    var checkForUpdate = (headers && headers["url-req"] == 1) ? true : false

    console.log("RESP check " + url + " ---> " + checkForUpdate);
    var data_merged = null;

    if (resp != null){
      this.setShowLoading(false)
      let data_updated = resp.result;

      if (update === data_updated.status.update_matches){
        let up = 0
        data.changes = 0
        data.matches.list.forEach(m => {
          up |= checkMatchUpdate(m);
        });
        if (up === 1){
          data.changes = 1
        }

        this.props.dispatch(setMatchesUpdated(0));
        return;
      }

      data_merged = getMergedData(data, data_updated, checkForUpdate);

    }else{
      data_merged = data;
      data_merged.changes = 0
    }

    let competitions_server = data_merged.competitions.list;
    let leagues = data_merged.leagues.list;
    let countries = data_merged.countries.list;
    let teams = data_merged.teams.list;

    competitions_server.forEach(c => {
        //console.log("COMPETITION " + c.name + " - " + c.popularity)
        completeCompetition(c, countries);
    });

    leagues.forEach(l => {
        completeLeague(l, competitions_server);
    });

    teams.forEach(t => {
        completeTeam(t, countries);
    });

    let orderedCompetitions = orderCompetitions(competitions_server);    
    var matchesKickOff = [[],[],[]];    // 0: live, 1: next, 2: ended
    
    data_merged.matches.list.forEach(m => {
        //console.log("Update match " + m.id)
        try{
          if (m.available !== 0 && checkMatchRange(m, data_merged.range)){
            if (isJustFinished(m)){
              var elapsed = Date.now() - m.isJustFinished;
              //console.log ("[JF] check " + m.id)
              //console.log ("[JF] " + m.id + " elapsed " + elapsed)
              if (elapsed > 20000){
                //console.log("Match [JF] " + m.id + " no more just finished - " + elapsed)
                m.isJustFinished = 0
              }else{
                //console.log("Match [JF] " + m.id + " contiune to be just finished")
              }
            }
            completeMatch(m, data_merged);
            
            let c = orderedCompetitions.find(e => e.id === m.league.competition_id);
            addMatchByDate(m, c.matches);
            addMatchByDate(m, matchesKickOff);
          }
        }catch(err){
          //console.log("ADDING MATCH ERROR ")
        }
    });
    //console.log("Updated " + i + " matches ok: " + ok_u + " er: " + er_u)
    //console.log("MUPDATE [check] " + data_merged.changes)
    
    var global_data = { 
      data: data_merged,
      competitionsMatches: orderedCompetitions,
      kickoffMatches: matchesKickOff,
      update: data_merged.status.update_matches,
      matches_updated: data_merged.changes
    }
    this.props.dispatch(setDataUpdate(global_data));

    console.log("DATA_BUILT " + data_merged.status.update_matches + " Ordered: " + orderedCompetitions.length + " Comps: " + competitions_server.length);

    if (url === matchesUrl(0, 0)){
      if (headers){
        var fetched = Date.parse(headers["date"]);
        console.log("[RESP] Checking fetched[" + url + "] ");
        console.log("[RESP] fetch " + fetched + " date: " + headers["date"]);
        console.log("[RESP] now: " + new Date().getTime());
        
        if ((parseFloat(fetched) + expire) < new Date().getTime()){
          console.log("updating to refresh fetched resp ");
          this.getMatches(1);
        }else{
          console.log("[RESP] fresh fetched resp ");
        }  
      }
    }
  }

  networkError(url,status){
    console.log("NETWORK ERROR " + status)    
  }

  getMatches = async (req = 0) => {
    const {update} = this.props
      this.setState({
      //events: null,
    })
    
    doGet(matchesUrl(0, update), this, req)
  }

  componentDidMount = () => {
    if (this.props.showFavorites){
      if (this.props.data == null){
        this.getMatches(-1);        
      }else
      return
    }
    
    this.getMatches(-1);
    
    this.interval = setInterval(this.getMatches, 5000)
  }
  componentDidUpdate = () => {
  }

  componentWillUnmount() {
    if (this.props.showFavorites)
      return
    if (this.interval)
      clearInterval(this.interval);
  }

  refresh = () => {
    this.getMatches();
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }

  setSearch(context){
    context.setState({search: !context.state.search, searchText: context.state.search ? null : ""})
  }

  setSearchText(val){
    if (this.state.search)
      this.setState({searchText: val.toLowerCase()})
  }

  openModalLR(context){
    context.setState({
      modalContent: 0,
      showModal: true,
    })
  }

  openModalCF(context){
    context.setState({
      modalContent: 1,
      showModal: true,
    })
  }

  closeModal(context){
    context.setState({
      showModal: false
    })
  }

  toggleVolume = (context) => {
    if (context.state.volume === false){
      context.state.soundEffect.src =""
      context.state.soundEffect.play()
    }
    context.setState({volume: (context.state.volume ? false:true)})
  }

  toggleOrder = () => {
    const { order, dispatch } = this.props;
    dispatch(setOrder((order) ? 0:1));
  }

  toggleLive = () => {
    const { isLive, dispatch } = this.props;
    dispatch(setIsLive(!isLive));
  }

  setDay = (day) => {
    this.setState({day: day})
  }

  showContainer() {
    const { 
      update,
      order,
      isLive,
      orderedCompetitionsIDWithMatches,
      matchesKickOff,
    } = this.props;
    
    const {searchText} = this.state

    return <MatchesContainer  competitions={orderedCompetitionsIDWithMatches} matches={matchesKickOff}
                              order={order} live={isLive} update={update}
                              search={(this.state.search && searchText !== null) ? searchText : null}
                              favorites={this.state.showFavorites ? this.props.favorites : null}
                              filter={this.props.filters} day={0}></MatchesContainer>
  }

  render() {
    const { data, update, order, isLive, update_favorites } = this.props;
    // data not ready

    var button_order = (order === 0) ? {  
      icon: (SITE == 'FUTBOL24') ? header_globe_on_f24 : header_globe_on_endirect ,
      text: "comps",
      action: this.toggleOrder
    }:
    { 
      icon: header_globe,
      text: "kickoff",
      action: this.toggleOrder
    }
    var button_live = (isLive) ? { 
      icon: (SITE == 'FUTBOL24') ? header_live_on_f24 : header_live_on_endirect ,
      text: "live",
      action: this.toggleLive
    }:
    { 
      icon: header_live,
      text: "live",
      action: this.toggleLive
    }
    
    var button_search = { 
      icon: header_search,
      action: this.setSearch,
      context: this
    }

    var button_filter = { 
      icon: header_filter,
      action: this.openModalCF,
      context: this
    }

    var button_calendar = { 
      icon: header_calendar,
      action: this.openModalLR,
      context: this
    }

    var button_volume = this.state.volume ? { 
      icon: header_volume_on ,
      action: this.toggleVolume,
      context: this
    }:{ 
      icon: header_volume_off,
      action: this.toggleVolume,
      context: this
    }

    var buttons = []
    if (!this.state.search) buttons.push(button_order);
    if (this.state.day === 0 && this.state.showFavorites === false){
      if (!this.state.search) buttons.push(button_calendar);      
      buttons.push(button_search);
      if (!this.state.search) buttons.push(button_filter);
      if (!this.state.search) buttons.push(button_live);
      if (!this.state.search) buttons.push(button_volume);
    }

    if (update === 0){
      return (
        <Layout title="Endirect24" description="Endirect24 Matches List" buttons={buttons}>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={this.state.showLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={'loading...'}
          duration={5000}
        />
        </Layout>
      );
    }
    console.log("MATCHES_TODAY .......... " + this.state.day);
    var page_name = "https://www.endirect24.com/stat/piwik.php?idsite=12&amp;rec=1&amp;action_name=page_matches";
    if (this.state.showFavorites)
      page_name   = "https://www.endirect24.com/stat/piwik.php?idsite=12&amp;rec=1&amp;action_name=page_favorites";
    /*if (this.state.day > 0)
      page_name += "_day/"+this.state.day;*/
    //console.log("[UF] MATCHES update_fav " + update_favorites)
    return (
      <Layout id={update_favorites} buttons={buttons} description="Futbol24 Matches List">
        <IonModal isOpen={this.state.showModal}>
        {this.state.modalContent === 1 && <CompetitionsFilter closeAction={this.closeModal} context={this}></CompetitionsFilter>}
        {this.state.modalContent === 0 && <LastResults closeAction={this.closeModal} context={this}></LastResults>}          
        </IonModal>

        {this.state.showFavorites === false && this.state.volume && <EventsSound changes={data.changes}/>}
        {this.state.search && <IonSearchbar color="secondary" onIonChange={(e) => this.setSearchText(e.detail.value)} />}

        {!this.state.search && <MatchesCalendar day={this.state.day} cb={this.setDay}/>}
          <div className="content"> 
            {this.state.day === 0? this.showContainer() : <MatchesDay day={this.state.day} order={order}  showFavorites={this.state.showFavorites}/>}
          </div>
          

      </Layout>
    )
  }
} // class

//<img src={page_name} alt="" />

export default connect(state => ({
  update: state.app.update,
  isLive: state.app.isLive,
  order:  state.app.order,
  data:   state.app.data,
  orderedCompetitionsIDWithMatches: state.app.competitionsMatches,
  matchesKickOff: state.app.kickoffMatches,
  advertisingCampaign: state.app.advertisingCampaign,
  update_favorites: state.app.update_favorites,
  favorites: state.app.favorites,
  update_fiters: state.app.update_filters,
  filters: state.app.filters,
}), null)(MatchesToday)

