import React from 'react';
import { Link } from 'react-router-dom';
import "../../css/layout.css"

///////////////////////////     COMPONENT

class MatchesCalendar extends React.Component {

    render(){

    const {day, cb} = this.props
    var d = [];
    d[0] = "SUN";
    d[1] = "MON";
    d[2] = "TUE";
    d[3] = "THU";
    d[4] = "WED";
    d[5] = "FRI";
    d[6] = "SAT";

    var month = [];
    month[0] = "JAN";
    month[1] = "FEB";
    month[2] = "MAR";
    month[3] = "APR";
    month[4] = "MAY";
    month[5] = "JUN";
    month[6] = "JUL";
    month[7] = "AUG";
    month[8] = "SEP";
    month[9] = "OCT";
    month[10] = "NOV";
    month[11] = "DEC";

    var today = new Date();
    var y = new Date();
    var yy = new Date();
    var yyy = new Date();
    var t = new Date();
    var tt = new Date();
    var ttt = new Date();

    y.setDate(today.getDate() - 1);
    yy.setDate(today.getDate() - 2);
    yyy.setDate(today.getDate() - 3);
    t.setDate(today.getDate() + 1);
    tt.setDate(today.getDate() + 2);
    ttt.setDate(today.getDate() + 3);

    let class_3 = (day === -3) ? "selected" : ""
    let class_2 = (day === -2) ? "selected" : ""
    let class_1 = (day === -1) ? "selected" : ""
    let class_0 = (day === 0) ? "selected" : ""
    let class1 = (day === 1) ? "selected" : ""
    let class2 = (day === 2) ? "selected" : ""
    let class3 = (day === 3) ? "selected" : ""
    
    return(
      <div className="date-bar"> 
        <div onClick={() => cb(-3)}>{d[yyy.getDay()]} <span>{yyy.getDate()}</span><div className={"date-bar-box " + class_3}></div></div> 
        <div onClick={() => cb(-2)}>{d[yy.getDay()]} <span>{yy.getDate()}</span><div className={"date-bar-box " + class_2}></div></div> 
        <div onClick={() => cb(-1)}>{d[y.getDay()]} <span>{y.getDate()}</span><div className={"date-bar-box " + class_1}></div> </div> 
        <Link to="/matches" className={class_0} onClick={() => cb(0)}><p className={"today " + class_0}>TODAY</p></Link>
        <div onClick={() => cb(1)}>{d[t.getDay()]} <span style={{fontSize:'bold'}}>{t.getDate()}</span><div className={"date-bar-box " + class1}></div></div>
        <div onClick={() => cb(2)}>{d[tt.getDay()]} <span>{tt.getDate()}</span><div className={"date-bar-box " + class2}></div></div>
        <div onClick={() => cb(3)}>{d[ttt.getDay()]} <span>{ttt.getDate()}</span><div className={"date-bar-box " + class3}></div></div> 
      </div>      
    )
  }
}

export default MatchesCalendar