

export const merge = (a, b) => {  
  // add or edit b elements in main array a

  b.forEach(element => {
    const index = a.findIndex((e) => e.id === element.id);
    
    if (index === -1) {
        a.push(b);
    } else {
        a[index] = element;
    }
  });
  return a;

}