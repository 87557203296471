import React from 'react';
import "../../css/layout.css"

import { Link } from 'react-router-dom';
import {doGet, teamsUrl} from '../../data/Protocol'
import { connect } from 'react-redux';
import { IonItem, IonLabel, IonList, IonLoading } from '@ionic/react';
import { addTeamToFavorites, getTeamsFavorites, isTeamFavorite } from '../../data/Favorites';

///////////////////////////     COMPONENT

class TeamsList extends React.Component {
  constructor(props) {
    super(props);
      
    this.state = {
      nationals: [],
      selected_country: -1,
      showLoading: true,
    }
  }

  componentDidMount = () => {
    this.getTeams(); 
    this.setState({selected_country: 0})
  }

  getTeams = async () => {    
    doGet(teamsUrl(), this) 
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }

  networkSuccess(url, resp) { 
    
    if (resp != null){
      this.setShowLoading(false)
      if (resp.result && resp.result.countries && resp.result.countries.list){
        var countries = resp.result.countries.list
        var teams = resp.result.teams.list
        var nationals = []
        
        teams.map(c => {
            var cc = countries.find(e => e.id === c.country_id);
            if (!cc.teams)
              cc.teams = []
            if (!c.popularity)   
              c.popularity = 0
            
            for (var index = 0; index < cc.teams.length; index++) {
              if (c.popularity > cc.teams[index].popularity)
                break
            }
            //cc.teams.push(c)
            cc.teams.splice(index, 0, c)
          }
        )

        countries.map(c => {
          if (!c.popularity)
            c.popularity = 0
          else
            c.popularity = Number(c.popularity)
          
            for (var index = 0; index < nationals.length; index++) {
              var p = nationals[index]
              
              if (c.popularity < p.popularity){  
                continue
              }else{
                if (c.popularity === p.popularity){  
                  if (p.name > c.name )
                    break
                }else{
                    break
                }
              }
            }
            nationals.splice(index, 0, c);
        }
      )

      this.setState({
          nationals: nationals,
        })
  
      }
    }
  }

  networkError(url,status){
  }

  openCountry(id){
    if (id === this.state.selected_country)
      this.setState({selected_country: 0})
    else
      this.setState({selected_country: id})
  }

  renderRow(country, i){

    return ( 
      <IonList key={i} style={{background: '#fff'}} lines="none" mode="ios">
        <IonItem key={"c" + i} className="no-padding" color="#fff" key={i} onClick={() => this.openCountry(country.id)}>
          <img className="countryImg" src={country.flag_url_medium} alt="" />
          <p className="countryName">{country.name}</p>
        </IonItem>
        {country.id === this.state.selected_country && country.teams.map((cc, i) => 
          <div key={i} style={{display: 'flex', height:'30px', alignItems:'center'}}>
            <svg style={{marginLeft: '5px'}} xmlns="http://www.w3.org/2000/svg" fill={isTeamFavorite(this.props.favorites, cc.id) ? "#0B1F64" : "#fff"} viewBox="0 0 24 24" stroke="#0B1F64" onClick={() => addTeamToFavorites(this, cc)}>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>
            <Link style={{width: '100%', height:'30px', display: 'flex', alignItems:'center'}} to={"/team/" + cc.id}>
              <IonLabel className="competitionName">
                <span>{cc.name}</span>
              </IonLabel>
            </Link>    
          </div>
          
        )}
      </IonList>            
    )
  }


  printFavorite(team){
    const { nationals } = this.state
    var t = null
    var country
    
    var i = 0

    for (i = 0; i < nationals.length; i++){
      t = nationals[i].teams.find(e => e.id === team);
      if ( t ){
        country =  nationals[i]
        break
      }
    }
    
    if (t)
    return(
      <IonItem key={t.id} className="no-padding" color="#fff">
        <Link style={{width: '100%', display:'flex'}} to={"/team/" + t.id}>
          <img className="countryImg" src={country.flag_url_medium} alt=""/>
          <p className="competitionFavName">{country.name} - <span>{t.name}</span></p>
        </Link>
        <svg slot="end" onClick={() => addTeamToFavorites(this, t)} style={{marginLeft: '5px'}} xmlns="http://www.w3.org/2000/svg" fill={"#0B1F64"} viewBox="0 0 24 24" stroke="#0B1F64" >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
              </svg>
      </IonItem>
    )
  }

  showFavorites(){
    const { favorites } = this.props
    var teams = getTeamsFavorites(favorites)

    if (teams && teams.length > 0){
      return(
        <div>
          <h1>
            <div>Favorites</div>
          </h1>
          <IonList style={{background: '#fff'}} lines="none" mode="ios">
            {teams.map(t => this.printFavorite(t) )}
          </IonList>
        </div>
      )
    }
  }

  render() {
    const {nationals} = this.state
    if (nationals.length > 0)
      return (
        <div className="content" style={{margin: "0px 5px 15px 5px"}}> 
          {this.showFavorites()}
          <h1>
            <div>Nationals</div>
          </h1>
          {nationals.map((country, i) => 
            this.renderRow(country, i)
          )}
        </div>
      );

      return (
        <IonLoading
          cssClass='my-custom-class'
          isOpen={this.state.showLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={'loading...'}
          duration={5000}
        />
      );

    }
} // class

export default connect(state => ({
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites
}), null)(TeamsList)

//export default MatchList
