import React from 'react';
import { getResult } from '../../data/MatchData';
import { homeTeamName, guestTeamName } from "../../data/MatchData";
import { connect } from 'react-redux';
import ico_match_event_goal_canceled from "../../images/ico_match_event_goal_cancel.png" 
import ico_match_event_card_red from "../../images/ico_nav_cards_on.png" 
import ico_match_event_goal     from "../../images/ico_nav_goals_on.png" 
import ico_match_event_status   from "../../images/ico_nav_time_on.png" 


class Match extends React.Component {

getResultBox(result){
  const { match } = this.props;

  let r = getResult(match)
    return(
      <p className="scores">{r}</p>
    )
}

render() {
    const { match, i, event } = this.props;

    
    var ccolor = '#' + match.league.competition.background;
    var cell1_class = "cell-name"
    var cell2_class = "cell-name"
    var team1_class = "team-name1"
    var team1_hl_class = ""
    var team2_class = "team-name2"
    var team2_hl_class = ""

    //var row_class = (i % 2) ? "row bg-bold" : "row"
    var row_class = "row bg-bold"
    var result = getResult(match)
    
    var end_icon

    if (event[0] === 0){
      end_icon = <span className="event-ico"><img className="invert" src={ico_match_event_goal} alt=""/></span>
    }
    if (event[0] === -2){
      end_icon = <span className="event-ico"><img className="invert" src={ico_match_event_goal_canceled} alt=""/></span>
    }
    if (event[0] === 1){
      end_icon = <span className="event-ico"><img className="invert" src={ico_match_event_card_red} alt=""/></span>
    }
    if (event[0] === 3){
      end_icon = <span className="event-ico"><img className="invert" src={ico_match_event_status} alt=""/></span>
    }

    if (event[2] === -1)
      team1_class = team1_class + " team_event"
    if (event[2] === 1)
      team2_class = team2_class + " team_event"
    
    var epoch = new Date(match.start_date * 1000);
    var offset = epoch.getTimezoneOffset() * 60;
    var start = new Date((match.start_date-offset) * 1000);;
    
    console.log("Last result match " + JSON.stringify(match))
    return (
      <div>
        <div className={row_class}>  
          <div className="row_left">
          
            <div className="compbox" style={{backgroundColor: ccolor, color: '#fff', textAlign:'center'}}>{(match.league && match.league.competition) ? match.league.competition.country.sname : "UNK"}</div>
            <p className="number">{event[1]}</p>
            <div className={cell1_class}>
            <p className={team1_class}>
              <span className={team1_hl_class}>{homeTeamName(match)}</span>
            </p>
            </div>
            {this.getResultBox(result)}
            <div className={cell2_class}>
              <div className={team2_class}>
                  <span className={team2_hl_class}>{guestTeamName(match)}</span>
              </div>
            </div>

          </div>
          <div style={{marginRight:'10px'}}>{end_icon}</div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites
}), null)(Match)

//export default Match;
