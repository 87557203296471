import React from 'react';
import MatchesToday from './MatchesToday';

class Favorites extends React.Component {

  render() {
    return (<MatchesToday showFavorites={true}></MatchesToday>)
  }
} // class


export default Favorites

