import React from 'react';

import {
  IonTabButton,
  IonIcon,
  IonImg,
  IonLabel,
} from '@ionic/react';

import menu_telegram from "../../images/menu_tg.png"
import Advertising from './Advertising';



class Telegram extends React.Component {
 
  render() {
    console.log("TELEGRAM");
    return <Advertising tag="bet"/>
  }
}

export default Telegram
