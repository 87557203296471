const initialState = {
    update: 0,
    isLive: false,
    order:  1,
    data: null,
    kickoffMatches: null,
    competitionsMatches: null,
    advertisingCampaign: null,
    matches_updated: 0,
    favorites: null,
    update_favorites: 0,
    filters: null,
    update_filters: 0,
};

const SET_DATA_UPDATE  = 'SET_DATA_UPDATE';
const SET_MATCHES_UPDATED  = 'SET_MATCHES_UPDATED';
const SET_DATA  = 'SET_DATA';
const SET_MATCHES_KCOFF  = 'SET_MKCO';
const SET_MATCHES_COMPS  = 'SET_MCMP';
const SET_UPDATE = 'SET_UPDATE';
const SET_ISLIVE = 'SET_ISLIVE';
const SET_ORDER  = 'SET_ORDER';
const SET_AD_CAMPAIGN  = 'SET_AD_CAMPAIGN';
const SET_FAVORITES = 'SET_FAVORITES';
const SET_FILTERS = 'SET_FILTERS';

export const setDataUpdate = global_data => ({ type: SET_DATA_UPDATE, global_data });

export const setUpdate = update => ({ type: SET_UPDATE, update });
export const setIsLive = islive => ({ type: SET_ISLIVE, islive });
export const setOrder  = order  => ({ type: SET_ORDER,  order });
export const setMatchesUpdated = matches_updated   => ({ type: SET_MATCHES_UPDATED, matches_updated });
export const setData   = data   => ({ type: SET_DATA,   data });
export const setMKickoff = data   => ({ type: SET_MATCHES_KCOFF,   data });
export const setMComps   = data   => ({ type: SET_MATCHES_COMPS,   data });
export const setAdCampaign  = data   => ({ type: SET_AD_CAMPAIGN,   data });
export const setFavorites   = data   => ({ type: SET_FAVORITES,   data });
export const setFilters   = data   => ({ type: SET_FILTERS,   data });

export default (state = initialState, action) => {
switch (action.type) {
    case SET_DATA_UPDATE:
        return { ...state, 
            data: action.global_data.data, 
            kickoffMatches: action.global_data.kickoffMatches,
            competitionsMatches: action.global_data.competitionsMatches,
            update: action.global_data.update,
            matches_updated: action.matches_updated
        };

    case SET_DATA:
        return { ...state, data: action.data };
    case SET_MATCHES_KCOFF:
        return { ...state, kickoffMatches: action.data };
    case SET_MATCHES_COMPS:
        return { ...state, competitionsMatches: action.data };
    case SET_UPDATE:
        return { ...state, update: action.update };
    case SET_ISLIVE:
        return { ...state, isLive: action.islive };
    case SET_ORDER:
        return { ...state, order: action.order };
    case SET_MATCHES_UPDATED:
        return { ...state, matches_updated: action.matches_updated };
    case SET_AD_CAMPAIGN:
        return { ...state, advertisingCampaign: action.data };
    case SET_FAVORITES:
        var n = state.update_favorites + 1
        return { ...state, favorites: action.data, update_favorites: n };
    case SET_FILTERS:
        var n = state.update_filters + 1
        return { ...state, filters: action.data, update_filters: n };
    default:
        return state;
    }
};