import React from 'react';
import {getDictionary} from './Advertising'

class OddsHeader extends React.Component {
 
  render() {
    const {campaign, providers, tab} = this.props 
    
    let title_jolly = "tj"
    let title = null

    providers.map(p => {
      
      for (var i=0; i< p.odds.length; i++){
        if (p.odds[i].type_id === 0){
          title_jolly = p.odds[i].title
        }
        if (p.odds[i].type_id === tab){
          title = p.odds[i].title
          break
        }
      }
      return 0
    }
    )

    if (!title)
      title = title_jolly
    
      title = getDictionary(campaign, title)
      title.replace("#goals#", "4");
      return <div className="page-title">{title}</div>
  }
}

export default OddsHeader
