import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  IonApp,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { isPlatform, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Matches from './pages/Matches';
import MatchDetailsPage from './pages/MatchDetails';
import MyMatches from './pages/MyMatches';
import TeamPage from './pages/Team';
import CompetitionPageTable from './pages/CompetitionWithMatches'
import CompetitionPageMatches from './pages/CompetitionWithTable'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Explore from './pages/Explore';
import MatchesToday from './components/Matches/MatchesToday';

/*
const App: React.FC = () => (
);
export default App;
*/

import menu_telegram from "./images/menu_tg.png"
import menu_live from "./images/menu_live_2.png"
import menu_live_svg from "./images/menu_live_2.svg"
import menu_favorites from "./images/menu_favorites_2.png"
import menu_explore from "./images/menu_explore_2.png"
import Telegram from './components/Advertising/Telegram';
import Advertising from './components/Advertising/Advertising';
import ExternalOdds from './components/Advertising/ExternalOdds';


const getConfig = () => {
  let config = {
    animated: false
  };

  if (isPlatform('iphone')) {
    setupConfig({ swipeBackEnabled: false, });
  }

  return config;
}
setupConfig(getConfig());


class App extends React.Component {
  
  /*
  getTg(){
    if (getTelegram())
      return <Telegram/>
    else
      return null;
  }*/

  render (){

    var tg ="";
    
      

    return (
    <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet >
          <Route path="/" render={() => <Redirect to="/matches" />} exact={true} />
          <Route exact path="/:tab(matches)" component={Matches} />
          <Route exact path="/mymatches" component={MyMatches} />
          <Route exact path="/match/:id" component={MatchDetailsPage}/>
          <Route exact path="/competitionTable/:id" component={CompetitionPageTable} />
          <Route exact path="/competition/:id" component={CompetitionPageMatches} />
          <Route exact path="/team/:id" component={TeamPage} />
          <Route exact path="/explore" component={Explore} />
          <Route exact path="/matches" component={Matches} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
            <IonTabButton  tab="scores" href="/matches">
              <IonIcon style={{width:'18px', height: '18px'}} src={menu_live_svg} />              
              <IonLabel className='menuLabel'>SCORES</IonLabel>
            </IonTabButton>

            <IonTabButton tab="telegram" href="#" style={{display:'none'}}>
            <Telegram></Telegram>
            </IonTabButton>

            <IonTabButton tab="favorites" href="/mymatches">
              <IonImg style={{width:'18px'}} src={menu_favorites} />
              <IonLabel className='menuLabel'>FAVORITES</IonLabel>
            </IonTabButton>

            <IonTabButton tab="explore" href="/explore">
              <IonImg style={{width:'18px'}} src={menu_explore} />
              <IonLabel className='menuLabel'>EXPLORE</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
    </IonReactRouter>
    )



    return <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet >
            <Route path="/" render={() => <Redirect to="/matches" />} exact={true} />
            <Route exact path="/:tab(matches)" component={Matches} />
            <Route exact path="/mymatches" component={MyMatches} />
            <Route exact path="/match/:id" component={MatchDetailsPage}/>
            <Route exact path="/competitionTable/:id" component={CompetitionPageTable} />
            <Route exact path="/competition/:id" component={CompetitionPageMatches} />
            <Route exact path="/team/:id" component={TeamPage} />
            <Route exact path="/explore" component={Explore} />
            <Route exact path="/matches" component={Matches} />
          </IonRouterOutlet>
          <IonTabBar slot="top">
            <IonTabButton  tab="scores" href="/matches">
              <IonImg style={{width:'16px'}} src="/images/menu_live.png" />              
              <IonLabel>Scores</IonLabel>
            </IonTabButton>

            <IonTabButton tab="favorites" href="/mymatches">
              <IonImg src="/assets/icon/menu_favorites.png" />
              <IonLabel>Favorites</IonLabel>
            </IonTabButton>

            <IonTabButton tab="explore" href="/explore">
              <IonImg src="/assets/icon/menu_explore.png" />
              
              <IonLabel>Explore</IonLabel>
            </IonTabButton>
          </IonTabBar>

        </IonTabs>
      </IonReactRouter>
    </IonApp>

  }
}

export default App;

