import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter } from '@ionic/react';
import CompetitionTable from '../components/Competitions/competitionTable'
import CompetitionContainer from '../components/Competitions/CompetitionContainer';
import Advertising from '../components/Advertising/Advertising';
import F24Header from '../components/F24Header';

interface CompetitionPageProps extends RouteComponentProps<{
  id: string;
}> {}

const CompetitionPage: React.FC<CompetitionPageProps> = ({match}) => {
  return(
      <IonPage>
      <IonHeader>
        <F24Header fulllogo={true}/>
      </IonHeader>
      <IonContent>
        <CompetitionContainer id={match.params.id} tab_selected={2}></CompetitionContainer>
      </IonContent>
      <IonFooter mode="ios"><Advertising/></IonFooter>
    </IonPage>
    )
};

export default CompetitionPage;
