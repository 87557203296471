import React from 'react'
import {doGet, competitionTableUrl} from '../../data/Protocol'
import {COLORS} from '../../data/colors'
import TabsHeader from '../TabsHeader';

class CompetitionTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      table: null,
      type: 1,
      tab: 1
    }
  }

  networkSuccess(url, resp) { 
    this.setState({table: resp.result})
    var name = resp.result.leagues.list[0].name
    var c = name.indexOf(',')
    var n = name
    if (c > 0)
      n = name.substring(0,c)
    
    this.props.setHeader(this.props.context, n, null )
  }

  networkError(url,status){
      
  }

  componentDidMount () {
    const {id} = this.props;

    let url = competitionTableUrl(id)
    doGet(url, this)
  }

  setTab(param){
    param[0].setState({tab: param[1], type: param[1]})
  }

  showButton() {  
    var {tab} = this.state
    
    var tab0 ={ 
      name: "Home",
      cb: this.setTab,
      params: [this, 2],
      selected: tab === 2 ? "selected" : ""
    }

    var tab1 ={ 
      name: "All",
      cb: this.setTab,
      params: [this, 1],
      selected: tab === 1 ? "selected" : ""
    }

    var tab2 ={ 
      name: "Away",
      cb: this.setTab,
      params: [this, 3],
      selected: tab === 3 ? "selected" : ""
    }

    var tabs = []
    tabs.push(tab0);
    tabs.push(tab1);
    tabs.push(tab2);

    return (<TabsHeader tabs={tabs} subheader={true}/>)
  }

  getLegend(teams){
    var l = []
    
    if (this.state.type !== 1){
      return
    }

      teams.map(t=>{
      if (t.type_id === 1){
        if (t.position_name){
          if (l.findIndex((p) => p.pn === t.position_name) === -1) 
          {
            var pn = t.position_name 
            var pc = t.position_color ? "#" + t.position_color : COLORS.lightGrey
            l.push({pn, pc})
          }
        }
      }
    })

    return(
      <div>
        <div style={{background: COLORS.greyHeader, paddingTop: '20px', paddingBottom: '20px'}}>
          <p style={{background: "#fff", paddingTop: '5px', paddingBottom: '5px', textAlign: 'center'}}>Position</p>
        </div>
        {l.map(p => 
          <div className="cTable">
              <div className="cTablePos"><p className="cTablePosBox empty" style={{background: p.pc}}></p></div>
              <div className="cTableName">{p.pn}</div>
          </div>
        )}

      </div>
    )
  }

  teamRow(team, teams, league_id){
    if (team.type_id === this.state.type && team.league_id === league_id){
      let i = teams.findIndex((t) => t.id === team.team_id)
      var tname = (i === -1) ? "-" : teams[i].sname
      var color = team.position_name ? team.position_color ? "#" + team.position_color : COLORS.lightGrey : "#fff"

      return (
        <div className="cTable">
          <div className="cTablePos">
            {color !== "#fff" && 
                <p className="cTablePosBox" style={{background: color}}>{team.place}</p>
            }
            {color === "#fff" && 
                <p style={{background: color}}>{team.place}</p>
            }

          </div>
          <div className="cTableName">{tname}</div>
          <div className="cTableM">{team.games_played}</div>
          <div className="cTableM">{team.record_wins}</div>
          <div className="cTableM">{team.record_drawn}</div>
          <div className="cTableM">{team.record_losses}</div>
          <div className="cTableDiff">{team.goals_forward}:{team.goals_against}</div>
          <div className="cTablePoints">{team.points}</div>
        </div>


      )
      
      /*return(
        <tr key={team.id} className="fc-stat-info">
          <td className="list-number"><p style={{background: color}}>{team.place}</p></td>
          <td className="fc-list-name">{tname}</td>
          <td className="fc-list-data">{team.games_played}</td>
          <td className="fc-list-data">{team.record_wins}</td>
          <td className="fc-list-data">{team.record_drawn}</td>
          <td className="fc-list-data">{team.record_losses}</td>
          <td className="fc-list-data">{team.goals_forward}:{team.goals_against}</td>
          <td className="fc-list-data"><strong>{team.points}</strong></td>
        </tr>
      )*/
    }
  }

  groupTable(league, teams){
    var {table} = this.state

    return(
    <div style={{margin: "0px 5px 15px"}}>
      {league.name !== league.dname && <p className="groupName">{league.dname}</p>}
      <div className="cTableHeader">
        <div className="cTablePos"></div>
        <div className="cTableName">Team</div>
        <div className="cTableM">P</div>
        <div className="cTableM">W</div>
        <div className="cTableM">D</div>
        <div className="cTableM">L</div>
        <div className="cTableDiff">Goal</div>
        <div className="cTablePoints">Pt</div>
      </div>
      {table.standings.list.map(t => 
          this.teamRow(t, teams, league.id)
        )}
    </div>
  )
        /*
    return (

    <div className="statistics" key={league.id}>
    
      {league.name !== league.dname && <h1>{league.dname}</h1>}

    <div className="team-stats-table">
      <table>
        <tbody>
        <tr className="header">
          <td className="list-number"></td>
          <td className="fc-list-name">Team</td>
          <td className="G">G</td>
          <td className="V">V</td>
          <td className="P">P</td>
          <td className="S">S</td>
          <td className="goal">Goal</td>
          <td className="pt">Pt</td>
        </tr>
        {table.standings.list.map(t => 
          this.teamRow(t, teams, league.id)
        )}
        </tbody>
      </table>
    </div>
    </div>
    )*/
  }
  
  render() {
    var {table} = this.state

    if (this.state.table){
      //let name = table.countries.list[0].sname + " - " + table.leagues.list[0].name
      return (
        <div>
          {this.showButton()}
          {table.leagues.list.map(l => 
          this.groupTable(l, table.teams.list)
        )}
        {this.getLegend(table.standings.list)}
        </div>
        
      )
    }

    return (<div>
      <div>loading...</div>
      </div>
    )
  }
}
 

export default CompetitionTable;
