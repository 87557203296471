import React from "react"
import {homeTeamName, guestTeamName, getHomeResult, getPastResult, getGuestResult, getStatusLabel} from "../../data/MatchData"
import {isScheduled, isPlaying} from "../../data/MatchData"
import {getTeamFlag, getTeamCountryName} from "../Teams/Team"

class BoxScore extends React.Component {

    
    render (){
        const {match} = this.props;
        if (match){
            var home = homeTeamName(match);
            var guest = guestTeamName(match);
            
            //console.log("team: " + JSON.stringify(match.teamHome));

            let vslabel = "";
            let vstext = "-";
            let statusClass = "ft";
            let resultClass = "result-dashboard";
            if (isPlaying(match)){
                statusClass+=" live"
                resultClass+=" live"
                if (getHomeResult(match) !== 0 || getGuestResult(match) !== 0)
                    resultClass+=" nocleansheet"
            }else
            if (isScheduled(match)){
                statusClass+=" scheduled"
                resultClass+=" scheduled"
                vslabel='vslabel';
                vstext="VS";
            }

            return (
                <div className="live-match-club-info">
                    <div className="left-fc">
                        <img className="" width="48px" height="48px" style={{alignSelf: 'center'}} src={getTeamFlag(match.teamHome)} alt=""/>
                        <div className="fc-name">{ home }</div>
                        <div className="fc-countryname">{getTeamCountryName(match.teamHome)}</div>
                    </div>

                    <div className="match-result">
                        <div className={statusClass}>{getStatusLabel(match)}</div>
                        <div className={resultClass}>
                            <span className="fc-left-result">{getHomeResult(match)}</span>
                            <span className={vslabel}>{vstext}</span>
                            <span className="fc-right-result">{getGuestResult(match)}</span>
                        </div>
                        <p className="match-result-bottom">{getPastResult(match)}</p>
                    </div>

                    <div className="right-fc">
                        <img width="48px" height="48px" style={{alignSelf: 'center'}} src={getTeamFlag(match.teamGuest)} alt=""/>
                        <div className="fc-name">{ guest }</div>
                        <div className="fc-countryname">{getTeamCountryName(match.teamGuest)}</div>
                    </div>
                </div>
            )
            

            return (
                <div className="live-match-club-info">
                    <div className="left-fc">
                        <a href="#" className="header-star">
                        <svg width="10" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24" stroke="white">
                            <path strokeLinejoin="round" strokeWidth="1"
                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                        </svg>
                        </a>
                        <div className="fc-name">{ home }</div>
                        <div className="fc-country">
                        <img width="25px" src={getTeamFlag(match.teamHome)} alt=""/>
                        <span>{getTeamCountryName(match.teamHome)}</span>
                        </div>
                    </div>

                    <div className="match-result">
                        <div className={statusClass}>{getStatusLabel(match)}</div>
                        <div className={resultClass}>
                            <span className="fc-left-result">{getHomeResult(match)}</span>
                            <span>-</span>
                            <span className="fc-right-result">{getGuestResult(match)}</span>
                        </div>
                        <p className="match-result-bottom">{getPastResult(match)}</p>
                    </div>

                    <div className="right-fc">
                        <a href="#" className="header-star">
                        <svg width="10" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24" stroke="white">
                            <path strokeLinejoin="round" strokeWidth="1"
                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                        </svg>
                        </a>
                        <div className="fc-name">{ guest }</div>
                        <div className="fc-country">
                        <img width="25px" src={getTeamFlag(match.teamGuest)} alt=""/>
                        <span>{getTeamCountryName(match.teamGuest)}</span>
                        </div>
                    </div>
                </div>
            )
        }

        return("")
    }
}

export default BoxScore;
