import React from "react"
import {campaignInfo, getPublishers} from "./Advertising" 
import { matchOddsUrl, doGet } from "../../data/Protocol";
import OddsCell from "./OddsCell"
import OddsHeader from "./OddsHeader";

class Odds extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          data: null,
        }   
      }

    getMatchOdds = async () => {
      const {match, campaign} = this.props;    
      if (campaignInfo){
        var pubs = getPublishers(campaign, "odds")
        var url = matchOddsUrl(match.id, pubs)
        doGet(url, this) 
      }
    }
    
    componentDidMount () {        
      this.getMatchOdds();    
    }
    
    componentWillUnmount() {
      if (this.interval)
        clearInterval(this.interval);
    }
    

    networkSuccess(url, resp) { 

      if (resp === null)
        return;

      const {cb} = this.props

      if (this.interval)
        clearInterval(this.interval);
    
      let refresh = resp.result.r
      let providers = resp.result.providers
      if (providers){
        if (cb)
          cb(providers.length)
        if (refresh > 0)
          this.interval = setInterval(this.getMatchOdds, refresh * 1000)
      }
      this.setState({providers: providers})
    }

    networkError(url,status){
      const {cb} = this.props
      if (cb)
        cb(false)
    }
    

    render (){
      const {providers} = this.state
      const {campaign, tab, match} = this.props

        if (providers){
          return (
            <div className="odds-info">
              <OddsHeader tab={tab} providers={providers} campaign={campaign}/>
              <table>
              <tbody>
                {providers.map(p => <OddsCell key={p.provider_id} match={match} provider={p} campaign={campaign} tab={tab}/>)}
              </tbody>
              </table>
            </div>                    
          )
        }
        return ""
    }
}

export default Odds
  
