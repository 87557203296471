/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { IonContent, IonHeader, IonPage, IonToolbar, IonFooter } from '@ionic/react';
import Advertising from "../components/Advertising/Advertising"
import F24Header from '../components/F24Header';
import "../css/layout.css"
import ExternalOdds from "./Advertising/ExternalOdds";

import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics';

  
const Layout = ({ children, buttons }) => {

  const startAnalytics = async () => {
    const data = await GoogleAnalytics.startTrackerWithId("UA-77365151-5");
  };

  //console.log("LAYOUT " + children);

  return (
    <IonPage>
    <IonContent>
        <F24Header placement="main" buttons={buttons}></F24Header>
        <F24Header placement="today" buttons={buttons}></F24Header>
      <div className="main">
        {children}
      </div>
    </IonContent>
    <IonFooter mode="ios"><Advertising tag="main"/></IonFooter> 
  </IonPage>
  )
}
//  <IonHeader mode="ios" >    
//  </IonHeader>
//<IonFooter mode="ios"><Advertising/></IonFooter> 

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
