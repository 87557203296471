import React from 'react';
import ExploreList from '../components/Explore/ExploreList';

const Explore: React.FC = () => {
  return (
      <ExploreList/>
  );
};

export default Explore;
