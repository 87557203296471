import React from 'react';
import { connect } from 'react-redux';
import { setMatchesUpdated } from '../../state/app';
import { F24TMatchUpdateStatus, F24TMatchUpdateScoreH, F24TMatchUpdateRollbackScoreH,
  F24TMatchUpdateScoreG, F24TMatchUpdateRollbackScoreG, F24TMatchUpdateRedCardsH, F24TMatchUpdateRollbackRedCardsH,
  F24TMatchUpdateRedCardsG, F24TMatchUpdateRollbackRedCardsG, F24TMatchUpdateStatusPartial } from '../../data/MatchData';

class EventsSound extends React.Component {

  constructor(props){
    super(props)
    
    const soundEffect = new Audio();
    soundEffect.autoplay = false;
    soundEffect.src=""
    
    this.state={
      soundEffect: soundEffect,
    }
  }


  render(){
    const { changes, data } = this.props
    const { soundEffect } = this.state
    
    console.log("MUPDATE [play] " + changes)
    if (changes > 0){
      if ((changes & F24TMatchUpdateScoreH) === F24TMatchUpdateScoreH) { // HOME GOAL
        soundEffect.src = '/assets/sounds/goal01.mp3';
      } else if ((changes & F24TMatchUpdateRollbackScoreH) === F24TMatchUpdateRollbackScoreH) {
        soundEffect.src = '/assets/sounds/error01.mp3';
      } else if ((changes & F24TMatchUpdateScoreG) === F24TMatchUpdateScoreG) {     // GUEST GOAL
        soundEffect.src = '/assets/sounds/goal01.mp3';
      } else if ((changes & F24TMatchUpdateRollbackScoreG) === F24TMatchUpdateRollbackScoreG) {
        soundEffect.src = '/assets/sounds/error01.mp3';
      } else if ((changes & F24TMatchUpdateRedCardsH) === F24TMatchUpdateRedCardsH) {  // red card HOME
        soundEffect.src = '/assets/sounds/whistle01.mp3';
      } else if ((changes & F24TMatchUpdateRollbackRedCardsH) === F24TMatchUpdateRollbackRedCardsH) {
        soundEffect.src = '/assets/sounds/error01.mp3';
      } else if ((changes & F24TMatchUpdateRedCardsG) === F24TMatchUpdateRedCardsG) {  // red card GUEST
        soundEffect.src = '/assets/sounds/whistle01.mp3';
      } else if ((changes & F24TMatchUpdateRollbackRedCardsG) === F24TMatchUpdateRollbackRedCardsG) {
        soundEffect.src = '/assets/sounds/error01.mp3';
      }else if ((changes & F24TMatchUpdateStatus) === F24TMatchUpdateStatus ) {  // END PERIOD
        soundEffect.src = '/assets/sounds/end_period_dflt.mp3';
      }else if ((changes & F24TMatchUpdateStatusPartial) === F24TMatchUpdateStatusPartial ) {  // END PERIOD
        soundEffect.src = '/assets/sounds/end_period_dflt.mp3';
      }       

      if (changes !== 0){
        if (changes > 1)
          soundEffect.play()
        data.changes = 0
        this.props.dispatch(setMatchesUpdated({data:data}));
      }
    }
    
    return ""
  }

}

export default connect(state => ({
  data: state.app.data,
}), null)(EventsSound)




