import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonFooter } from '@ionic/react';
import Advertising from '../components/Advertising/Advertising';
import TeamContainer from '../components/Teams/TeamContainer';
import F24Header from '../components/F24Header';

interface CompetitionPageProps extends RouteComponentProps<{
  id: string;
}> {}

const CompetitionPage: React.FC<CompetitionPageProps> = ({match}) => {
  return(
    <IonPage>
      <IonHeader>
        <F24Header fulllogo={true}/>
      </IonHeader>
    <IonContent>
      <TeamContainer id={match.params.id}></TeamContainer>
    </IonContent>
    <IonFooter mode="ios"><Advertising/></IonFooter>
    </IonPage>
  )
};

export default CompetitionPage;
