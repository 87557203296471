import React from "react"
import {homeTeamId, guestTeamId, homeTeamName, guestTeamName, getHomeResult, getGuestResult} from "../../data/MatchData"
import {COLORS} from '../../data/colors'

const POS_COLOR_1 = COLORS.springGreen;
const POS_COLOR_2 = COLORS.springGreenTwo;
const POS_COLOR_3 = COLORS.lightLightGreen;
const POS_COLOR_4 = COLORS.springGreen;
const POS_COLOR_5 = COLORS.mossGreen;
const POS_COLOR_6 = COLORS.mediumPink;
const POS_COLOR_7 = COLORS.mediumPink;


class Statistics extends React.Component {

    getPositionColor(pos){
        var color = "#fff"

        switch (pos){
            case 1:
                color = POS_COLOR_1
                break;
            case 2:
                color = POS_COLOR_2
                break;
            case 3:
                color = POS_COLOR_3
                break;
            case 4:
                color = POS_COLOR_4
                break;
            case 5:
                color = POS_COLOR_5
                break;
            case 6:
                color = POS_COLOR_6
                break;
            case 7:
                color = POS_COLOR_7
                break;
            default:
                color = COLORS.greyHeader
                break;
        }
        return color
    }

    Standings(){
        const {match} = this.props;

        if (match.statistics.standings && match.statistics.standings.list && match.statistics.standings.list.length){
            let list = match.statistics.standings.list
            //console.log("STAND " + JSON.stringify(s))

            let home = homeTeamName(match);
            let guest = guestTeamName(match);
            let homeId = homeTeamId(match);

            let firstTeam = (homeId === list[0].team_id) ? home : guest;
            let secondTeam = (homeId === list[0].team_id) ? guest : home;
            
            return(
                <div style={{marginTop:'10px'}}>
                <table>
                <tbody>
                <tr className="header">
                    <td colSpan="2"></td>
                    <td className="G">G</td>
                    <td className="V">V</td>
                    <td className="P">P</td>
                    <td className="S">S</td>
                    <td className="goal">Goal</td>
                    <td className="fc-list-name">Pt</td>
                </tr>
                <tr className="fc-stat-info">
                    <td className="list-number"><p style={{background: this.getPositionColor(list[0].place)}}>{list[0].place}</p></td>
                    <td className="fc-list-name">{firstTeam}</td>
                    <td className="fc-list-data">{list[0].games_played}</td>
                    <td className="fc-list-data">{list[0].record_wins}</td>
                    <td className="fc-list-data">{list[0].record_drawn}</td>
                    <td className="fc-list-data">{list[0].record_losses}</td>
                    <td className="fc-list-data">{list[0].goals_forward}:{list[0].goals_against}</td>
                    <td className="fc-list-name" style={{textAlign:'center'}}>{list[0].points}</td>
                </tr>
                <tr className="distance-indicator">
                    <td colSpan="7">
                    <div className="indicator"></div>
                    <div className="border"></div>
                    </td>
                </tr>
                <tr className="fc-stat-info">
                    <td className="list-number"><p style={{background: this.getPositionColor(list[1].place)}}>{list[1].place}</p></td>
                    <td className="fc-list-name">{secondTeam}</td>
                    <td className="fc-list-data">{list[1].games_played}</td>
                    <td className="fc-list-data">{list[1].record_wins}</td>
                    <td className="fc-list-data">{list[1].record_drawn}</td>
                    <td className="fc-list-data">{list[1].record_losses}</td>
                    <td className="fc-list-data">{list[1].goals_forward}:{list[1].goals_against}</td>
                    <td className="fc-list-name" style={{textAlign:'center'}}>{list[1].points}</td>
                </tr>                
                </tbody>
                </table>
                </div>
            )
        }
        return ""
    }

    LastResult(home, guest){
        const {match} = this.props;
        var homeName = homeTeamName(match);
        var guestName = guestTeamName(match);
        if (match.statistics.results && match.statistics.results[home] && match.statistics.results[guest]){
            var hres = match.statistics.results[home];
            var gres = match.statistics.results[guest];
            
            return(
                <div>
                    <div className="stats-last-result">
                        <div className="club-name">{homeName}</div>
                        <div className="circle">
                            {hres.map((a, index) =><p key={index} className={a}>{a}</p>)}
                        </div>
                    </div>
                    <div className="stats-last-result">
                        <div className="club-name">{guestName}</div>
                        <div className="circle">
                        {gres.map((a, index) =><p key={index} className={a}>{a}</p>)}
                        </div>
                    </div>
                </div>            
            )    
        }
        
        return ""
    }


    UnderOver(home, guest){
        const {match} = this.props;

        if (match.statistics.under_over){
            var hs = match.statistics.under_over[home];
            var gs = match.statistics.under_over[guest];
            if (!hs || !gs)
            return
            var homeName = homeTeamName(match);
            var guestName = guestTeamName(match);
            
            var home_over_perc  = Math.round(hs['over'] / hs['games'] * 100)
            var guest_over_perc = Math.round(gs['over'] / gs['games'] * 100)
            var home_under_perc  = Math.round(hs['under'] / hs['games'] * 100)
            var guest_under_perc = Math.round(gs['under'] / gs['games'] * 100)

            return(
                <div className="under-over">
                    <div className="section-title">Under/Over 2.5</div>
                    <div>
                        <div className="top under-over-list">
                            <div className="left-number">{home_over_perc}%</div>
                            <div className="line left-line"><div className="line-orange left" style={{width: home_over_perc + "%"}}/></div>
                            <div className="result"><strong>{hs['over']}</strong> OVER <strong>{gs['over']}</strong></div>
                            <div className="line right-line"><div className="line-orange right" style={{width: guest_over_perc + "%"}}/></div>
                            <div className="right-number">{guest_over_perc}%</div>
                        </div>
                        <div className="top under-over-list">
                            <div className="left-number">{home_under_perc}%</div>
                            <div className="line left-line"><div className="line-orange left" style={{width: home_under_perc + "%"}}/></div>
                            <div className="result"><strong>{hs['under']}</strong> UNDER <strong>{gs['under']}</strong></div>
                            <div className="line right-line"><div className="line-orange right" style={{width: guest_under_perc + "%"}}/></div>
                            <div className="right-number">{guest_under_perc}%</div>
                        </div>
                        <div className="club-names">
                            <div className="left-club-name">{homeName}</div>
                            <div className="right-club-name">{guestName}</div>
                        </div>
                    </div>
                </div>            
            )
        }

        return ""
    }


    UnderOver_2(home, guest){
        const {match} = this.props;

        if (match.statistics.under_over){
            var hs = match.statistics.under_over[home];
            var gs = match.statistics.under_over[guest];
            if (!hs || !gs)
            return
            var homeName = homeTeamName(match);
            var guestName = guestTeamName(match);
            
            var home_over_perc  = Math.round(hs['over'] / hs['games'] * 100)
            var guest_over_perc = Math.round(gs['over'] / gs['games'] * 100)
            var home_under_perc  = Math.round(hs['under'] / hs['games'] * 100)
            var guest_under_perc = Math.round(gs['under'] / gs['games'] * 100)

            return(
                <div className="under-over">
                    
                        <div className="top">
                            <div className="club-name">{homeName}</div>                            
                            <div className="uo-container">
                                <div className="uo-box">
                                    <div className="label">over 2.5</div>
                                    <div className="uo-percentage">{home_over_perc}%</div>
                                    <div className="line white">
                                        <div className="line-orange right" style={{width: home_over_perc + "%"}}/>
                                    </div>
                                    <div className="uo-number">{hs['over']}</div>
                                </div>
                                <div className="uo-box">
                                    <div className="label">under 2.5</div>
                                    <div className="uo-percentage">{home_under_perc}%</div>
                                    <div className="line white">
                                        <div className="line-orange right" style={{width: home_under_perc + "%"}}/>
                                    </div>
                                    <div className="uo-number">{hs['under']}</div>
                                </div>
                            </div>
                        </div>
                        <div className="top">
                        <div className="club-name">{guestName}</div>                            
                            <div className="uo-container">
                                <div className="uo-box">
                                    <div className="label">over 2.5</div>
                                    <div className="uo-percentage">{guest_over_perc}%</div>
                                    <div className="line white">
                                        <div className="line-orange right" style={{width: guest_over_perc + "%"}}/>
                                    </div>
                                    <div className="uo-number">{gs['over']}</div>
                                </div>
                                <div className="uo-box">
                                    <div className="label">under 2.5</div>
                                    <div className="uo-percentage">{guest_under_perc}%</div>
                                    <div className="line white">
                                        <div className="line-orange right" style={{width: guest_under_perc + "%"}}/>
                                    </div>
                                    <div className="uo-number">{gs['under']}</div>
                                </div>
                            </div>
                        </div>
                    
                </div>            
            )
        }

        return ""
    }


    render (){
        const {match} = this.props;
        if (match){
            if (match.statistics){
                var home = homeTeamId(match);
                var guest = guestTeamId(match);

                return (
                <div className="statistics">
                    <div className="page-title">Table</div>
                    <div className="statistics-content">
                        {this.Standings()}
                    </div>
                    <div className="page-title">Over/Under</div>
                    <div className="statistics-content">                        
                        {this.UnderOver_2(home, guest)}
                    </div>
                    <div className="page-title">Last Result</div>
                    <div className="statistics-content">                        
                        {this.LastResult(home, guest)}
                    </div>

                </div>
                )
            }
        }

        return("")
    }
}

export default Statistics;

//{this.H2h(home, guest)}