import React from 'react';

import { Link } from 'react-router-dom';
import Layout from '../layout'
import "../../css/layout.css"
import TabsHeader from '../TabsHeader';
import Competitions from '../Explore/Competitions';
import Teams from '../Explore/Teams';
import CompetitionTable from './competitionTable';
import Competition from './Competition';
import CompetitionMatches from './CompetitionMatches';

///////////////////////////     COMPONENT

class CompetitionContainer extends React.Component {
  constructor(props) {
    super(props);
    var {tab_selected} = this.props

    console.log("COMPETITIONS init tab selected " + tab_selected)
    if (!tab_selected)
      tab_selected = 2

    this.state = {
      tab: tab_selected,
      matches: null,
      days: null,
      name: null,
      flag: null
    }
  }

  setHeader(context, name, flag){
    if (context.state.name === null)
      context.setState({flag: flag, name: name})
  }

  setMatches(context, resp, name){
    context.setState({resp: resp, name: name})
  }

  setTab(param){
    param[0].setState({tab: param[1]})
  }
  
  getHeader(){
    const {name, flag} = this.state
    var n = name
    if (name === null)
      n = "loading"
    
    return(<h1>{n}</h1>)
  }

  render() {

    var {tab} = this.state

    console.log("COMPETITIONS render tab selected " + tab)

    var tab0 ={ 
      name: "Results",
      cb: this.setTab,
      params: [this, 0],
      selected: tab === 0 ? "selected" : ""
    }

    var tab1 ={ 
      name: "Fixtures",
      cb: this.setTab,
      params: [this, 1],
      selected: tab === 1 ? "selected" : ""
    }

    var tab2 ={ 
      name: "Table",
      cb: this.setTab,
      params: [this, 2],
      selected: tab === 2 ? "selected" : ""
    }

    var tabs = []
    tabs.push(tab0);
    tabs.push(tab1);
    tabs.push(tab2);

    return (
        <div className="content" >
          {this.getHeader()}
          <TabsHeader tabs={tabs}/>
          {tab === 0 && <CompetitionMatches id={this.props.id} type={0} resp={this.state.resp} cb={this.setMatches} context={this}/>} 
          {tab === 1 && <CompetitionMatches id={this.props.id} type={1} resp={this.state.resp} cb={this.setMatches} context={this}/>}
          {tab === 2 && <CompetitionTable id={this.props.id} context={this} setHeader={this.setHeader}/>}
        </div>
      );

  }
} // class

export default CompetitionContainer

