import React from 'react';
import MatchesToday from '../components/Matches/MatchesToday';

const Matches: React.FC = () => {
  return(
    <MatchesToday></MatchesToday>
  )
 }

 export default Matches;


