
import { compareCompetitions } from "../components/Competitions/Competition"

export const orderCompetitions = (competitions_server) => {  
  let orderedCompetitions = [];
  competitions_server.forEach(c => {   
    let index = 0;
    var c_ref
    for (index = 0; index < orderedCompetitions.length; index++) {
      c_ref = competitions_server.find(e=>e.id===orderedCompetitions[index].id);

      if (compareCompetitions(c, c_ref) < 0)
        break;
    }
    var matches = [[],[],[]];
    let c_elem = { id:c.id, matches:matches, competition: c};
    orderedCompetitions.splice(index, 0, c_elem);
  });

  return orderedCompetitions;
}