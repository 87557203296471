import React from "react"
import {getEventIcon} from "../../data/MatchData"

class EventCell extends React.Component {

    getExtraTime(){
        const {action} = this.props;
        var minute_add = action.minutes_add
        if (minute_add)
            return(
                <span style={{verticalAlign: "top", fontSize: "10px"}}>+{minute_add}</span>
            )
        return ""
    }

    render (){
        const {action} = this.props;
        if (action){
            var minute = action.minutes
            var player = action.player ? action.player : "-"
            var align  = action.team_offensive === 1 ? "reverse" : "";
                        
            return (
                <div className={"eventi-list " + align} >
                    <div className="player-name">{player}</div>
                    <div className="status">
                        <img alt="event" width="20" src={getEventIcon(action.type_id)}/>
                    </div>
                    <div className="score">{minute}{this.getExtraTime()}</div>
              </div>
          
            )

        }

        return("")
    }
}

export default EventCell;