import {merge} from "./Utils";
import { compareCompetitions } from "../components/Competitions/Competition"
import ico_match_event_card_red from "../images/ico_match_event_card_red.png" 
import ico_match_event_card_yellow from "../images/ico_match_event_card_yellow.png" 
//import ico_match_event_goal_12 from "../images/ico_match_event_goal_12.png" 
//import ico_match_event_goal_cancel_12 from "../images/ico_match_event_goal_cancel_12.png" 
import ico_match_event_goal from "../images/ico_match_event_goal.png" 
import ico_match_event_owngoal from "../images/ico_match_event_owngoal.png" 
import ico_match_event_penalty from "../images/ico_match_event_penalty.png" 
//import ico_match_event_penalty_12 from "../images/ico_match_event_penalty_12.png" 
//import ico_match_event_penalty_missed_12 from "../images/ico_match_event_penalty_missed_12.png" 
import ico_match_event_penalty_missed from "../images/ico_match_event_penalty_missed.png" 
import { isMatchFavorite } from "./Favorites";
import { isCompetitionFiltered, isListFiltered } from "./Filters";
import { stringify } from "querystring";

const STATUS_SCHEDULED    = 1;
const STATUS_FIRST_HALF   = 2;
const STATUS_HALF_TIME    = 3;
const STATUS_SECOND_HALF  = 4;
const STATUS_FINAL        = 5;
const STATUS_EXTRA        = 6;
const STATUS_PENALTIES    = 7;
const STATUS_EXTRA_FINAL  = 8;
const STATUS_PENALTIES_FINAL = 9;
const STATUS_ABANDONED    = 10;
const STATUS_POSTPONED    = 11;
const STATUS_FT_ONLY      = 12;
const STATUS_SUSPENDED    = 13;
const STATUS_PENALTIES_WITHOUT_EXTRA       = 14;
const STATUS_PENALTIES_FINAL_WITHOUT_EXTRA = 15;
const STATUS_WALKOVER     = 16;
const STATUS_ANNULLED     = 17;
const STATUS_CANCELLED    = 18;


    // CHANGES STATUS
    export const F24TMatchUpdateStatus                   = 1 << 1;
    export const F24TMatchUpdateStatusPartial            = 1 << 2;
    // scores
    export const F24TMatchUpdateScores                   = 1 << 7;
    export const F24TMatchUpdateScoreH                   = 1 << 8;
    export const F24TMatchUpdateRollbackScoreH           = 1 << 9;
    export const F24TMatchUpdateScoreG                   = 1 << 10;
    export const F24TMatchUpdateRollbackScoreG           = 1 << 11;

    export const F24TMatchUpdateMinute                   = 1 << 12;
            // cards //
    export const F24TMatchUpdateCards                    = 1 << 34;
    export const F24TMatchUpdateRedCardsH                = 1 << 13;
    export const F24TMatchUpdateRollbackRedCardsH        = 1 << 14;
    export const F24TMatchUpdateRedCardsG                = 1 << 15;
    export const F24TMatchUpdateRollbackRedCardsG        = 1 << 16;
    export const F24TMatchUpdateYellowCardsH             = 1 << 17;
    export const F24TMatchUpdateRollbackYellowCardsH     = 1 << 18;
    export const F24TMatchUpdateYellowCardsG             = 1 << 19;
    export const F24TMatchUpdateRollbackYellowCardsG     = 1 << 20;
    
    export const F24TMatchUpdateTeams                    = 1 << 21;
    //const F24TMatchUpdateDetails                  = 1 << 35;

    export const TeamUpdatedScore                = 1 << 0;
    export const TeamUpdatedScoreRoolback        = 1 << 1;
    export const TeamUpdatedYellowCards          = 1 << 2;
    export const TeamUpdatedRedCards             = 1 << 3;
    export const TeamUpdatedYellowCardsRollback  = 1 << 4;
    export const TeamUpdatedRedCardsRollback     = 1 << 5;

    const TYPE_GOAL = 1;
    const TYPE_RED_CARD = 2;
    const TYPE_YELLOW_CARD = 3;
    const TYPE_OWN_GOAL = 4;
    const TYPE_PENALTY = 5;
    const TYPE_MISSED_PENALTY = 6;
    
export const getEventIcon = (type) => {   
  switch (type) {
    case TYPE_GOAL:
      return ico_match_event_goal;

    case TYPE_RED_CARD:
        return ico_match_event_card_red;

    case TYPE_YELLOW_CARD:
        return ico_match_event_card_yellow;

    case TYPE_OWN_GOAL:
        return ico_match_event_owngoal;

    case TYPE_PENALTY:
        return ico_match_event_penalty;

    case TYPE_MISSED_PENALTY:
        return ico_match_event_penalty_missed;

    default:
      return "";
  }
}
   
export const homeTeamId= (match) => {
  return match.home.team_id
}
 
export const guestTeamId= (match) => {
  return match.guest.team_id
}

export const homeTeamName= (match) => {
  var name = ""
  if (match && match.teamHome && match.teamHome.sname)
    name = match.teamHome.sname
  return name
}

export const guestTeamName= (match) => {
  if (match && match.teamGuest && match.teamGuest.sname)
    return match.teamGuest.sname
  return "-"
}

export const competitionName= (match) => {
  if (match && match.league && match.league.competition && match.league.competition.name)
    return match.league.competition.name
  return "-"
}


export const redCards= (t) => {
    if (t.cards !== null)
      return t.cards[1];
    return 0;
  }

export  const yellowCards= (t) => {
    if (t.cards !== null)
      return t.cards[0];
    return 0;
  }

export const checkCards = (c, old) => {
  let changes = 0;
/*
  if(yellowCards(c) > yellowCards(old)){
    changes |= TeamUpdatedYellowCards;
  } else if(yellowCards(c) < yellowCards(old)){
    changes |= TeamUpdatedYellowCardsRollback;
  }
*/
    if(redCards(c) > redCards(old)){
      changes |= TeamUpdatedRedCards;
    } else if(redCards(c) < redCards(old)){
    changes |= TeamUpdatedRedCardsRollback;
  }

  return changes;
}

export const checkGoals = (t, old) => {
  let changes = 0;

  if(t.results[0] > old.results[0]){
    changes |= TeamUpdatedScore;
  } else if(t.results[0] < old.results[0]){
    changes |= TeamUpdatedScoreRoolback;
  }
  return changes;
}

export const updateMatchData = (m, old)=>{
  let status_id = m.status_id
  let changes = 0;
  m.isJustFinished = old.isJustFinished
  if (m.updated > old.updated) {
    // Status
    if (m.status_id !== old.status_id) {
      if (isStatusFinshed(m)){
        changes |= F24TMatchUpdateStatus;
        m.isJustFinished = Date.now();
        //console.log("SOUND  Match [JF] " + m.id + " just finished " + m.isJustFinished)
      }else{
        if ( status_id === STATUS_HALF_TIME || status_id === STATUS_EXTRA || status_id === STATUS_PENALTIES || status_id === STATUS_PENALTIES_WITHOUT_EXTRA || status_id === STATUS_SUSPENDED);
        changes |= F24TMatchUpdateStatusPartial
      }
    }
/*
    // Minute
    if (m.minutes !== old.minutes || m.injury !== old.injury) {
      //console.log("SOUND UPDATING MATCH [" + m.id + "] F24TMatchUpdateMinute")
      changes |= F24TMatchUpdateMinute;
    }
*/
    // Scores
    let hGoalChanges = checkGoals(m.home, old.home);
    if (hGoalChanges !== 0) {
        if ((hGoalChanges & TeamUpdatedScore) !== 0)
            changes |= F24TMatchUpdateScoreH;
        if ((hGoalChanges & TeamUpdatedScoreRoolback) !== 0)
            changes |= F24TMatchUpdateRollbackScoreH;
        changes |= F24TMatchUpdateTeams | F24TMatchUpdateScores;
    }

    let gGoalChanges = checkGoals(m.guest, old.guest)
    if (gGoalChanges !== 0) {
        if ((gGoalChanges & TeamUpdatedScore) !== 0)
            changes |= F24TMatchUpdateScoreG;
        if ((gGoalChanges & TeamUpdatedScoreRoolback) !== 0)
            changes |= F24TMatchUpdateRollbackScoreG;
        
        changes |= F24TMatchUpdateTeams | F24TMatchUpdateScores;
    }

    // Cards
  
    let hCardsChanges = checkCards(m.home, old.home);
    if (hCardsChanges !== 0) {
      //console.log("SOUND UPDATING MATCH [" + m.id + "] HOME F24TMatchUpdateTeams | F24TMatchUpdateCards")

        if ((hCardsChanges & TeamUpdatedYellowCards) !== 0)
            changes |= F24TMatchUpdateYellowCardsH;
        if ((hCardsChanges & TeamUpdatedYellowCardsRollback) !== 0)
            changes |= F24TMatchUpdateRollbackYellowCardsH;
        if ((hCardsChanges & TeamUpdatedRedCards) !== 0)
            changes |= F24TMatchUpdateRedCardsH;
        if ((hCardsChanges & TeamUpdatedRedCardsRollback) !== 0)
            changes |= F24TMatchUpdateRollbackRedCardsH;
        changes |= F24TMatchUpdateTeams | F24TMatchUpdateCards;
    }
    let gCardsChanges = checkCards(m.guest, old.guest);
    if (gCardsChanges !== 0) {
      //console.log("SOUND UPDATING MATCH [" + m.id + "] HOME F24TMatchUpdateTeams | F24TMatchUpdateCards")

        if ((gCardsChanges & TeamUpdatedYellowCards) !== 0)
            changes |= F24TMatchUpdateYellowCardsG;
        if ((gCardsChanges & TeamUpdatedYellowCardsRollback) !== 0)
            changes |= F24TMatchUpdateRollbackYellowCardsG;
        if ((gCardsChanges & TeamUpdatedRedCards) !== 0)
            changes |= F24TMatchUpdateRedCardsG;
        if ((gCardsChanges & TeamUpdatedRedCardsRollback) !== 0)
            changes |= F24TMatchUpdateRollbackRedCardsG;
        changes |= F24TMatchUpdateTeams | F24TMatchUpdateCards;
    }
  }

  if (typeof m.available === 'undefined'){
    m.available = 1
  }

  m.changes = changes;
  m.changes_time = 0
  let date = Date.now()

  if (m.changes > 0){
    m.changes_time = date
    //console.log("MUPDATE [NEW]:" + m.id + " - " + m.changes + " date " + date)
  }else{
    if (old.changes_time > 0){
      var elapsed = Date.now() - old.changes_time
      //console.log("MUPDATE [OLD]:" + m.id + " - " + old.changes + " date " + old.changes_time + " elapsed: " + elapsed)
      if (elapsed < 15000){
        m.changes = old.changes
        m.changes_time = old.changes_time
      }
    }
    return 0
  }
  //console.log("MUPDATE [RET]:" + m.changes + " date " + m.changes_time)

  return m.changes
}

export const checkMatchUpdate = (m) => {
  if (m.changes_time > 0){
    var elapsed = Date.now() - m.changes_time
    
    if (elapsed > 15000){
      m.changes = 0
      m.changes_time = 0
      //console.log("MUPDATE [OLD]:" + m.id + " - RESET elapsed: " + elapsed)
      return 1
    }else{
      //console.log("MUPDATE [OLD]:" + m.id + " - " + m.changes + " date " + m.changes_time + " elapsed: " + elapsed)
    }
  }
  return 0
}

export const isSecondHalfPlayed = (m)=>{
  let status_id = m.status_id;
  return ( status_id === STATUS_FINAL || status_id === STATUS_SECOND_HALF || status_id === STATUS_HALF_TIME || status_id === STATUS_EXTRA || status_id === STATUS_PENALTIES || status_id === STATUS_PENALTIES_WITHOUT_EXTRA || status_id === STATUS_SUSPENDED);
}

export const isPlaying = (m)=>{
  let status_id = m.status_id;
  return (isJustFinished(m) || status_id === STATUS_FIRST_HALF || status_id === STATUS_HALF_TIME || status_id === STATUS_SECOND_HALF || status_id === STATUS_EXTRA || status_id === STATUS_PENALTIES || status_id === STATUS_PENALTIES_WITHOUT_EXTRA || status_id === STATUS_SUSPENDED);
}

export const isStarted = (m)=>{
  let status_id = m.status_id;
  return (status_id !== STATUS_SCHEDULED && status_id !== STATUS_POSTPONED) ;
}

export const isScheduled = (m)=>{
  let status_id = m.status_id;
  return (status_id === STATUS_SCHEDULED) ;
}

export const isJustFinished = (m)=>{
  if (m.isJustFinished > 0)
    return true;
  return false;
}

export const isStatusFinshed = (m)=>{
  let status_id = m.status_id;
  return (status_id === STATUS_FINAL || status_id === STATUS_EXTRA_FINAL || status_id === STATUS_PENALTIES_FINAL || status_id === STATUS_ABANDONED || status_id === STATUS_POSTPONED || status_id === STATUS_FT_ONLY
          || status_id === STATUS_PENALTIES_FINAL_WITHOUT_EXTRA || status_id === STATUS_WALKOVER);
}

export const isEnded = (m)=>{
  let status_id = m.status_id;
  return ((status_id === STATUS_FINAL || status_id === STATUS_EXTRA_FINAL || status_id === STATUS_PENALTIES_FINAL || status_id === STATUS_ABANDONED || status_id === STATUS_CANCELLED || status_id === STATUS_POSTPONED || status_id === STATUS_FT_ONLY
          || status_id === STATUS_PENALTIES_FINAL_WITHOUT_EXTRA || status_id === STATUS_WALKOVER) && !isJustFinished(m));
}

export const isCancelled = (m)=>{
  let status_id = m.status_id;
  return (status_id === STATUS_ANNULLED || status_id === STATUS_CANCELLED);
}

export const isWalkOver = (m)=>{
  let status_id = m.status_id;
  return status_id === STATUS_WALKOVER;
}

export const isSuspended = (m)=>{
  let status_id = m.status_id;
  return status_id === STATUS_SUSPENDED;
}

export const isAbandoned = (m)=>{
  let status_id = m.status_id;
  return status_id === STATUS_ABANDONED;
}

export const isPostPoned = (m)=>{
  let status_id = m.status_id;
  return status_id === STATUS_POSTPONED;
}

export const isWhiteDraw = (m)=>{
  if (m.home != null  && m.guest != null)
      return (m.home.Goals() === 0 && m.guest.Goals() === 0);
  return false;
}

export const isExtraTimePlayed = (m)=>{
  let status_id = m.status_id;
  return (isStatusFinshed(m) && !(status_id === STATUS_PENALTIES_WITHOUT_EXTRA || status_id === STATUS_PENALTIES_FINAL_WITHOUT_EXTRA));
}

export const compareMatch = (match1, match2)=>{
  if (match1.start_date < match2.start_date)
    return -1;
  if (match1.start_date === match2.start_date){
    let i = compareCompetitions(match1.league.competition, match2.league.competition);
    if ( i !== 0)
      return i;
    let a = match1.teamHome.sname;
    let b = match2.teamHome.sname;
    return (a<b?-1:(a>b?1:0));
  }
  return 1
}

export const checkMatchRange = (match, range) => {
  return ((range.start <= match.start_date && match.start_date <= range.end) ? true : false)
}

export const completeMatch = (match, data, favorites)=>{
  let leagues = data.leagues.list;
  let teams = data.teams.list;
  
  if (!match.home  || !match.guest ){
    //console.log("MATCH ERROR " + JSON.stringify(match));
    //console.log("MATCH ERROR " + (match.id));
    return;
  }
  
  if (typeof match.available === 'undefined'){
    match.available = 1
  }

  /*
  match.favorite = 0
  if (typeof favorites !== 'undefined' && favorites != null){
    const i = favorites.findIndex((e) => e === id);
    if (i >= 0)
      match.favorite = 1
  }*/

  const league = leagues.find(e => e.id === match.league_id);
  const team_h = teams.find(t => t.id === match.home.team_id);
  const team_g = teams.find(t => t.id === match.guest.team_id);

  match.league = (league);
  match.teamHome  = team_h;
  match.teamGuest = team_g;
}

export const getDetailsTitle = (match)=>{  
  if (isPlaying(match)){
    return "Live"
  }

  if (isScheduled(match)){
    return "Prematch"
  }

  return "Final"
}

export const getStartHour = (match)=>{
  var epoch = new Date(match.start_date * 1000);
  var offset = epoch.getTimezoneOffset() * 60;
  var start = new Date((match.start_date-offset) * 1000);;
  start = start.toISOString().slice(-13, -8);
  return start;

}
  
export const getStatusLabel = (match)=>{
  let status_id = match.status_id;
  var extra = "";
  var statusString = "";
  
  if (isPlaying(match)){
    extra = (match.injury === true) ? "'+" : "'";
    statusString = !(match.minutes === "") ? match.minutes + extra : "-";
  }

  switch (status_id) {
        case STATUS_SCHEDULED:
            /*statusString = "";
            if ((available_mask & F24AvailableMaskResultAggregateFL) === F24AvailableMaskResultAggregateFL) {
                statusString = " (" + getFirstLeg() + ")";
            }*/
            
            var epoch = new Date(match.start_date * 1000);
            var offset = epoch.getTimezoneOffset() * 60;
            var start = new Date((match.start_date-offset) * 1000);;
            start = start.toISOString().slice(-13, -8);
            statusString = start;

            break;
        case STATUS_FIRST_HALF:
            break;
        case STATUS_HALF_TIME:
            statusString = "HT";
            break;
        case STATUS_SECOND_HALF:
            break;
        case STATUS_FINAL:
            statusString = "FT";
            break;
        case STATUS_EXTRA:
            statusString = "ET";
            break;
        case STATUS_PENALTIES:
        case STATUS_PENALTIES_WITHOUT_EXTRA:
            statusString = "Pen.";
            break;
        case STATUS_EXTRA_FINAL:
            statusString = "AET";
            break;
        case STATUS_PENALTIES_FINAL:
        case STATUS_PENALTIES_FINAL_WITHOUT_EXTRA:
            statusString = "AP";
            break;
        case STATUS_ABANDONED:
            statusString = "ABD";
            break;
        case STATUS_FT_ONLY:
            statusString = "FTO";
            break;
        case STATUS_POSTPONED:
            statusString = "Post.";
            break;
        case STATUS_SUSPENDED:
            statusString = "Susp.";
            break;
        case STATUS_WALKOVER:
            statusString = "W.O.";
            break;
        case STATUS_ANNULLED:
            statusString = "Ann.";
            break;
        case STATUS_CANCELLED:
            statusString = "Canc.";
            break;
        default:
            statusString = "ERR";
            break;
    }
    return statusString;
}

export function getPastResult(m) {
  var resultString = "";
  var status_id = m.status_id
  
  if (status_id !== STATUS_FT_ONLY) {
    switch (status_id) {
      case STATUS_SCHEDULED:
      {
        //long now = System.currentTimeMillis();
        //countdown = start_date * 1000L - now ;
        break;
      }
      case STATUS_HALF_TIME:
      case STATUS_SECOND_HALF:
      case STATUS_FINAL:
      resultString = "HT " + getHomeHTResult(m) + "-" + getGuestHTResult(m);
        break;
      case STATUS_EXTRA:
        resultString = "HT " + getHomeHTResult(m) + "-" + getGuestHTResult(m);
        resultString = resultString + " FT " + getHomeResult(m) + "-" + getGuestResult(m);
        break;
      case STATUS_PENALTIES:
      case STATUS_PENALTIES_WITHOUT_EXTRA:
        if (isExtraTimePlayed(m)) {
          resultString = "HT " + getHomeHTResult(m) + "-" + getGuestHTResult(m);
          resultString = resultString + " FT " + getHomeResult(m) + "-" + getGuestResult(m);
          resultString = resultString + " ET " + getHomeETResult(m) + "-" + getGuestETResult(m);
        } else {
          resultString = "HT " + getHomeHTResult(m) + "-" + getGuestHTResult(m);
          resultString = resultString + " FT " + getHomeResult(m) + "-" + getGuestResult(m);
        }
        break;
      case STATUS_EXTRA_FINAL:
        resultString = "HT " + getHomeHTResult(m) + "-" + getGuestHTResult(m);
        resultString = resultString + " FT " + getHomeResult(m) + "-" + getGuestResult(m);
        break;
      case STATUS_PENALTIES_FINAL:
      case STATUS_PENALTIES_FINAL_WITHOUT_EXTRA:
        if (isExtraTimePlayed(m)) {
          resultString = "HT " + getHomeHTResult(m) + "-" + getGuestHTResult(m);
          resultString = resultString + " FT " + getHomeResult(m) + "-" + getGuestResult(m);
          resultString = resultString + " ET " + getHomeETResult(m) + "-" + getGuestETResult(m);
        } else {
          resultString = "HT " + getHomeHTResult(m) + "-" + getGuestHTResult(m);
          resultString = resultString + " FT " + getHomeResult(m) + "-" + getGuestResult(m);
        }
        break;
      default:
        break
    }
  }
  return resultString
}

export function getResult(m) {
  let status_id = m.status_id;
  var resultString = "";

  if ((isPlaying(m) || isEnded(m)) && !isPostPoned(m) && !isCancelled(m)) {
    if (status_id === STATUS_PENALTIES || status_id === STATUS_PENALTIES_WITHOUT_EXTRA) {
      resultString = m.home.results[4] + "-" + m.guest.results[4];
    } else {
      resultString = m.home.results[0] + "-" + m.guest.results[0];
    }
  }
  return resultString;
}

export function getFHResult(m) {
  var resultString = "-";

  if (isSecondHalfPlayed(m))
    return "("+ m.home.results[1] + "-" + m.guest.results[1] + ")";
  return "";
}



export function getHomeResult(m) {
  let status_id = m.status_id;
  var resultString = "";

  if ((isPlaying(m) || isEnded(m)) && !isPostPoned(m) && !isCancelled(m)) {
    if (status_id === STATUS_PENALTIES || status_id === STATUS_PENALTIES_WITHOUT_EXTRA) {
      resultString = m.home.results[4];
    } else {
      resultString = m.home.results[0];
    }      
  }
  return resultString;
}

export function getGuestResult(m) {
  let status_id = m.status_id;
  var resultString = "";

  if ((isPlaying(m) || isEnded(m)) && !isPostPoned(m) && !isCancelled(m)) {
    if (status_id === STATUS_PENALTIES || status_id === STATUS_PENALTIES_WITHOUT_EXTRA) {
      resultString = m.guest.results[4];
    } else {
      resultString = m.guest.results[0];
    }      
  }
  return resultString;
}


export function getHomeHTResult(m) {
  return m.home.results[1];
}
export function getGuestHTResult(m) {
  return m.guest.results[1];
}
export function getHomeETResult(m) {
  return m.home.results[3];
}
export function getGuestETResult(m) {
  return m.guest.results[3];
}

export const findMatch = (id, matches)=>{
  return matches.find(e => e.id === id);
}

export const addMatchByDate = (match, matches) => {
  let list = matches[0];

  if (isPlaying(match))         
    list = matches[0];          // live
  else if (isScheduled(match))  
    list = matches[1];          // scheduled
  else                  
    list= matches[2];           // ended
  
  for (var index = 0; index < list.length; index++) {
    if (compareMatch(match, list[index]) < 0)
      break;
  }
  list.splice(index, 0, match);

  return ;
}

export const rangeChanged = (old_range, new_range) => {
  return (old_range.day !== new_range.day || old_range.start !== new_range.start || old_range.end !== new_range.end) ? true : false;
}

export const getMergedData = (old_data, new_data, checkForChanges) =>{
  
  if (old_data == null || rangeChanged(old_data.range, new_data.range)){
    console.log("[RANGE] switching to new data " + (old_data ? "range_changed" : "no_storage"));
    return new_data;
  }
  
  if (new_data.status.update_competitions > old_data.status.update_competitions) {
    merge(old_data.competitions.list, new_data.competitions.list);
  }
  if (new_data.status.update_leagues > old_data.status.update_leagues) {
    merge(old_data.leagues.list, new_data.leagues.list);
  }
  if (new_data.status.update_countries > old_data.status.update_countries) {
    merge(old_data.countries.list, new_data.countries.list);
  }
  if (new_data.status.update_teams > old_data.status.update_teams) {
    merge(old_data.teams.list, new_data.teams.list);
  }

  var changes = 0;

  if (new_data.status.update_matches > old_data.status.update_matches) {
    let a = old_data.matches.list
    let b = new_data.matches.list
    var added = 0;
    var updated = 0;
    var removed = 0;
    b.forEach(element =>{
      const index = a.findIndex((e) => e.id === element.id);
      
      if (index === -1) {
          console.log("ADD new match " + element.id)
          a.push(element);
          added++;
      } else {
        //console.log("update match " + element.id)
        if (element.available === 0){
          a.splice(index, 1)
          removed++;
        }else{
          let match_changes = updateMatchData(element, a[index])
          if (checkForChanges){
            element.changes = 0;
            match_changes = 0;  
          }
          var event = []
          event[0] = -100

          if ((match_changes & F24TMatchUpdateStatus) === F24TMatchUpdateStatus || (match_changes & F24TMatchUpdateStatusPartial) === F24TMatchUpdateStatusPartial){
            event[0] = 3;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = 0      // -1 home, 1 guest
          }
          if ((match_changes & F24TMatchUpdateScoreH) === F24TMatchUpdateScoreH){
            event[0] = 0;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = -1      // -1 home, 1 guest
          }
          if ((match_changes & F24TMatchUpdateRollbackScoreH) === F24TMatchUpdateRollbackScoreH){
            event[0] = -2;      // 0 goal, 1 card, 2 ht, 3 ft, -1 rollback card, -2 rollback goal
            event[2] = -1      // -1 home, 1 guest
          }
          if ((match_changes & F24TMatchUpdateScoreG) === F24TMatchUpdateScoreG) {     // GUEST GOAL
            event[0] = 0;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = 1      // -1 home, 1 guest
          }  
          if ((match_changes & F24TMatchUpdateRollbackScoreG) === F24TMatchUpdateRollbackScoreG) {
            event[0] = -2;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = 1      // -1 home, 1 guest
          } 
          if ((match_changes & F24TMatchUpdateRedCardsH) === F24TMatchUpdateRedCardsH) {  // red card HOME
            event[0] = 1;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = -1      // -1 home, 1 guest            
          } 
          if ((match_changes & F24TMatchUpdateRollbackRedCardsH) === F24TMatchUpdateRollbackRedCardsH) {
            event[0] = -1;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = -1      // -1 home, 1 guest            
          } 
          if ((match_changes & F24TMatchUpdateRedCardsG) === F24TMatchUpdateRedCardsG) {  // red card GUEST
            event[0] = 1;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = 1      // -1 home, 1 guest            
          }
          if ((match_changes & F24TMatchUpdateRollbackRedCardsG) === F24TMatchUpdateRollbackRedCardsG) {
            event[0] = -1;      // 0 goal, 1 card, 2 ht, 3 ft
            event[2] = 1      // -1 home, 1 guest            
          }
          event[1] = getStatusLabel(element)
          event[3] =  element

          if (!old_data.last_matches_updated){
            console.log("LastUpdate create array" )
            old_data.last_matches_updated = []
          }
          if (event[0] !== -100){
            console.log("LastUpdate create push event " + event[0] + " " + event[1])
            old_data.last_matches_updated.splice(0, 0, event)
          }

          changes |= match_changes
          a[index] = element;
          updated++;
        }
      }

      old_data.matches.list = a;
    })
    console.log("UPDATING DATA new: " + added + " updated: " + updated + " removed: " + removed)
    console.log("UPDATING DATA new_array_len: " + a.length + " update_len: " + b.length)
  }

  old_data.status = new_data.status;
  old_data.range = new_data.range;
  old_data.changes = changes;
  //old_data.last_matches_updated = new_data.matches.list
  return old_data;
}

export const searchTeamName = (match, searchText) => {
  var teamname_h = match.teamHome.name.toLowerCase()
  var teamname_g = match.teamGuest.name.toLowerCase()
  //console.log("   match " + match.id + " : " + teamname_h + " - " + teamname_g)
  if (teamname_h.includes(searchText) || teamname_g.includes(searchText)){
    return true
  }
  return false
}

export const matchIsLive = (match) => {
  let timeto = match.start_date*1000 - Date.now()
  if (timeto < 600000){
    return true
  }
  return false
}


export const filterMatches = ( allMatches, searchText, isLive, favorites, filters ) =>{
  var filteredMatches = [[],[],[]]
  if (searchText === null && !isLive && favorites === null && !isListFiltered(filters))
    return allMatches
  var to = (!searchText && isLive) ? 2 : 3

  //console.log("filterMatch to " + to + " matches: " + allMatches.length + " filters " + filters);

  for (var i = 0; i < to; i++){
    var matches = []
    allMatches[i].forEach(match => {
      var l, src, fvr, flt = true
      src   = searchText === null || (searchText.length > 1 && searchTeamName(match, searchText))
      if (src){
        l   = (!isLive || matchIsLive(match))
        fvr = (favorites === null)  || (favorites && isMatchFavorite(favorites, match))
        flt = fvr && filters && !isCompetitionFiltered(filters, match.league.competition.id) 

        if ( l && fvr && flt && l)
          matches.push(match)  
      }
    })
    filteredMatches[i] = matches
  }

  return filteredMatches
}


export const filterCompetitions = ( allCompetitions, searchText, isLive, favorites, filters ) =>{
  var filteredComps = []

  if (searchText === null && !isLive && favorites === null && !isListFiltered(filters)){
    //console.log("FILTERING... return ALL")
    return allCompetitions
  }

  console.log("FILTERING... " + isLive)
  allCompetitions.forEach(competition => {
    var matches = filterMatches(competition.matches, searchText, isLive, favorites, filters)
    
    if (matches[0].length > 0 || matches[1].length > 0 || matches[2].length > 0){
      //console.log("\tfiltering ... pushing comp "+competition.id)
      
      filteredComps.push({ id:competition.id, matches:matches, competition: competition.competition})
    }
  })
  //console.log("FILTERED... " + stringify(filteredComps))
    
  return filteredComps
}

export const filterCompetitions_ = ( allCompetitions, searchText, isLive, favorites, filters ) =>{
  var filteredComps = []

  if (searchText === null && !isLive && favorites === null && filters === null){
    return allCompetitions
  }

  allCompetitions.forEach(competition => {
    var copied_competition = { id:competition.id, matches:[[],[],[]], competition: competition.competition};
    var to = (!searchText && isLive) ? 2 : 3
    for (var i = 0; i<to; i++){
      var matches = []
      
      competition.matches[i].forEach(match => {
        var l, src, fvr, flt
        src = (searchText && searchTeamName(match, searchText)) || !searchText
        
        l = !isLive || (isLive && matchIsLive(match)) ? true : false 
        fvr = (favorites !== null) || (favorites && isMatchFavorite(favorites, match))
        flt = (favorites === null) && filters && !isCompetitionFiltered(filters, competition.id) 
        if ( l && fvr && flt && l)
          matches.push(match)
          /*
        if ( ( searchText && searchTeamName(match, searchText) ) || 
             ( isLive && matchIsLive(match) ) || 
             ( favorites && isMatchFavorite(favorites, match)) ||
             ( favorites === null && filters && !isCompetitionFiltered(filters, competition.id)) ){
              matches.push(match)
        }*/
      })
      if (matches.length > 0){
        copied_competition.matches[i]=matches
      }  

    }
    if (copied_competition.matches[0].length > 0 || copied_competition.matches[1].length > 0 || copied_competition.matches[2].length > 0){
      filteredComps.push(copied_competition)
    }
  })
    
  return filteredComps
}