import React from 'react';
import "../css/layout.css"

///////////////////////////     COMPONENT

class TabsHeader extends React.Component {

    render(){

    const {tabs, subheader} = this.props

    var divclass = (subheader) ? "subHeader space-around" : "tabHeader space-around"
       
    return(
      <div className={divclass}>
            {tabs.map((t, index) => 
                <div key={index} className={t.selected} onClick={() => t.cb(t.params)}><p>{t.name}</p></div> 
              )}
      </div>      
    )
  }
}

export default TabsHeader