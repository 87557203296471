
import { Drivers, Storage } from '@ionic/storage';
import { setFilters } from '../state/app'

export const readFilters = async (context) => {  

  console.log("STORAGE reading..." + JSON.stringify(context.props.filters))
  if (context.props.filters){
    console.log("STORAGE reading return... not done")
    return context.props.filters
  }

  try {
    //const storage = new Storage();
    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });

    await storage.create();
  
    const filter_json = await storage.get('filters');
    console.log("STORAGE init filter: " + filter_json)
    var filters = {
        competitions: {
          list: [],
        },
    }

    if (filter_json){
      filters = JSON.parse(filter_json)
      if (filters.competitions === null)
        filters.competitions = {list:[]}
    }
    context.props.dispatch(setFilters(filters));   

  } catch (error) {
        console.log("STORAGE error: " + error)
  }

  console.log("STORAGE reading return... " + JSON.stringify(filters))
  return filters
}

export const getCompetitionsFilters = (filters) => {
  if (filters && filters.competitions){
    return filters.competitions.list
  }
  return null
}

export const isListFiltered = (filters) => {
  if (filters && filters.competitions && filters.competitions.list.length > 0){
    return true
  }

  return false
}


export const isCompetitionFiltered = (filters, id) => {

  if (filters && filters.competitions){
    const i = filters.competitions.list.findIndex((e) => e === id);
    if (i >= 0){
      console.log("COMP FILTER " + id + " TRUE")
      return true
    }
  }

  return false
}

export const addCompetitionToFilter = async (context, competition) => {
  var filters = context.props.filters
  
  console.log("[FIL][COMP] ADD TO STORAGE")
  
  try {
    if (filters){
      console.log("[FIL][COMP]  storage active")
      
      if (filters.competitions){
        const i = filters.competitions.list.findIndex((e) => e === competition.id);
        if (i >= 0){
          console.log("[FIL][COMP]  comp already in storage ")
          console.log("[FIL][COMP]  remove comp from storage: " + competition.id)

          filters.competitions.list.splice(i, 1);
        }else{
          filters.competitions.list.push(competition.id)
        }
      }else{
        filters.competitions = {list:[]}
        filters.competitions.list.push(competition.id)
      }
    }else{
      console.log("[FIL][COMP]  storage empty")
      
      filters = {
        competitions:{
          list: [],
        },
      }
      console.log("[FIL][COMP]  init: " + JSON.stringify(filters, null, 2))
      filters.competitions.list.push(competition.id);
    }
    console.log("[FIL][COMP]  add comp to storage: " + competition.id)

    //const storage = new Storage();
    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });

    await storage.create();
    context.props.dispatch(setFilters(filters)); 
    await storage.set('filters', JSON.stringify(filters));
    //this.setState({updated: this.state.updated++})
} catch (error) {
  console.log("[FIL] STORAGE error: " + error)
}
}

export const addCountryToFilter = async (context, country) => {
  var filters = context.props.filters
  var competitions = country.competitions
  
  console.log("[FIL][COMP] ADD TO STORAGE")
  
  try {
    if (!filters){
      filters = {
        competitions:{
          list: [],
        },
      }
    }
    competitions.map( competition => {
      console.log("[FIL][COMP]  storage active")
      
      const i = filters.competitions.list.findIndex((e) => e === competition.id);
      if (i < 0){
        filters.competitions.list.push(competition.id)
      }
    })

    
    //const storage = new Storage();
    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
    await storage.create();
    context.props.dispatch(setFilters(filters)); 
    await storage.set('filters', JSON.stringify(filters));
    //this.setState({updated: this.state.updated++})
} catch (error) {
  console.log("[FIL] STORAGE error: " + error)
}
}

export const removeCountryFromFilter = async (context, country) => {
  var filters = context.props.filters
  var competitions = country.competitions
  
  console.log("[FIL][COMP] ADD TO STORAGE")
  
  try {
    if (!filters){
      return
    }
    competitions.map( competition => {
      console.log("[FIL][COMP]  storage active")
      
      const i = filters.competitions.list.findIndex((e) => e === competition.id);
      if (i >= 0){
        filters.competitions.list.splice(i, 1);
      }
    })

    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
    //const storage = new Storage();
    await storage.create();
    context.props.dispatch(setFilters(filters)); 
    await storage.set('filters', JSON.stringify(filters));
    //this.setState({updated: this.state.updated++})
} catch (error) {
  console.log("[FIL] STORAGE error: " + error)
}
}