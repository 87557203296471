import React from 'react';
import { connect } from 'react-redux';
import MatchEvent from './MatchEvent';
import ico_match_event_card_red from "../../images/ico_nav_cards_on.png" 
import ico_match_event_goal     from "../../images/ico_nav_goals_on.png" 
import ico_match_event_status   from "../../images/ico_nav_time_on.png"
import icon_arrow from "../../images/icon_tick.png"
import Layout from '../layout';

class LastResults extends React.Component {

  constructor(props){
    super(props)

    this.state={
      goals: true,
      cards: true,
      status: true
    }
  }

  toggleGoals = () => {
    const { goals } = this.state;
    this.setState({goals: (goals) ? 0:1})
  }

  toggleCards = () => {
    const { cards } = this.state;
    this.setState({cards: (cards) ? 0:1})
  }

  toggleStatus = () => {
    const { status } = this.state;
    this.setState({status: (status) ? 0:1})
  }

  getLastResults(){
    const { data, update } = this.props

    return(
      <div className="content" id={update}>
        {(data.last_matches_updated.map( (match, index) => {
            if (((match[0] === 0 || match[0] === -2) && this.state.goals) ||
                ((match[0] === 1 || match[0] === -1) && this.state.cards) ||
                (match[0] === 3 && this.state.status))
              return (<div><MatchEvent key={match[3].id} match={match[3]} event={match}/></div>)
          }
        ))
      } 
      </div>
    )
  }

  render() {
    const { closeAction, context, data, update } = this.props

    var g_opacity = (this.state.goals) ? "" : "opacity"
    var c_opacity = (this.state.cards) ? "" : "opacity"
    var s_opacity = (this.state.status) ? "" : "opacity"
    
    var close = { 
      icon: icon_arrow,
      text: "live",
      action: closeAction,
      context: context
    }

    var buttons = []
    buttons.push(close);
    
    return (
      <Layout title="Latest results" buttons={buttons}>
      
      <div className="actionBox">
        <div className="actionBar">
          <img onClick={ () => this.toggleGoals() } className={"icoEvents " + g_opacity}  src={ico_match_event_goal} alt=""/>
          <img onClick={ () => this.toggleCards() } className={"icoEvents " + c_opacity} src={ico_match_event_card_red} alt=""/>
          <img onClick={ () => this.toggleStatus() } className={"icoEvents " + s_opacity} src={ico_match_event_status} alt=""/>
        </div>
        </div>
        {(data.last_matches_updated) ? this.getLastResults() : <div className="errorMessage"><p className="errorMessage">no events</p></div>}
      </Layout>
    )
  };

}

export default connect(state => ({
  update: state.app.update,
  data: state.app.data,
  
}), null)(LastResults)

