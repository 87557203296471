import React from "react"
//import { AsyncStorage } from 'AsyncStorage';
import { Drivers, Storage } from '@ionic/storage';
import {homeTeamName, guestTeamName, isScheduled} from "../../data/MatchData"
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "../../css/details.css"
import icon_under from "../../images/icon_arrow_under.png"
import icon_over from "../../images/icon_arrow_over.png" 
import icon_none from "../../images/icon_vote_none.png" 
import icon_tick from "../../images/icon_vote_tick.png" 
import { addPredictionUrl, doGet } from "../../data/Protocol";

class Predictions extends React.Component {
    
    constructor(props) {
        super(props);

        this.UserVoted(1, null)
        this.state = {
                active: 1,
                UserVoted: 0,                
            }
        }

        networkSuccess(url, resp) { 
    }

    networkError(url,status){
        console.log("NETWORK ERROR Predictions " + status)
    }

    Vote(vote){
        const {match} = this.props;
        const {active} = this.state;

        var param = "mw"
        if (active === 2)
            param = "muo"
        else if (active === 3)
            param = "mng"
        var url =  addPredictionUrl(match.id, param + "=" + vote) 

        doGet(url, this);

        this.storeVote(vote)
        this.setState({UserVoted: vote})
    }

    UserVoted = async (type) => {
        const {match} = this.props;
        
        const storage = new Storage({
            name: 'f24',
            driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
          });
        await storage.create();

        try {
            //const predictions_text = await AsyncStorage.getItem('predictions');
            const predictions_text = await storage.get('predictions');
            if (predictions_text) {
                var predictions = JSON.parse(predictions_text)

                var i = predictions.findIndex((e) => e.match === match.id && e.type === type)
                if (i >= 0){
                    this.setState({UserVoted: predictions[i].vote})
                }else{
                    this.setState({UserVoted: false})
                }
            }else
                this.setState({UserVoted: false})
        } catch (error) {
            console.log("STORAGE error: " + error)
            this.setState({UserVoted: false})
        }
        console.log("STORAGE return: " + storage)
    }

    storeVote = async (vote) => {
        const {match} = this.props;
        const {active} = this.state;

        const storage = new Storage({
            name: 'f24',
            driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
          });
        await storage.create();

        console.log("STORAGE set: " + storage)

        var votation = {
            "match": match.id,
            "start": match.start,
            "type": active, 
            "vote": vote
          }

        try {
            //const predictions_text = await AsyncStorage.getItem('predictions');
            const predictions_text = await storage.get('predictions');
            let predictions = []

            if (predictions_text) {
                predictions = JSON.parse(predictions_text)
            }
            
            const i = predictions.findIndex((e) => e.match === match.id && e.type === active);

            if (i >= 0)
                predictions[i] = votation;
            else
                predictions.push(votation);
    
            //await AsyncStorage.setItem('predictions', JSON.stringify(predictions));
            await storage.set('predictions', JSON.stringify(predictions));
          } catch (error) {
              console.log("[store] Error storing data " + error)
            // Error saving data
          }
    }


    setActive(next){
        const {active} = this.state;
        const {cb} = this.props;
        
        var next_state = active
        if (next){
            if (active === 3)
                next_state = 1;
            else
                next_state = active+1
        }else{
            if (active === 1)
                next_state = 3;
            else
                next_state = active-1
        }
        
        this.UserVoted(next_state, this.state.storage)
        cb(next_state)
        this.setState({
            active: next_state,
        })
      }

    getContentResult(){
        const {match} = this.props;
        const {active, UserVoted} = this.state;

        let list = match.predictions.list
        var elem 
        for (var index = 0; index < list.length; index++) {
            elem = list[index]
            if (elem.type_id === active){
                break
            }
        }
 
        let home  = homeTeamName(match)
        let guest = guestTeamName(match)
        
        let dcolor = `rgba(140, 140, 140)`
        let ocolor = `rgba(237, 103, 15)`
        //let ocolor = `rgba(255, 140, 140)`
        var predictions_elems
        if (active === 1){
            let tot = (elem.h + elem.d + elem.g)
            let hperc = Math.round(elem.h / tot * 100)
            let dperc = Math.round(elem.d / tot * 100)
            let gperc = Math.round(elem.g / tot * 100)
            if (!hperc) hperc = 0;
            if (!dperc) dperc = 0;
            if (!gperc) gperc = 0;

            predictions_elems = [
                {
                  "name": home,
                  "votes": elem.h,
                  "perc": hperc, 
                  "color": UserVoted === "h" ? ocolor : dcolor
                },
                {
                    "name": "Draw",
                    "votes": elem.d,
                    "perc": dperc, 
                    "color": UserVoted === "d" ? ocolor : dcolor
                  },
                {
                    "name": guest,
                    "votes": elem.g,
                    "perc": gperc, 
                    "color": UserVoted === "g" ? ocolor : dcolor
                  }
              ]
        }
        if (active === 2){
            let uperc = Math.round(elem.u / (elem.u + elem.o)* 100)
            let operc = Math.round(elem.o / (elem.u + elem.o)* 100)
            if (!uperc) uperc = 0;
            if (!operc) operc = 0;

            predictions_elems = [
                {
                  "name": 'UNDER 2.5',
                  "votes": elem.u,
                  "perc": uperc, 
                  "color": UserVoted === "u" ? ocolor : dcolor
                },
                {
                    "name": 'OVER 2.5',
                    "votes": elem.o,
                    "perc": operc,
                    "color": UserVoted === "o" ? ocolor : dcolor
                }
              ]
        }
        if (active === 3){
            let tot = (elem.h + elem.n + elem.g)
            let hperc = Math.round(elem.h / tot* 100)
            let nperc = Math.round(elem.n / tot* 100)
            let gperc = Math.round(elem.g / tot* 100)
            if (!hperc) hperc = 0;
            if (!nperc) nperc = 0;
            if (!gperc) gperc = 0;

            predictions_elems = [
                {
                  "name": home,
                  "votes": elem.h,
                  "perc": hperc, 
                  "color": UserVoted === "h" ? ocolor : dcolor
                },
                {
                    "name": "none",
                    "votes": elem.n,
                    "perc": nperc, 
                    "color": UserVoted === "n" ? ocolor : dcolor
                },
                {
                    "name": guest,
                    "votes": elem.g,
                    "perc": gperc, 
                    "color": UserVoted === "g" ? ocolor : dcolor
                }
              ]
        }

        return(
            <div key={active} className="cirle-info-bar">
                {predictions_elems.map((e, index) =>
                    <div key={index}>
                        <div className="circle">
                            <CircularProgressbar value={e.perc} text={`${e.perc}%`} 
                            styles={buildStyles({
                                
                                pathTransitionDuration: 0.5,                             
                                pathColor: e.color,
                                textColor: '#000',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                              })}
                            />
                        </div>
                        <div className="club-name">
                            <div><strong>{e.name}</strong></div>
                            <div>
                            <p className="votes">{e.votes} voti</p>
                            </div>
                        </div>
                    </div>
        
                    )}
            </div>
        )
    }

    getContentVotations(){
        const {match} = this.props;
        const {active} = this.state;

        let list = match.predictions.list
        var elem 
        for (var index = 0; index < list.length; index++) {
            elem = list[index]
            if (elem.type_id === active){
                break
            }
        }
 
        let home  = homeTeamName(match)
        let guest = guestTeamName(match)

        var predictions_elems
        if (active === 1){
            predictions_elems = [
                {
                  "name": home,
                  "text": "1", 
                  "val": "h"
                },
                {
                    "name": "Draw",
                    "text": "X", 
                    "val": "d"
                },
                {
                    "name": guest,
                    "text": "2", 
                    "val": "g"
                }
              ]        
        }
        if (active === 2){
            predictions_elems = [
                {
                  "name": "UNDER 2.5",
                  "text": "1", 
                  "val": "u",
                  icon: icon_under
                },
                {
                    "name": "OVER 2.5",
                    "text": "2", 
                    "val": "o",
                    icon: icon_over
                }
              ]        
        }
        if (active === 3){
            predictions_elems = [
                {
                  "name": home,
                  "text": "1",
                  "val": "h",
                  icon: icon_tick
                },
                {
                    "name": "None",
                    "text": "X", 
                    "val": "n",
                    icon: icon_none
                },
                {
                    "name": guest,
                    "text": "2", 
                    "val": "g",
                    icon: icon_tick
                }
              ]        
        }

        if (active === 2 || active === 3)
            return(
            <div key={active} className="cirle-info-bar">
                {predictions_elems.map((e, index) =>
                        <div key={index} onClick={() => this.Vote(e.val)}>
                        <div className="circle">

                            <CircularProgressbarWithChildren strokeWidth="1" value="100" 
                            styles={buildStyles({
                                pathTransitionDuration: 0.5,                             
                                pathColor: `rgba(140, 140, 140)`,
                                textColor: '#000',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                                width: '100%',
                                
                                
                              })}
                            >
                            <img style={{ width: 40, marginTop: -5 }} src={e.icon} alt="doge" />                                
                            </CircularProgressbarWithChildren>
                            </div>
                            <div className="club-name">
                            <div>{e.name}</div>
                        </div>
                    </div>
        
                    )}
            </div>
            )

            return(
                <div key={active} className="cirle-info-bar">
                    {predictions_elems.map((e, index) =>
                            <div key={index} onClick={() => this.Vote(e.val)}>
                            <div className="circle">
    
                                <CircularProgressbar strokeWidth="1" value="100" text={`${e.text}`} 
                                styles={buildStyles({
                                    
                                    pathTransitionDuration: 0.5,                             
                                    pathColor: `rgba(140, 140, 140)`,
                                    textColor: '#000',
                                    trailColor: '#d6d6d6',
                                    backgroundColor: '#3e98c7',
                                  })}
                                />
                                </div>
                                <div className="club-name">
                                <div>{e.name}</div>
                            </div>
                        </div>
            
                        )}
                </div>
            )
    
    }
    
    render (){
        const {match} = this.props;
        const {active, UserVoted} = this.state;

        var dot1 = "dot"
        var dot2 = "dot"
        var dot3 = "dot"
        
        var content = ""
        var title   = "-"

        if (UserVoted === -1)
            return (<div>loading...</div>)

        //if (!(isScheduled(match) && !UserVoted)){
            content = this.getContentResult()
            if (active === 1){
                title = "Match winner"   
            }
            if (active === 2){
                title = "Under/Over 2.5"   
            }
            if (active === 3){
                title = "Next goal"   
            }    
        /*}else{
            content = this.getContentVotations()
            if (active === 1){
                title = "Who will win"   
            }
            if (active === 2){
                title = "Under/Over 2.5"   
            }
            if (active === 3){
                title = "Next goal" 
            }    
        }*/


        if (active === 1){
            dot1 = "dot active"
        }
        if (active === 2){
            dot2 = "dot active"
        }
        if (active === 3){
            dot3 = "dot active"
        }

        if (match){
            if (match.predictions && match.predictions.list && match.predictions.list.length > 0){

                return (
                    <div key={active} className="predictions-wrapper">
                    <div className="page-title">{title}</div>
                    {content}
                    <div className="dots">
                        <div className="tab-prev" onClick={() => this.setActive(false)}>
                            <svg  xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd" />
                            </svg>
                        </div>
                        <span className={dot1}></span>
                        <span className={dot2}></span>
                        <span className={dot3}></span>
                        <div className="tab-next" onClick={() => this.setActive(true)}>
                            <svg  xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z">
                            </path>
                            </svg>
                        </div>
                    </div>
                </div>
                )
            }
        }
        return("")
    }
}

export default Predictions;