import React from 'react';
import { connect } from 'react-redux';
import ScriptTag from 'react-script-tag';

import {
  IonTabButton,
} from '@ionic/react';

import { Helmet } from "react-helmet"

const addScript = ({ src, id, onLoad }) => {
  const existing = document.getElementById(id);
  if (existing) {
    //console.log("E2tag REMOVED");
    return existing;
    existing.remove();
    var script_div = document.getElementById('gE2Tag');
    if (script_div)
    script_div.innerHTML = "";
    
  } else {
    console.log("E2tag CREATED");
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => {
      if (onLoad) {
        onLoad();
      }
    };
    document.body.appendChild(script);
    return script;
  }
};


class ExternalOdds extends React.Component {

  
  componentDidMount() {
    console.log("COMPONENT EXTODD DID MOIUNT");
    this.loadScript();
    
  }

  componentDidUpdate() {
    console.log("COMPONENT EXTODD DID MOIUNT");
    this.loadScript();
  }

  componentWillUnmount(){
    console.log("EXTERNA UNMOUNT");
  }

  loadScript(){
    
    addScript({
      //src: `https://jsfiddle.net/t619hLkv/`, // `https://cdn77.v1.oddsserve.com/9b12c500.js`,
      src: `https://cdn77.v1.oddsserve.com/9b12c500.js`,
      id: "e2tag-script",
      onLoad: () => {
        console.log("E2tag LOADED");
        var script = document.getElementById("e2tag-script");
        script.remove();
      },
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    }

    //this.interval = setInterval(this.updateTimer, 10000)
  }

  updateTimer = async () => {
    var show = this.state.show + 1;
    this.setState({
      show: show,
    })
  }

  handleScriptInject( scriptTags ) {   
    /*
      var p = ReactDOM.findDOMNode(this.refs.gE2Tag);
      if (p){      
        console.log("DAN div " + p + " len: " + p.childNodes.length);
        for (var i=0; i<p.childNodes.length; i++){
          console.log("DAN " + p.childNodes[i]);
        }
        var oo = p.getElementsByClassName('oddsServe-wrapper');
        console.log("DAN OK  " + oo);
      }
      */
  }

  render() {
    console.log("RENDER EXTERNAL ODDS ");    
    /*
    console.log("RENDER EXTERNAL ODDS " + this.state.show);    
    if (this.state.show == 1)
      return null;

      this.setState({show:1});*/

    //var resource_content = '<ins className="eccc554d" data-key="9e78f3daff11aab71b4f32e4af4d32e2"></ins><script async src="https://cdn77.v1.oddsserve.com/9b12c500.js"></script>';

    //return <div dangerouslySetInnerHTML={{ __html: resource_content }}></div>
    // <ScriptTag isHydrating={true} type="text/javascript" src="https://cdn77.v1.oddsserve.com/9b12c500.js" />
    //<ins className="eccc554d" data-key="9e78f3daff11aab71b4f32e4af4d32e2"></ins>
    return <div id="gE2Tag" style={{marginBottom: '10px'}}>
        <ins className="eccc554d" data-key="28e767035a0ec4d6231022393e40eaaa"></ins>
    </div>
  }
}

export default connect(state => ({update: state.app.update,}), null)(ExternalOdds)
//export default ExternalOdds
