import React from 'react';
import { completeCompetition, completeLeague } from "../Competitions/Competition";
import {doGet, matchesDayUrl} from '../../data/Protocol'
import { completeMatch, addMatchByDate } from "../../data/MatchData";
import { orderCompetitions } from "../../data/CompetitionData";
import { completeTeam } from "../Teams/Team";
import "../../css/layout.css"
import { connect } from 'react-redux';
import MatchesContainer from './MatchesContainer';

class MatchesDay extends React.Component {
  
  constructor(props) {
    super(props);
    const {day, update} = this.props
    var u = (!update) ? 0 : update
      
    console.log("DETAILS create " + day + " u: " + u)
    this.state = {
      showLoading: true,
      day: day,
      update: u
    }
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }
  
  networkSuccess(url, resp) { 
   
    let data_merged = resp.result;

    let competitions_server = data_merged.competitions.list;
    let leagues = data_merged.leagues.list;
    let countries = data_merged.countries.list;
    let teams = data_merged.teams.list;

    competitions_server.forEach(c => {
        completeCompetition(c, countries);
    });

    leagues.forEach(l => {
        completeLeague(l, competitions_server);
    });

    teams.forEach(t => {
        completeTeam(t, countries);
    });

    let orderedCompetitions = orderCompetitions(competitions_server);
    
    var matchesKickOff = [[],[],[]];    // 0: live, 1: next, 2: ended

    data_merged.matches.list.forEach(m => {
        try{
          completeMatch(m, data_merged);
          if (m.available === 1){
            let c = orderedCompetitions.find(e => e.id === m.league.competition_id);
            addMatchByDate(m, c.matches);
            addMatchByDate(m, matchesKickOff);
          }
        }catch(err){
          console.log("ADDING MATCH ERROR")
        }
      
    });

    this.setState ( {
      update: 1,
      day: this.props.day,
      data: data_merged,
      orderedCompetitionsIDWithMatches: orderedCompetitions,
      matchesKickOff: matchesKickOff,
    })  
  }

  networkError(url,status){

  }

  getMatches = async () => {
    const { day } = this.props;
    doGet(matchesDayUrl(day), this)
  }

  componentDidMount () {
    const { day } = this.props;
    console.log("MATCH_DAY_"+day+" componentDidMount")
    this.getMatches();
  }

  componentDidUpdate () {
    const { day } = this.props;
    const { update } = this.state;

    console.log("MATCH_DAY_"+day+" componentDidUpdate: " + update)
    if (update === 0){
      this.getMatches();
    }
  }

  static getDerivedStateFromProps(props, state){    
    if ( props.day === state.day ){
      return null
    }

    return {
      day: props.day,
      update: 0
    }
  }

  render() {
    const { 
      update,
      update_favorites,
      update_filters,
      orderedCompetitionsIDWithMatches,
      matchesKickOff,
    } = this.state;
    const {order} = this.props
    // data not ready

    console.log("MATCHES_DAY .......... " + this.props.day);

    return <MatchesContainer  key={update_favorites + update_filters}
                              competitions={orderedCompetitionsIDWithMatches} matches={matchesKickOff}
                              order={order} live={false} update={update}
                              search= {null}
                              favorites={this.props.showFavorites ? this.props.favorites : null}
                              filter={this.props.filters} day={this.props.day}></MatchesContainer>
  }
} // class  
          
export default connect(state => ({
  update_favorites: state.app.update_favorites,
  favorites: state.app.favorites,
  update_fiters: state.app.update_filters,
  filters: state.app.filters,
}), null)(MatchesDay)


