import React from 'react';
import "../../css/layout.css"
import Layout from '../layout'
import TabsHeader from '../TabsHeader';
import Competitions from './Competitions';
import Teams from './Teams';
import { readFavorites } from '../../data/Favorites';
import { connect } from 'react-redux';

///////////////////////////     COMPONENT

class ExploreList extends React.Component {
  constructor(props) {
    super(props);
      
    this.state = {
      tab: 0,
    }

    readFavorites(this)
  }

  setTab(param){
    param[0].setState({tab: param[1]})
  }

  render() {

    var tab1 ={ 
      name: "Competitions",
      cb: this.setTab,
      params: [this, 0],
      selected: (this.state.tab === 0) ? "selected" : ""
    }

    var tab2 ={ 
      name: "Teams",
      cb: this.setTab,
      params: [this, 1],
      selected: (this.state.tab === 0) ? "" : "selected"
    }

    var tabs = []
    tabs.push(tab1);
    tabs.push(tab2);
    var page_name = "https://stat-piwik.futbol24.com/matomo.php?idsite=8&amp;rec=1&amp;action_name=page_explore";
    
    return (
        <Layout title="Endirect24" description="Endirect24">
          <TabsHeader tabs={tabs}/>
          {this.state.tab === 0 ? <Competitions/> : <Teams/>}
          <img src={page_name} alt="" />
        </Layout>
      );

  }
} // class


export default connect(state => ({
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites
}), null)(ExploreList)

//export default ExploreList

