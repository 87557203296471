import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
//import MatchDetails from '../components/MatchDetails/MatchDetails__';
import MatchDetails from '../components/MatchDetails/MatchDetails';

interface MatchDetailsPageProps extends RouteComponentProps<{
  id: string;
}> {}


const MatchDetailsPage: React.FC<MatchDetailsPageProps> = ({match}) => {
  return (<MatchDetails id={match.params.id}></MatchDetails>)
};


export default MatchDetailsPage;
