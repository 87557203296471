import React from "react"
import { Link } from 'react-router-dom';
import "../../css/details.css"
import Advertising from "../Advertising/Advertising"
import BoxScore from "./BoxScore"
import Predictions from "./Predictions"
import MatchEvents from "./Events"
import H2H from "./H2Hs";
import Statistics from "./Statistics"

import {doGet, matchDetailsUrl} from '../../data/Protocol'
import { completeMatch, isPlaying, isScheduled } from "../../data/MatchData"
import { completeCompetition, completeLeague } from "../Competitions/Competition";
import { completeTeam } from "../Teams/Team";
import { IonContent, IonPage } from "@ionic/react";
import F24Header from "../F24Header";
import {homeTeamName, guestTeamName} from "../../data/MatchData"

//import { Admob, AdmobOptions } from '@awesome-cordova-plugins/admob';
import nextArrow from "../../images/navigationArrowRight.png"


class MatchDetails extends React.Component {


  constructor(props) {
    super(props);

    console.log("DETAILS contruct")

    const {id} = this.props;
    
    this.state = {
      matchID: id,
      updated: 0,
      predictionsTab: 1
    }
  }
  
  networkError(url, status){

  }

  networkSuccess(url, data_updated){
    const { data } = this.state;    
    if(data_updated === null)
      return
      
    if (data){
      if (!data_updated.result.match.statistics)
        data_updated.result.match.statistics = data.match.statistics
      if (!data_updated.result.match.predictions)
        data_updated.result.match.predictions = data.match.predictions
    }
    clearInterval(this.interval);
    if (isPlaying(data_updated.result.match)){
       this.interval = setInterval(this.getMatchDetails, 5000)
    }else{
      this.interval = setInterval(this.getMatchDetails, 20000)
    }

    data_updated.result.competitions.list.forEach(c => {
      completeCompetition(c, data_updated.result.countries.list);
    });

    data_updated.result.leagues.list.forEach(l => {
        completeLeague(l, data_updated.result.competitions.list);
    });

    data_updated.result.teams.list.forEach(t => {
        completeTeam(t, data_updated.result.countries.list);
    });

    completeMatch(data_updated.result.match, data_updated.result)

    this.setState ( {
        data: data_updated.result,
        updated: data_updated.result.match.updated
    })
  }

  getMatchDetails = async () => {
    const { id } = this.props;
    const { data } = this.state;
    var url
    if (data){
      if (isScheduled(data.match))
        url = matchDetailsUrl(id, "actions:predictions")
      else
        url = matchDetailsUrl(id, "actions")
    }else
      url = matchDetailsUrl(id, "actions:predictions:statistics")
    doGet(url, this)  
  }

  componentDidMount () {
    console.log("DETAILS mount")
    const {matchID} = this.state;
    
    this.getMatchDetails(matchID);
    
  }

  componentWillUnmount() {
    console.log("DETAILS unmount")
    if (this.interval)
      clearInterval(this.interval);
    if (this.request && this.request.abort) {
      this.request.abort();
    }
  }
  
  PredictionsCB = (type) => {
    console.log("PREDICTIONS TAB CHANGED " + type)
    this.setState ( {
        predictionsTab: type
    })   
  }

  getTtile(match){
    if (isPlaying(match))
      return 'Live'
    if (isScheduled(match))
      return 'Prematch'
    
    return 'Final'
  }

  renderContents(){
    const { data } = this.state;


    if (data){
      var page_name = "https://stat-piwik.futbol24.com/matomo.php?idsite=8&amp;rec=1&amp;action_name=page_match/"+homeTeamName(data.match)+" - "+guestTeamName(data.match);
      var table_url = "/competitionTable/" + data.match.league.competition.id ;
      return (
        <div>
        <div style={{backgroundColor: "#fff", position: 'sticky', top: '0', width: '100%', zIndex: '100'}}> 
          <Link to={table_url}>
            <div className="top-menu content">
              <h1>
                <img width="20px" src={data.match.league.competition.country.flag_url_medium} alt=""></img>
                {data.match.league.name}          
              </h1>

              <div className="menu-next-button">
                <img  width="7" src={nextArrow} alt=""></img>

              </div> 
            </div>
          </Link>
          </div>
          <div style={{margin:' 0 5px 0 5px'}}>
            <div className="live-match">
            <BoxScore match={data.match}/>
            </div>
            <Advertising tag="odds" match={data.match} tab={this.state.predictionsTab} />
            <MatchEvents match={data.match}/>
            <Predictions match={data.match} cb={this.PredictionsCB}/>
            <Statistics match={data.match}/>
            <H2H match={data.match}/>
          </div>
          <img src={page_name} alt="" style={{border:0}} />
        </div>
      )
    }
    return (<div>loading</div>);
  }


  render (){
    const { data } = this.state;

    return (
      <IonPage>
      <F24Header title={(data) ? this.getTtile(data.match) : 'loading...'}></F24Header>
      <IonContent fullscreen>
        {this.renderContents()}
      </IonContent>
      </IonPage>  
    )
    
  }
}

//export default connect(state => ({update: state.app.update,}), null)(MatchDetails)
export default MatchDetails;