import React from "react"
import EventCell from "./EventCell"

class MatchEvents extends React.Component {

    render (){
        const {match} = this.props;
        if (match){
            if (match.actions && match.actions.list){
                var actions = match.actions.list
                return (
                <div className="eventi-wrapper">
                    <div className="page-title">Eventi</div>
                    <div style = {{background: `white`}}>
                        {actions.map(a=><EventCell key={a.id} action={a}/>)}
                    </div>
                </div>
                )
            }
        }

        return("")
    }
}

export default MatchEvents;