import { App } from '@capacitor/core';

export const PROTOCOL = "https://"
//const HOST = "api.futbol24.gluak.com/v2"
//export const HOST = "api.futbol24.gluak.com/v2"
//export const HOST = "ls.gluak.com/api/v2"
export const HOST = "m.endirect24.com/api/v2"

const AD_PROTOCOL = "https://"
const AD_HOST = "ad.gluak.com"

//const AD_PROTOCOL = "http://"
//const AD_HOST = "staging.ad.gluak.com"
var appForeground = true

App.addListener('appStateChange', ({ isActive }) => {
  //console.log('SW App state changed. Is active?', isActive);  
  appForeground = isActive
});

export const getAdCampaign = async (component) => {
  var url = AD_PROTOCOL + AD_HOST + "/campaign/665322a7625fd80a0c379d8810deb9ca-futbol24-mobile"
  console.log("[PROTO] [GET] " + url);
  var xhr = new XMLHttpRequest()
  
  xhr.open("GET", url, true)
  xhr.withCredentials = true;
  xhr.send();
  xhr.onerror = function(e){
    if (component.networkError)
    component.networkError(url, xhr.status)
  }
  xhr.onload = function(e){
    if (xhr.readyState === 4){
      var resp
      if (xhr.status === 200 || xhr.status === 204){
        resp = JSON.parse(xhr.response); 
        //console.log("[PROTO] [GET] '200' " + xhr.response)
        component.networkSuccess(url, resp);
      } else if (xhr.status === 403){
        resp = JSON.parse(xhr.response); 
        console.log("[PROTO] [GET] 'Forbidden'")
        component.networkError(url, xhr.status);
      } else {
        if (component.networkError)
          component.networkError(url, xhr.status)
      }
    }
  }
}


export const start = (return_url, component) => {   
  var url = PROTOCOL + HOST + "/start"
  
  console.log("[PROTO] [GET] " + url);
  var xhr = new XMLHttpRequest()
  
  xhr.open("GET", url, true)
  xhr.withCredentials = true;
  xhr.send();
  xhr.onerror = function(e){
    if (component.networkError)
      component.networkError(return_url, xhr.status)
  }
  xhr.onload = function(e){
    if (xhr.readyState === 4){
      if (xhr.status === 200){
        doGet(return_url, component)
      } else {
        if (component.networkError)
          component.networkError(return_url, xhr.status)
      }
    }
  }
}

export const matchOddsUrl = (id, publisher) => {   
  var url = PROTOCOL + HOST + "/match/" + id + "/multiodds/" + publisher + "?type=details"

  return url
}

export const addPredictionUrl = (id, qs) => {   
  var url = PROTOCOL + HOST + "/match/"+ id + "/predictions/add?" + qs
  return url
}

export const matchesUrl = (day, update) => {   
  var url = PROTOCOL + HOST + "/matches/"
  if (update === 0) 
    url = url + 'day'
  else
    url = url + "update/" + update

  return url
}

export const matchesDayUrl = (day) => {   
  var url = PROTOCOL + HOST + "/matches/day/" + day

  return url
}

export const matchDetailsUrl = (id, fields) => {   
  var url = PROTOCOL + HOST + "/match/" + id + (fields ? "?fields=" + fields : "") 

  return url
}

export const competitionTableUrl = (id) => {
  var url = PROTOCOL + HOST + "/competition/" + id + "/standings" 

  return url
}

export const competitionsUrl = () => {   
  var url = PROTOCOL + HOST + "/competitions"

  return url
}

export const teamsUrl = () => {   
  var url = PROTOCOL + HOST + "/teams"

  return url
}

export const competitionMatchesUrl = (id) => {   
  var url = PROTOCOL + HOST + "/competition/" + id + "/fixtures"

  return url
}

export const teamUrl = (id) => {   
  var url = PROTOCOL + HOST + "/team/" + id + "/matches"

  return url
}

export const doGet = async (url, component, req ) => {
  if (!appForeground){
    component.networkSuccess(url, null)
    return;
  }

  console.log("[PROTO] [GET] " + url);
  var xhr = new XMLHttpRequest()
  
  xhr.open("GET", url, true)
  xhr.withCredentials = true;
  
  xhr.send();
  xhr.onerror = function(e){
    if (component.networkError)
    component.networkError(url, xhr.status)
  }
  xhr.onload = function(e){
    if (xhr.readyState === 4){
      var resp
      if (xhr.status === 200 || xhr.status === 204){
        if (xhr.status !== 204){
          resp = JSON.parse(xhr.response); 

             // Get the raw header string
          const headers = xhr.getAllResponseHeaders();

          // Convert the header string into an array
          // of individual headers
          const arr = headers.trim().split(/[\r\n]+/);

          // Create a map of header names to values
          const headerMap = {};
          arr.forEach((line) => {
            const parts = line.split(': ');
            const header = parts.shift();
            const value = parts.join(': ');
            headerMap[header] = value;
          });
          console.log("RESP PROTO request num " + req)
          headerMap['url-req'] = req

          component.networkSuccess(url, resp, headerMap);
          //console.log("[PROTO] [GET] '200' " + xhr.response)
        }else{
          component.networkSuccess(url, "");
        }
      } else if (xhr.status === 403){
        resp = JSON.parse(xhr.response); 
        console.log("[PROTO] [GET] 'Forbidden'")
        component.networkError(url, xhr.status);
      } else {
        if (component.networkError)
          component.networkError(url, xhr.status)
      }
    }
  }
}
