

export const COLORS = {
     black : '#000000',
     white : '#f6f6f4',
     whiteTwo : '#ffffff',
     CatSkillWhite : '#e1e3de',
     LightGrey : '#ebede8',
     lightGreyTwo : '#f1f3ee',
     greyHeader : '#dfe1dc',
     matches_row_team_name : '#000000',
     greentab : '#16805e',
     tabColor : '#F8FAF5',
     warmGrey : '#8c8c8c',
     warmGreyTwo : '#797979',
     greyishDfl : '#BDC2B0',
     greyish : '#80BDC2B0',
     greyishTwo : '#a8a9a3',
     lightGreyFour : '#e3e6e0',
     lightGrey : '#F5F6F3',
     lightGreyFive : '#DEE0D7',
     brownishGrey : '#6c6c6c',
     mediumGrey : '#61635a',
     mediumGreyTwo : '#7c7f75',
     mediumGreyFour : '#86877f',
     mediumGreyFive : '#696c62',
     comps_header : '#D1D4C7',
     charcoal : '#4B4D46',
     predictionborder : '#e6e8e1',
     tuatara : '#464741',
     grey : '#808080',
    orange : '#ed670f',
    orangeAlpha : '#33ed670f',
    brightOrange : '#ff6600',
    rustOrange : '#c2540c',
    veryPaleGreen : '#edfbdf',
    blueGreen : '#16805e',
    lawnGreen : '#5dad0d',
    springGreen : '#b1f66a',
    springGreenTwo : '#b0f66a',
    jungleGreen : '#0d8a2c',
    lightLightGreen : '#d6ffac',
    mossGreen : '#628c32',
    danGreen : '#147556',
    lightGreyGreen : '#DFEBD3',
    lightGreyThree : '#F3F4F5',
    darkGrassGreen : '#448700',
    scarlet : '#d0021b',
    mediumPink : '#ed5a6c',


}