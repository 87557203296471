import React from 'react';
import { Link } from 'react-router-dom';
import {getResult, getFHResult, F24TMatchUpdateScores, F24TMatchUpdateScoreH, F24TMatchUpdateScoreG, 
  F24TMatchUpdateRedCardsH, F24TMatchUpdateRollbackRedCardsG, F24TMatchUpdateRedCardsG,
   F24TMatchUpdateRollbackScoreG, F24TMatchUpdateRollbackScoreH, F24TMatchUpdateRollbackRedCardsH, getStartHour } from '../../data/MatchData';
import { homeTeamName, guestTeamName } from "../../data/MatchData";
import { connect } from 'react-redux';
import ico_match_event_goal from "../../images/ico_match_event_goal.png" 
import ico_match_event_goal_canceled from "../../images/ico_match_event_goal_cancel.png" 
import ico_match_event_card_red from "../../images/ico_match_event_card_red.png" 
import { IonItemDivider } from '@ionic/react';

class MatchLight extends React.Component {

getResultBox(result){
  const { match } = this.props;
  let r = "-"
  if (result !== "")
    r = getResult(match)
    return(
      <div>
      <p className='scores'>{r}</p><p className='subscores'>{getFHResult(match)}</p>
      </div>
    )
  if (r === "0-0" || r === "-")
    return(
      <p className="scores"><span style={{color: '#000'}}>{r}</span> {getFHResult(match)}</p>
    )
  else
    return(
      <p className="scores"><span>{r}</span> {getFHResult(match)}</p>
    )
}


render() {
    const { match, i, } = this.props;

    var cell1_class = "cell-name"
    var cell2_class = "cell-name"
    var team1_class = "team-name1"
    var team1_hl_class = ""
    var team2_class = "team-name2"
    var team2_hl_class = ""

    if (match.changes & (F24TMatchUpdateScoreH | F24TMatchUpdateRollbackScoreH | F24TMatchUpdateRedCardsH | F24TMatchUpdateRollbackRedCardsH)){
      //console.log("MATCH_UPDATE score h " + homeTeamName(match))
      team1_hl_class = "highlighted-team"
      cell1_class = cell1_class + " highlighted"
    }
    if (match.changes & (F24TMatchUpdateScoreG | F24TMatchUpdateRollbackScoreG | F24TMatchUpdateRedCardsG | F24TMatchUpdateRollbackRedCardsG)){
      //console.log("MATCH_UPDATE score g " + guestTeamName(match))
      team2_hl_class = "highlighted-team"
      cell2_class = cell2_class + " highlighted"
    }

    var details_url = "/match/" + match.id ;
    //var row_class = (i % 2) ? "row bg-bold" : "row"
    var row_class = "row bg-bold"
    var result = getResult(match)


    var end_icon = ""

    if (match.changes & F24TMatchUpdateScores){
      end_icon = <span className="soccer-ball"><img src={ico_match_event_goal} alt=""/></span>
    }
    if (match.changes & (F24TMatchUpdateRollbackScoreG | F24TMatchUpdateRollbackScoreH)){
      end_icon = <span className="soccer-ball"><img src={ico_match_event_goal_canceled} alt=""/></span>
    }
    if (match.changes & ( F24TMatchUpdateRedCardsH | F24TMatchUpdateRedCardsG )){
      end_icon = <span className="soccer-ball"><img src={ico_match_event_card_red} alt=""/></span>
    }

    var epoch = new Date(match.start_date * 1000);
    var offset = epoch.getTimezoneOffset() * 60;
    var start = new Date((match.start_date-offset) * 1000);;
    start = start.toISOString().slice(0, 10);
    
    //<div style={{fontSize:'11px', color:'grey'}}>{start} - {match.league.name}</div>}
    return (
      <div>
      {this.props.extended && <div style={{fontSize:'11px', color:'grey'}}>{start} - {match.league.competition.name}</div>}
      <div className={row_class}>  
        <div className="row_left">
        <Link to={details_url}>
        <p className="number">{getStartHour(match)}</p>
          <div className={cell1_class}>
          <p className={team1_class}>
            <span className={team1_hl_class}>{homeTeamName(match)}</span>
          </p>
          </div>
          <div className='resultBox'>
          {this.getResultBox(result)}
          </div>
          <div className={cell2_class}>
            <div className={team2_class}>
                <span className={team2_hl_class}>{guestTeamName(match)}</span>
            </div>
          </div>
          </Link>
        </div>
        <div>
        {end_icon}
        </div>
      </div>
    </div>      
    )
    return (
      <div>
        {this.props.extended && <h1>{start} - {match.league.competition.name}</h1> }
        <div className={row_class}>  
          <div className="row_left">
          <Link to={details_url}>
            <p className="number">{getStartHour(match)}</p>
            <div className={cell1_class}>
            <p className={team1_class}>
              <span className={team1_hl_class}>{homeTeamName(match)}</span>
            </p>
            </div>
            {this.getResultBox(result)}
            <div className={cell2_class}>
              <div className={team2_class}>
                  <span className={team2_hl_class}>{guestTeamName(match)}</span>
              </div>
            </div>

            </Link>
          </div>
          {end_icon}
        </div>
        
      </div>
      
    )
  }
}

export default connect(state => ({}), null)(MatchLight)

//export default Match;
