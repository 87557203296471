import { IonLoading } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import MatchLight from '../Matches/MatchLight';


export const getTeamCountryFlag = (team) => {
  if (team && team.country){
    return team.country.flag_url_medium
  }

  return ""
}

export const getTeamCountryName = (team) => {
  if (team && team.country){
    return team.country.name
  }

  return ""
}
export const getTeamFlag = (team) => {
  if (team){
    return team.logo_url_low
  }

  return ""
}

export const completeTeam = (t, countries)=>{
  const country = countries.find(e => e.id === t.country_id);
  t.country = country || "";
}

class Team extends React.Component {

  constructor(props) {
    super(props);
      
    this.state = {
      showLoading: true,
      matches: this.props.matches,
      team: this.props.team
    }
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }


  render() {
    const {matchesKickOff} =  this.props
   
    if (this.state.matches){
         
      if (matchesKickOff){
          for (var mindex = 0; mindex < this.state.matches.length; mindex++) {
            var found = matchesKickOff[0].find(m=> m.id === this.state.matches[mindex].id)
            if (found){
              this.state.matches[mindex] = found
            }
          }
      }

      return(      
        <div  className="content">
          {this.state.matches.map((match, index) => <MatchLight extended={true} id={match.id} key={match.id} match={match} i={index}/>)} 
        </div>
      )
   }
    
   return <IonLoading
      cssClass='my-custom-class'
      isOpen={this.state.showLoading}
      onDidDismiss={() => this.setShowLoading(false)}
      message={'loading...'}
      duration={5000}
    />;
  }
}

export default connect(state => ({
  update: state.app.update,
  matchesKickOff: state.app.kickoffMatches,
}), null)(Team)

