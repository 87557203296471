import React from 'react';
import { Link } from 'react-router-dom';
import {doGet, competitionsUrl} from '../../data/Protocol'
import "../../css/layout.css"
import { connect } from 'react-redux';
import { IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading } from '@ionic/react';
import { addCompetitionToFavorites, isCompetitionFavorite } from '../../data/Favorites';
import { addCompetitionToFilter, addCountryToFilter, isCompetitionFiltered, removeCountryFromFilter } from '../../data/Filters';
import { arrowDownOutline } from 'ionicons/icons';
import { checkmarkCircle } from 'ionicons/icons';

///////////////////////////     COMPONENT

class CompetitionsFilteredList extends React.Component {
  constructor(props) {
    super(props);
      
    this.state = {
      showLoading: true,
      countries: [],
      selected_country: -1
    }

  }

  componentDidMount = () => {
    if (this.state.countries.length === 0){
      this.getCompetitions(); 
      this.setState({selected_country: 0})  
    }
  }

  getCompetitions = async () => {    
    doGet(competitionsUrl(), this) 
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }


  networkSuccess(url, resp) { 
    var allcountries = []
    if (resp != null){
      this.setShowLoading(false)
      if (resp.result && resp.result.countries && resp.result.countries.list){
        var countries = resp.result.countries.list
        var comps = resp.result.competitions.list

        var index = 0
        var p 
        countries.map(c => {
          if (!c.popularity)
            c.popularity = 0
          else
            c.popularity = Number(c.popularity)
          
          c.filtered = 0
          for (index = 0; index < allcountries.length; index++) {
              p = allcountries[index]
              if (c.popularity < p.popularity){  
                continue
              }else{
                if (c.popularity === p.popularity){  
                  if (p.name > c.name )
                    break
                }else{
                    break
                }
              }
          }
          allcountries.splice(index, 0, c);
        }        
      )
      comps.map(c => {
        var cc = countries.find(e => e.id === c.country_id);
        if (!cc.competitions)
          cc.competitions = []
        cc.competitions.push(c)

        if (!isCompetitionFiltered(this.props.filters, c.id))
          cc.filtered++
        }
      )

      this.setState({
          countries: allcountries,
        })
  
      }
    }
  }

  networkError(url,status){
  }

  manageFilters(type, country){
    if (type)
      addCountryToFilter(this, country)
    else
      removeCountryFromFilter(this, country)
  }

  openCountry(id){
    if (id === this.state.selected_country)
      this.setState({selected_country: 0})
    else
      this.setState({selected_country: id})
  }


  renderRow(country, i){
    const { favorites } = this.props
    
    country.filtered = 0
    country.competitions.map(c => {
      if (!isCompetitionFiltered(this.props.filters, c.id)){
        country.filtered++
        c.filtered = false
      }else{
        c.filtered = true
      }
    })

    let all = country.filtered === country.competitions.length ? true : false
    return ( 
      <IonList key={i} style={{background: '#fff'}} lines="none" mode="ios">

        <IonItem key={"c" + i} className="item grey">
          <IonIcon size="small" onClick={() => this.manageFilters(all ? true : false, country) } className="padding-left" color={ !all ? "medium" : "secondary"} icon={checkmarkCircle}></IonIcon>
          <img className="countryImg padding-left" src={country.flag_url_medium} alt="" onClick={() => this.openCountry(country.id)}/>
          <IonLabel className={"competitionName "} onClick={() => this.openCountry(country.id)}>{country.name}</IonLabel>
          <p className="compsCounter" slot="end">{country.filtered}/{country.competitions.length}</p>
          <IonImg style={{width:'20px'}} slot="end" className="padding-left" src={arrowDownOutline}></IonImg>
        </IonItem>

        {country.id === this.state.selected_country && country.competitions.map((cc, i) => 
        {
          let filtered = false
          let selected = ""
          if (cc.filtered){
            filtered = true
            selected = "selected"
          }
          return(
          <IonItem key={i} style={{display: 'flex', height:'30px', alignItems:'center'}}>
            <IonIcon size="small" onClick={() => addCompetitionToFilter(this, cc)} className="padding-left" color={ filtered ? "medium" : "secondary"} icon={checkmarkCircle}></IonIcon>
            <IonLabel className="competitionName"><span>{cc.name}</span></IonLabel>
            <svg onClick={() => addCompetitionToFavorites(this, cc)} style={{marginLeft: '5px'}} xmlns="http://www.w3.org/2000/svg" fill={isCompetitionFavorite(favorites, cc.id) ? "#0B1F64" : "#fff"} viewBox="0 0 24 24" stroke="#0B1F64" >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>            
          </IonItem>
          )}
        )}
      </IonList>            
    )
  }


  render_filter(){
    const { update_favorites, update_filters } = this.props
    const { countries } = this.state

      return (
        <div key={update_favorites + update_filters} className="content">
          {countries.map((country,i) => 
            this.renderRow(country, i)
          )}
        </div>
      );   
  }

  render() {
    const { countries } = this.state
    if (countries.length > 0){
      return (this.render_filter())
    }

      return (
        <IonLoading
          cssClass='my-custom-class'
          isOpen={this.state.showLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={'loading...'}
          duration={5000}
        />
      );

    }
} // class

export default connect(state => ({
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites,
  filters: state.app.filters,
  update_filters: state.app.update_filters
}), null)(CompetitionsFilteredList)

//export default MatchList
