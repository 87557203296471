import React from 'react';
//import { Link } from 'gatsby';

import { homeTeamName, guestTeamName, competitionName, getResult } from "../../data/MatchData"

class H2HCell extends React.Component {

  render() {
    const { match } = this.props;
  

    const unixTime = match.start_date;
    const date = new Date(unixTime*1000);
   // console.log(date.toLocaleDateString("en-US"));

    return (
          <div className="h2h-list">
            <div className="list-title">{competitionName(match)} - {date.toLocaleDateString("it-IT", { year: 'numeric', month: 'short', day: '2-digit' })}</div>
            <div className="list-club-result">
            <div className="left-club">{homeTeamName(match)}</div>
            <div className="result">{getResult(match)}</div>
            <div className="right-club">{guestTeamName(match)}</div>
          </div>
        </div>
    )
  }

}

//     var details_url = "/matchDetails/" + match.id ;
//      <Link to={details_url} >  
//      </Link>

export default H2HCell;
