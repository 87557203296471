import React from "react"
import {SITE} from '../global_variables'

import F24HeaderEndirect from "./F24HeaderEndirect"
import F24HeaderF24 from "./F24HeaderF24"

class F24Header extends React.Component {

  render(){
    return (SITE == 'FUTBOL24') ?
          <F24HeaderF24  {...this.props}/>
            :
          <F24HeaderEndirect  {...this.props}/>
    }
}

export default F24Header
