import React from 'react';

import "../../css/layout.css"
import TabsHeader from '../TabsHeader';
import Team from './Team';
import {doGet, teamUrl} from '../../data/Protocol'
import { completeCompetition, completeLeague } from "../Competitions/Competition";
import { completeMatch } from "../../data/MatchData";
import { completeTeam } from "../Teams/Team";
import { IonLoading } from '@ionic/react';

///////////////////////////     COMPONENT

class TeamContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: true,
      tab: 1,
      fixtures: null,
      results: null
    }
  }

  componentDidMount = () => {
    this.getTeam() 
  }

  getTeam = async () => {    
    doGet(teamUrl(this.props.id), this) 
  }

  setShowLoading(show){
    this.setState({showLoading: show})
  }

  buildMatches(resp){
    let fixtures = []
    let results = []
      let team = null

      let competitions = resp.result.competitions.list;
      let leagues = resp.result.leagues.list;
      let countries = resp.result.countries.list;
      let teams = resp.result.teams.list;
  
      var today = new Date();

      competitions.forEach(c => {
          completeCompetition(c, countries);
      });
  
      leagues.forEach(l => {
          completeLeague(l, competitions);
      });
  
      teams.forEach(t => {
          completeTeam(t, countries);
          if (Number(t.id) === Number(this.props.id)){
            team = t
          }
      });
  

      resp.result.matches.list.forEach(m => {  
        var epoch = new Date(m.start_date * 1000);
        var offset = epoch.getTimezoneOffset() * 60;
        var start_ts = new Date((m.start_date-offset) * 1000)
        var start = start_ts.toISOString().slice(0, 10);
        var diff = start_ts - today
        
        completeMatch(m, resp.result);          

        if (diff >= 0){
          for (var index = 0; index < fixtures.length; index++) {
            var dist = m.start_date - fixtures[index].start_date            
            if (dist < 0)
              break;
          }
          fixtures.splice(index, 0, m)
        }
  
        if (diff < 0){
          for (var index = 0; index < results.length; index++) {
            var dist = m.start_date - results[index].start_date            
            if (dist > 0)
              break;
          }
          results.splice(index, 0, m)  
        }
      })
    
      this.setState({
        fixtures: fixtures,
        results: results,
        team: team
      })
  
      //this.setState({matches: resp.result.matches.list})
    }
  

  networkSuccess(url, resp) { 
    
    if (resp !== null){
      this.setShowLoading(false)
      this.buildMatches(resp)
    }
  }

  networkError(url,status){
  }

  setTab(param){
    param[0].setState({tab: param[1]})
  }

  render() {

    var {tab} = this.state

    var tab0 ={ 
      name: "Results",
      cb: this.setTab,
      params: [this, 0],
      selected: tab === 0 ? "selected" : ""
    }

    var tab1 ={ 
      name: "Fixtures",
      cb: this.setTab,
      params: [this, 1],
      selected: tab === 1 ? "selected" : ""
    }

    var tabs = []
    tabs.push(tab0);
    tabs.push(tab1);

    if (this.state.team){
      return (
        <div className="content">
          {this.state.team && <h1>{this.state.team.name}</h1>}
          <TabsHeader tabs={tabs}/>
          {tab === 0 && <Team id={this.props.id} type={0} team={this.state.team} matches={this.state.results}/>} 
          {tab === 1 && <Team id={this.props.id} type={1} team={this.state.team} matches={this.state.fixtures}/>}
        </div>
      );
    }

    return(
      <IonLoading
        cssClass='my-custom-class'
        isOpen={this.state.showLoading}
        onDidDismiss={() => this.setShowLoading(false)}
        message={'loading...'}
        duration={5000}
      />)
  }
} // class

export default TeamContainer

