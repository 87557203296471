import React from 'react';
import { Drivers, Storage } from '@ionic/storage';
import {getPublisherAsset, getPublisherOddsAsset, getDictionary, isPublisherInfo} from '../Advertising/Advertising'
import {PROTOCOL, HOST} from '../../data/Protocol'

class OddsCell extends React.Component {

  constructor(props) {
    super(props);
    const store = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
    store.create();
    
    this.state = {
      UserVoted: -1,
      storage: store
    }
  }

  componentDidMount () {
    if (this.state.UserVoted === -1)
      this.UserVoted()    
  }

  UserVoted = async () => {
    const {match, tab} = this.props;
    var type = tab;
    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
    await storage.create();

    try {
        const predictions_text = await storage.get('predictions');

        if (predictions_text) {
            var predictions = JSON.parse(predictions_text)

            var i = predictions.findIndex((e) => e.match === match.id && e.type === type)
            if (i >= 0){
                this.setState({UserVoted: predictions[i].vote})
            }else{
                this.setState({UserVoted: 0})
            }
        }else
            this.setState({UserVoted: 0})
    } catch (error) {
      this.setState({UserVoted: -2})
      console.log("ODDS ROW check pred ERR " + error)
    }
  }

  openUrl(url){
    if (url && url.length > 0)
      window.open(url, "_blank")
  }

  render() {
    const {campaign, provider, tab} = this.props 
    //console.log("ODD ROW pid: " + provider.provider_id )

    let pa = getPublisherAsset(campaign, provider.provider_id);
    let logo = pa.logo
    let bgcolor = pa.bgcolor

    let o = null
    let jolly = null

    if (provider.odds){
      
      for (var i=0; i< provider.odds.length; i++){
        if (provider.odds[i].type_id === 0){
          jolly = provider.odds[i]
        }
        if (provider.odds[i].type_id === tab){
          o = provider.odds[i]
          break;
        }
      }
      if (!o)
        o = jolly
      if (o){

        let values = o.list

        let passets = getPublisherOddsAsset(campaign, provider.provider_id)
        let asset_action_url = null
        if (passets && passets.publisher_action)
          asset_action_url = passets.publisher_action

        return (
        <tr>
        <td className="quote">
          <div>
              {values[0]['values'].map(v => {
                var url
                
                if (v.action){
                  url = PROTOCOL + HOST + v.action
                }else{
                  url = asset_action_url                  
                }
                let odd_name = getDictionary(campaign,v.name)

                if (isPublisherInfo(campaign, provider.provider_id)){
                  return(                    
                      <div key={odd_name} style={{alignContent: "center", alignItems:"center", textAlign: "center", fontSize: '10px', background: '#fff', color:'#8c8c8c'}}>
                        {odd_name}
                      </div>              
                  )
                }

                let voted = this.state.UserVoted
                let light = false
                var dstyle = {}
                if (tab === 1 ){
                  
                  if (v.name === "1" && voted === "h"){
                    light = true
                  }
                  else if (v.name === "2" && voted === "g")
                    light = true
                  else if (v.name === "X" && voted === "d")
                    light = true
                }
                if (light){
                  console.log("ODDS ROW  voted " + voted + " tab: " + tab + " n: " + v.name)
                  dstyle = {background:'#ed670f' , color: '#fff'}
                }

                return(
                  
                  <div key={v.name} className='quote-box' style={dstyle} onClick={() => this.openUrl(url)}>
                    <span className='quote-text'>{odd_name}</span>
                    <span className='quote-text-value'><strong>{v.value}</strong></span>
                  </div>
                
                )
              }
              )}
          </div>
        </td>
        <div className="logo" onClick={() => this.openUrl(asset_action_url)}>
          <img src={logo} alt="" style={{backgroundColor: bgcolor}}/>
        </div>
        </tr>
      )
      }
    }
    console.log("ODDS ROW empty")
    return(
        <tr>
        <td className="quote">
          -
        </td>
        <td className="logo">
        </td>
        </tr>
      )
    }
}

export default OddsCell
