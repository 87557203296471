import React from 'react';
import { Link } from 'react-router-dom';
import {getStatusLabel, isPlaying, getResult, getFHResult, F24TMatchUpdateScores, F24TMatchUpdateStatus, F24TMatchUpdateScoreH, F24TMatchUpdateScoreG, 
  F24TMatchUpdateRedCardsH, F24TMatchUpdateRollbackRedCardsG, F24TMatchUpdateRedCardsG,
   F24TMatchUpdateRollbackScoreG, F24TMatchUpdateRollbackScoreH, F24TMatchUpdateRollbackRedCardsH, redCards } from '../../data/MatchData';
import { homeTeamName, guestTeamName } from "../../data/MatchData";
import { connect } from 'react-redux';
import ico_match_event_goal from "../../images/ico_match_event_goal.png" 
import ico_match_event_goal_canceled from "../../images/ico_match_event_goal_cancel.png" 
import ico_match_event_card_red from "../../images/ico_match_event_card_red.png" 
import { IonItemDivider } from '@ionic/react';
import { addMatchToFavorites, isMatchFavorite } from '../../data/Favorites';

import stats_svg from "../../images/stats.svg"

class Match extends React.Component {


getResultBox(result){
  const { match } = this.props;

  if (result === "")
  return (
    
    <div className="inner-stats">
        <img src={stats_svg} alt=""/>
        
    </div>
    
  ) 


  let r = getResult(match)
    return(
      <div>
      <p className='scores'>{r}</p><p className='subscores'>{getFHResult(match)}</p>
      </div>
    )
}

getCardsIcon(home){
  const {match} = this.props
  var icons = []

  var n = (home) ? redCards(match.home) : redCards(match.guest);
  for (var i=0; i < n; i++)
    icons.push(1);
    
  return (
    icons.map( i => <img key={i} style={{width:'12px', marginLeft:'1px'}} src={ico_match_event_card_red} alt=""/>)
  )
}

render() {
    const { match, i, favorites, update_favorites } = this.props;

    var isFav = isMatchFavorite(favorites, match)
    var event_on = false
    var ccolor = '#' + match.league.competition.background;
    var cell1_class = "cell-name"
    var cell2_class = "cell-name"
    var team1_class = "team-name1"
    var team1_hl_class = ""
    var team2_class = "team-name2"
    var team2_hl_class = ""

    if (match.changes & (F24TMatchUpdateScoreH | F24TMatchUpdateRollbackScoreH | F24TMatchUpdateRedCardsH | F24TMatchUpdateRollbackRedCardsH)){
      //console.log("MATCH_UPDATE score h " + homeTeamName(match))
      team1_hl_class = "highlighted-team"
      cell1_class = cell1_class + " highlighted"
      event_on = true
    }
    if (match.changes & (F24TMatchUpdateScoreG | F24TMatchUpdateRollbackScoreG | F24TMatchUpdateRedCardsG | F24TMatchUpdateRollbackRedCardsG)){
      //console.log("MATCH_UPDATE score g " + guestTeamName(match))
      team2_hl_class = "highlighted-team"
      cell2_class = cell2_class + " highlighted"
      event_on = true
    }

    var details_url = "/match/" + match.id ;
    //var row_class = (i % 2) ? "row bg-bold" : "row"
    var row_class = "row bg-bold"
    var result = getResult(match)

    var fav_color = isFav ? "#0B1F64" : "#fff";

    var end_icon = <svg xmlns="http://www.w3.org/2000/svg" fill={fav_color} viewBox="0 0 24 24" stroke="#0B1F64" onClick={() => addMatchToFavorites(this, match)}>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
          </svg>

    if (match.changes & F24TMatchUpdateScores){
      end_icon = <span className="soccer-ball"><img src={ico_match_event_goal} alt=""/></span>
    }
    if (match.changes & (F24TMatchUpdateRollbackScoreG | F24TMatchUpdateRollbackScoreH)){
      end_icon = <span className="soccer-ball"><img src={ico_match_event_goal_canceled} alt=""/></span>
    }
    if (match.changes & ( F24TMatchUpdateRedCardsH | F24TMatchUpdateRedCardsG )){
      end_icon = <span className="soccer-ball"><img src={ico_match_event_card_red} alt=""/></span>
    }

    var epoch = new Date(match.start_date * 1000);
    var offset = epoch.getTimezoneOffset() * 60;
    var start = new Date((match.start_date-offset) * 1000);;
    start = start.toISOString().slice(0, 10);
    var numcolor = isPlaying(match) ? '#E30001' : '';
    return (
      <div>
        {this.props.extended && <div style={{fontSize:'11px', color:'grey'}}>{start} - {match.league.name}</div>}
        <div id={update_favorites} className={row_class}>  
          <div className="row_left">
          <Link to={details_url}>
            <div className="empty" style={{backgroundColor: ccolor}}></div>
            <p className="number" style={{color: numcolor}}>{getStatusLabel(match)}</p>
            <div className={cell1_class}>
            <p className={team1_class}>
              {!event_on && this.getCardsIcon(1)}
              <span className={team1_hl_class}>{homeTeamName(match)}</span>
            </p>
            </div>
            <div className='resultBox'>
            {this.getResultBox(result)}
            </div>
            <div className={cell2_class}>
              <div className={team2_class}>
                  <span className={team2_hl_class}>{guestTeamName(match)}</span>{!event_on && this.getCardsIcon(0)}
              </div>
            </div>

            </Link>
          </div>
          <div className='star'>
          {end_icon}
          </div>
        </div>
        {this.props.extended && <IonItemDivider></IonItemDivider>}
      </div>
    )
  }
}

export default connect(state => ({
  favorites: state.app.favorites,
  update_favorites: state.app.update_favorites
}), null)(Match)

//export default Match;
