import React from "react"
import Odds from "./Odds";
//import InnerHTML from 'dangerously-set-html-content'
//import postscribe from 'postscribe';

import {
  IonApp,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';

import { connect } from 'react-redux';
import { setAdCampaign } from '../../state/app';
import { getAdCampaign} from '../../data/Protocol'
import Match from "../Matches/Match";
import { randomBytes } from "crypto";
import { Helmet } from "react-helmet"
import menu_telegram from "../../images/menu_tg.png"

//import AspectRatio from 'react-aspect-ratio';

const TYPE_BANNER     = 1;
const TYPE_ADMOB      = 51;
const TYPE_ADAPPTR    = 50;
const TYPE_WEBVIEW    = 2;
const TYPE_WEBCONTENT = 3;


const addAdScript = ({ src, id, onLoad }) => {
  const existing = document.getElementById(id);
  if (existing) {
    console.log("loaded: exists");
    return existing;
  } else {
    console.log("loaded: creating script");
    var script = document.createElement("script");
    script.setAttribute("id", "AdScript");
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => {
      if (onLoad) {
        onLoad();
      }
    };
    
    const delem = document.getElementById('AdBox');   
    delem.appendChild(script);
    
    return script;
  }
};


const addScript2 = ({ src, id, onLoad }) => {
  const existing = document.getElementById(id);
  /*if (existing) {
    console.log("loaded: exists");
    return existing;
  } else */{
    console.log("loaded: creating script");
    var script = document.createElement("script");
    script.setAttribute("id", "AdScript");
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => {
      if (onLoad) {
        onLoad();
      }
    };
    document.body.appendChild(script);
    
    return script;
  }
};


///// CAMPAIGN
export const campaignInfo = (campaign) => {
  if (campaign){
    return JSON.parse(campaign.user_info)
  }
  return null
}

export const getPublisherInfo = (campaign, pub) => {
  if (campaign){
    var info = campaignInfo(campaign)
    if (info && info.assets.publishers){
      for (const [index, el] of Object.entries(info.assets.publishers)) {
        if ( index === pub ) {     
          return el
        }
      }
    }
    if (info && info.assets.publisher){
          return info.assets.publisher
    }
  }
  return null
}

export const getDictionary = (campaign, text) => {
  if (campaign){
    var info = campaignInfo(campaign)
    if (info && info.odds_dictionary){
      for (const [index, el] of Object.entries(info.odds_dictionary)) {
        if ( "%"+index+"%" === text ) {     
          return el
        }
      }
    }
  }
  return text
}

export const getPublisherLogo = (campaign, pub) => {
  var pubInfo = getPublisherInfo(campaign, pub)
  if (pubInfo)
    return pubInfo.logo
  return null
}

export const getPublisherAsset = (campaign, pub) => {
  var pubInfo = getPublisherInfo(campaign, pub)
  if (pubInfo){
    return {logo: pubInfo.logo, bgcolor: pubInfo.bgc}
  }
  return null
}

export const isPublisherInfo = (campaign, pub) => {
  var pubInfo = getPublisherInfo(campaign, pub)
  if (pubInfo)
    return pubInfo.info === 1 ? true: false
  
  return false
}

////// ASSETS

export const getPublishers = (advertisingCampaign, tag) => {   
  var asset = getAssetTag(advertisingCampaign, tag)
  var pubs = null
  if (asset){
    if (asset.user_info){
      var ui = JSON.parse(asset.user_info)

      if (ui.publishers){
        Object.entries(ui.publishers).map(([key,value])=>{
          //if (!isPublisherInfo(advertisingCampaign, key))
            pubs = (pubs) ? pubs + "-" + key : key
            return 0
        })
        return pubs
      }
      if (ui){
        return ui.publisher
      }
    }
  }
  return null
}


export const getPublisherOddsAsset = (campaign, pub) => {
  
  var asset = getAssetTag(campaign, "odds")
  if (asset){
    if (asset.user_info){
      var ui = JSON.parse(asset.user_info)
      var p_assets = null
      if (ui.publishers){
        Object.entries(ui.publishers).map(([key,value])=>{
          if (key === pub)
          p_assets = value
          return 0
        })
        return p_assets
      }
    }
  }
  return null
}

const getAssetTag = (advertisingCampaign, tag) => {
  
  var asset = null

  if (advertisingCampaign.assets && advertisingCampaign.assets.list && advertisingCampaign.assets.list.length){
    let list = advertisingCampaign.assets.list
    for (var i = 0; i < list.length; i++) {
      if (list[i].tag === tag || (list[i].tags && list[i].tags.includes(tag)) || list[i].tag === "*"){
        asset = list[i]
        break;
      }
    } 
  }
  return asset
}

//////////////////   COMPONENT

class Advertising extends React.Component {

  constructor(props) {
    super(props);
      
    this.state = {
      div: 0,
      tg: null
    }
    
  }

  networkSuccess(url, resp) { 
    const { dispatch } = this.props;

    if (url.includes("ad.gluak.com")){
      //console.log("AD RESET DIV")
      //postscribe('#ad', "", {done: this.adLoaded})

      //const element = document.getElementById("main_banner");
      //if (element)
    //        element.remove();



      if (resp.result.betting && resp.result.betting.tg){
        this.setState({tg: resp.result.betting.tg});
        var but = document.getElementById("tab-button-telegram");
        if (but)
          but.style.display = 'block';      
      }
      else{
        this.setState({tg: null});
        var but = document.getElementById("tab-button-telegram");
        if (but)
          but.style.display = 'none';     
      }
      dispatch(setAdCampaign(resp.result.campaign));
      //this.setState({div: this.state.div++})
      return;
    } 
  }
  
  adLoaded = () => {
  }

  getAd = async () => {
    getAdCampaign(this);
  }

  loadScript(){
    const { advertisingCampaign } = this.props;    

    if (advertisingCampaign){
      var ass = this.getAsset(1)
      if (ass && ass.length > 0){
        //postscribe('#ad' + this.state.div, ass, {done: this.adLoaded})
      }
    }
  }

  componentDidMount = () => {
    var { advertisingCampaign, tag } = this.props;    

    if (tag === "main"){
      addScript2({
        src: `https://cdn77.v1.oddsserve.com/9b12c500.js`,
        id: "e2ad-script",
        onLoad: () => {
          console.log("e2TagAD script loaded!");
          var script = document.getElementById("e2ad-script");
          script.remove();
  
        },
      });
    }

    if (!tag){
      tag="main";
    }
    
    /*
    if (tag === "bet"){
      var but = document.getElementById("tab-button-telegram");
      if (but)
        but.style.display = 'none';  
    }*/

    if (!advertisingCampaign){
      this.getAd()
      //this.adInterval = setInterval(this.getAd, 10000)
    }

    if (tag === "main"){
      this.loadScript()
    }  
  }
  
  componentDidUpdate = () => {
    var { tag } = this.props;    
    if (!tag)
      tag="main";

    if (tag === "main"){
      this.loadScript()

      //<script async src="https://cdn77.v1.oddsserve.com/9b12c500.js"></script>
      // https://jsfiddle.net/t619hLkv/
      addScript2({
        src: `https://cdn77.v1.oddsserve.com/9b12c500.js`,
        id: "e2ad-script",
        onLoad: () => {
          console.log("e2TagAD script loaded!");
          var script = document.getElementById("e2ad-script");
          script.remove();
  
        },
      });
    }
  }

  componentWillUnmount() {
  }


  getTgUrl(){
    if (this.state.tg){
      window.open(this.state.tg, "_blank");
    }else{
      alert("Tips are not available in your country");
    }
  }

  getAsset(from){
    /*
    var rcc = '<body><a href="https://rek.futbol24.com/ck.php?oaparams=2__bannerid=7186__zoneid=706__cb=aae9ce203c__oadest=https%3A%2F%2Fwww.pravda.com.ua%2F" target="_blank">'
    rcc = rcc + '<img src="https://rek.futbol24.com/images/2b4b917691da9a6217025190988e6c29.gif" width="960" height="150" alt="" title="" border="0"/></a>'
    rcc = rcc + '<div id="beacon_aae9ce203c" style="position: absolute; left: 0px; top: 0px; visibility: hidden;">'
    rcc = rcc + '<img src="https://rek.futbol24.com/lg.php?bannerid=7186&amp;campaignid=542&amp;zoneid=706&amp;loc=http%3A%2F%2Flocalhost%3A8100%2F&amp;cb=aae9ce203c" width="0" height="0" alt="" style="width: 0px; height: 0px;"/></div></body>'
    return <div dangerouslySetInnerHTML={{ __html: rcc }} />;*/


    var { advertisingCampaign, tag } = this.props;
    if (!advertisingCampaign)
      return ""
    if (!tag)
      tag="main";

    var asset = getAssetTag(advertisingCampaign, tag)

    if (asset){
      let type = asset.type_id

      if (type === TYPE_BANNER){
        if (from === 1)
          return null
        
        console.log("[AD] found BANNER ")

        let action_url = asset.action_url;
        let image_url = asset.resource_urls.high;
        if (image_url){
          return (
            <a style={{width:'100%'}} href={action_url}>
              <img style={{width:'100%'}} className="" id="img" src={image_url} alt="show"/>
            </a>
            )
        }else{
          if (asset.resource_urls){
            return (
              <a href={action_url} style={{width:'100%'}}>
                <img style={{width:'100%'}} className="" id="img" src={asset.resource_urls.low} alt="show"/>
              </a>
              )
          }
        }
      }
      
      if (type === TYPE_WEBCONTENT){
          let resource_content
          let rc = asset.resource_content

          let bstart = rc.indexOf("<body>");
          if (bstart >= 0){
            var startPos = bstart + "<body>".length;
            var endPos = rc.indexOf("</body>");
            resource_content = rc.substring(startPos,endPos);
            //console.log("WEB_CONTENT body: " + startPos + "-" + endPos)
          }else{
            resource_content = rc
          }

          resource_content = rc.substring(startPos,endPos).trim();
          let isScript = resource_content.indexOf("<script") >= 0

          /*
          if (from === 1)
            return (isScript) ? resource_content : null
          */

          if (!isScript){
            console.log("[AD] found WEB_CONTENT noscript: " + resource_content)
            return <div dangerouslySetInnerHTML={{ __html: resource_content }} />;
            //return <InnerHTML html={resource_content}></InnerHTML>
          }else{
            /*var rch = resource_content.replace("<script","<Helmet><script");
            rch = rch.replace("</script>", "</Helmet></script>")*/
            console.log("[AD] found WEB_CONTENT script: " + resource_content)
            var myRegex = /<script[^>]+src="(https:\/\/[^">]+)"/g;
            var results = myRegex.exec(resource_content);
            var source = results[1];
            console.log("SCRIPT SRC " + source);

/*
            addAdScript({
              src: source,
              id: "ad-script",
              div: "main_banner",
              onLoad: () => {
                console.log("Ad script loaded!");
              },
            });*/
            
            return null;  //<div dangerouslySetInnerHTML={{ __html: resource_content }} />;            
          }
      }
    }
    return ""
  }

  render (){
    
    var { advertisingCampaign, tag } = this.props;
    if (!tag)
      tag="main";

    if (tag === "main"){
      /*
      const delem2 = document.getElementById('AdBox');
      if (delem2)
      delem2.style.display = 'none';
        */

      return <div id="main_banner" className="main_banner"><ins className="eccc554d" data-key="13faf8b24a36f47a9e579504554f437c"></ins></div>;
      

      return <ins class="eccc554d" data-key="65f2fe1dc5735a998119ca1be99f5677"></ins>;       
      return <div id="main_banner" className="main_banner"><ins class="eccc554d" data-key="65f2fe1dc5735a998119ca1be99f5677"></ins></div>;



      const delem = document.getElementById('AdBox');
      var html = this.getAsset(0);
      if (html){
        if (delem)
          delem.style.display = 'none';
        return <div id="main_banner" className="main_banner">{html}</div>
      }else{
        if (delem)
          delem.style.display = 'flex';
      }
      
//var a = "<div id='container' class='imageContainer'><Helmet><script language='javascript' src='https://track.adform.net/adfscript/?bn=38335503'></script><Helmet><div id='beacon_8ff24bc989' style={{position: 'absolute', left: '0px', top: '0px', visibility: 'hidden'}}><img src='https://rek.futbol24.com/lg.php?bannerid=5924&amp;campaignid=443&amp;zoneid=687&amp;cb=8ff24bc989' width='0' height='0' alt='' style={{width: '0px', height: '0px'}} /></div></div>"
//return <div dangerouslySetInnerHTML={{ __html: a }} />


      //return (<div className="main_banner">{this.getAsset(0)}</div>)  
      //return <a href='https://rek.futbol24.com/ck.php?n=a3d2daff&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://rek.futbol24.com/avw.php?zoneid=706&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a3d2daff' border='0' alt='' /></a>

      /*return <div><ins data-revive-zoneid="706" data-revive-id="5b88586dda01ff6d7b675603f5768134"></ins>
      <script async src="//rek.futbol24.com/asyncjs.php"></script></div>*/
      

      const sr = 'https://rek.futbol24.com/afr.php?zoneid=706&cb='+ Math.random()
      const ar = 'https://rek.futbol24.com/ck.php?n=af315314&cb=' + Math.random()
      const imsr = 'https://rek.futbol24.com/avw.php?zoneid=706&cb='+ Math.random() +'&n=af315314'       
    
      var resource_content = "<iframe style='width: 100%'  id='a6a8737c' name='a6a8737c' src="+sr+" frameBorder='0' scrolling='no'><a href="+ar+" target='_blank'><img src="+imsr+" border='0' alt='' /></a></iframe>"
      return <div dangerouslySetInnerHTML={{ __html: resource_content }} />;

      var resource_content = "<iframe style={{ width: '100%' }}  id='a6a8737c' name='a6a8737c' src={sr} frameBorder='0' scrolling='no'><a href={ar} target='_blank'><img src={imsr} border='0' alt='' /></a></iframe>"
      return <html><meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, user-scalable=no"/><head></head><div id="ad0" className="main_banner">
        <iframe style={{ width: '100%' }}  id='a6a8737c' name='a6a8737c' src={sr} frameBorder='0' scrolling='no'><a href={ar} target='_blank'><img src={imsr} border='0' alt='' /></a></iframe></div></html>

      /*
      const sr = 'http://rek.futbol24.com/afr.php?zoneid=684&amp;cb='+ Math.random()
      const ar = 'http://rek.futbol24.com/ck.php?n=a0034c43&amp;cb=' + Math.random()
      const imsr = 'http://rek.futbol24.com/avw.php?zoneid=684&amp;cb=' + Math.random() +'&amp;n=a0034c43'
      return <iframe id='adb7c7d2' name='adb7c7d2' src={sr} frameborder='0' scrolling='no'><a href={ar} target='_blank'><img src={imsr} border='0' alt='' /></a></iframe>
      */
      // from gluak ad server
      return (<div className="main_banner">{this.getAsset(0)}</div>)  
    }
    if (tag === "bet"){
        return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} onClick={() => this.getTgUrl()}>
                <IonImg style={{width:'18px'}} src={menu_telegram} />              
                <IonLabel className='menuLabel'>BETTING</IonLabel>
              </div>
    }

    if (tag === "odds"){
      if (advertisingCampaign){      
        return (
          <Odds campaign={advertisingCampaign} {...this.props}/>
        )  
      }
      return ""
    }
  }
}

export default connect(state => ({
  advertisingCampaign: state.app.advertisingCampaign
}), null)(Advertising)

//export default Advertising
