
import { Drivers, Storage } from '@ionic/storage';
import { setFavorites } from '../state/app'

export const readFavorites = async (context) => {  
  if (context.props.favorites)
    return
    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
  await storage.create();
  
  try {
    const favorites_json = await storage.get('favorites');
    console.log("STORAGE init favorites: " + favorites_json)
    var favorites = {
      matches: {
        competitions: [],
        list: [],                
      },
      competitions: {
        list: [],
      },
      teams: {
        list: [],
      }
    }

    if (favorites_json){
      
      favorites = JSON.parse(favorites_json)
      if (favorites.matches === null)
        favorites.matches = {list:[],competitions: []}
        if (favorites.competitions === null)
        favorites.competitions = {list:[]}
      if (favorites.teams === null)
        favorites.teams = {list:[]}

    }
    context.props.dispatch(setFavorites(favorites));   

  } catch (error) {
      console.log("STORAGE error: " + error)
  }
}

export const getCompetitionsFavorites = (favorites) => {
  if (favorites && favorites.competitions){
    return favorites.competitions.list
  }
  return null
}

export const getTeamsFavorites = (favorites) => {
  if (favorites && favorites.teams){
    return favorites.teams.list
  }
  return null
}

export const isMatchFavorite = (favorites, match) => {

  if (favorites && favorites.matches){
    const i = favorites.matches.list.findIndex((e) => e === match.id);
    if (i >= 0){
      return true
    }
    return (match.league && match.league.competition && isCompetitionFavorite(favorites, match.league.competition.id))
           || (match.teamHome && isTeamFavorite(favorites, match.teamHome.id))
           || (match.teamGuest && isTeamFavorite(favorites, match.teamGuest.id))
  }

  return false
}

export const isCompetitionFavorite = (favorites, id) => {

  if (favorites && favorites.competitions){
    const i = favorites.competitions.list.findIndex((e) => e === id);
    if (i >= 0){
      return true
    }
  }

  return false
}

export const isTeamFavorite = (favorites, id) => {

  if (favorites && favorites.teams){
    const i = favorites.teams.list.findIndex((e) => e === id);
    if (i >= 0){
      return true
    }
  }

  return false
}

export const addMatchToFavorites = async (context, match) => {
  var favorites = context.props.favorites
  
  console.log("[FAV][MATCH] ADD TO STORAGE")
  
  try {
    if (favorites){
      console.log("[FAV][MATCH] storage active")
      
      const i = favorites.matches.list.findIndex((e) => e === match.id);
      if (i >= 0){
        console.log("[FAV][MATCH] match already in storage ")
        console.log("[FAV][MATCH] remove match from storage: " + match.id)

        favorites.matches.list.splice(i, 1);

        const c = favorites.matches.competitions.findIndex((e) => e.id === match.league.competition.id);
        console.log("[FAV][MATCH] remove match check comp : " + match.league.competition.id  + " pos: " + c)

        if (c >= 0){
          var n = favorites.matches.competitions[c].n - 1
  
          console.log("[FAV][MATCH] comp exists: " + match.league.competition.id + " matches: " + n)
          if (n < 0)
            favorites.matches.competitions.splice(c,1);
          else
            favorites.matches.competitions[c]={id: match.league.competition.id, n: n};
        }
        const storage = new Storage({
          name: 'f24',
          driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
        });
        await storage.create();
        context.props.dispatch(setFavorites(favorites)); 
        await storage.set('favorites', JSON.stringify(favorites));
        
        return;
      }

    }else{
      console.log("[FAV][MATCH] storage empty")
      console.log("[FAV][TEAM]  storage empty")
      favorites = {
        matches: {
          competitions: [],
          list: [],
        }, 
        competitions:{
          list: [],
        },
        teams:{
          list:[]
        }
      }
    }
    console.log("[FAV][MATCH] add match to storage: " + match.id)

    favorites.matches.list.push(match.id);

    const i = favorites.matches.competitions.findIndex((e) => e.id === match.league.competition.id);
    if (i < 0){
      console.log("[FAV][MATCH] comp non exists: " + match.league.competition.id)
      favorites.matches.competitions.push({id: match.league.competition.id, n: 0});
    }else{
      var n = favorites.matches.competitions[i].n + 1
      console.log("[FAV][MATCH] comp exists: " + match.league.competition.id + " add: " + n)
      favorites.matches.competitions[i]={id: match.league.competition.id, n: n};
    }

    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
    await storage.create();
    context.props.dispatch(setFavorites(favorites)); 
    await storage.set('favorites', JSON.stringify(favorites));
    
} catch (error) {
  console.log("[FAV][MATCH] STORAGE error: " + error)
}
}

export const addCompetitionToFavorites = async (context, competition) => {
  var favorites = context.props.favorites
  
  console.log("[FAV][COMP] ADD TO STORAGE")
  
  try {
    if (favorites){
      console.log("[FAV][COMP]  storage active")
      
      if (favorites.competitions){
        const i = favorites.competitions.list.findIndex((e) => e === competition.id);
        if (i >= 0){
          console.log("[FAV][COMP]  comp already in storage ")
          console.log("[FAV][COMP]  remove comp from storage: " + competition.id)

          favorites.competitions.list.splice(i, 1);
        }else{
          favorites.competitions.list.push(competition.id)
        }
      }else{
        favorites.competitions = {list:[]}
        favorites.competitions.list.push(competition.id)
      }
    }else{
      console.log("[FAV][COMP]  storage empty")
      console.log("[FAV][TEAM]  storage empty")
      favorites = {
        matches: {
          competitions: [],
          list: [],
        }, 
        competitions:{
          list: [],
        },
        teams:{
          list:[]
        }
      }
      console.log("[FAV][COMP]  init: " + JSON.stringify(favorites, null, 2))
      favorites.competitions.list.push(competition.id);
    }
    console.log("[FAV][COMP]  add comp to storage: " + competition.id)

    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
    await storage.create();
    context.props.dispatch(setFavorites(favorites)); 
    await storage.set('favorites', JSON.stringify(favorites));
    //this.setState({updated: this.state.updated++})
} catch (error) {
  console.log("[FAV] STORAGE error: " + error)
}
}


export const addTeamToFavorites = async (context, team) => {
  var favorites = context.props.favorites

  console.log("[FAV][TEAM] ADD TO STORAGE")
  
  try {
    if (favorites){
      console.log("[FAV][TEAM]  storage active")
      
      if (favorites.teams){
        const i = favorites.teams.list.findIndex((e) => e === team.id);
        if (i >= 0){
          console.log("[FAV][TEAM]  team already in storage ")
          console.log("[FAV][TEAM]  remove team from storage: " + team.id)

          favorites.teams.list.splice(i, 1);
        }else{
          favorites.teams.list.push(team.id)
        }
      }else{
        favorites.teams = {list : []}
        favorites.teams.list.push(team.id)
      }
    }else{
      console.log("[FAV][TEAM]  storage empty")
      favorites = {
        matches: {
          competitions: [],
          list: [],
        }, 
        competitions:{
          list: [],
        },
        teams:{
          list:[]
        }
      }
      console.log("[FAV][TEAM]  init: " + JSON.stringify(favorites, null, 2))
      favorites.teams.list.push(team.id);
    }
    console.log("[FAV][TEAM]  add team to storage: " + team.id)

    const storage = new Storage({
      name: 'f24',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    });
    await storage.create();
    context.props.dispatch(setFavorites(favorites)); 
    await storage.set('favorites', JSON.stringify(favorites));
    //this.setState({updated: this.state.updated++})
} catch (error) {
  console.log("[FAV][TEAM] STORAGE error: " + error)
}
}
