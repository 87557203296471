import React from "react"
import H2HCell from "./H2HCell"

import { completeMatch } from "../../data/MatchData"
import { completeCompetition, completeLeague } from "../Competitions/Competition";
import { completeTeam } from "../Teams/Team";
import {homeTeamId, guestTeamId, homeTeamName, guestTeamName, getHomeResult, getGuestResult} from "../../data/MatchData"

class H2H extends React.Component {


    H2h(home, guest){
        const {match} = this.props;
        if (match.statistics.head_to_head && match.statistics.head_to_head.matches
             && match.statistics.head_to_head.matches.list
             && match.statistics.head_to_head.matches.list.length > 0){

            var homeName = homeTeamName(match);
            var guestName = guestTeamName(match);
            var h_won = 0;
            var g_won = 0;
            var drawn = 0;
            var played = 0;

            match.statistics.head_to_head.matches.list.forEach(m => {
                played++;
                let h_id = homeTeamId(m)
                let hg = getHomeResult(m)
                let gg = getGuestResult(m)

                //console.log("MATCH " + h_id + ": " + hg + " - " + gg)

                if (hg > gg){
                    if (h_id === home)
                        h_won++
                    else
                        g_won++
                }else if (hg < gg){
                    if (h_id === home)
                        g_won++
                    else
                        h_won++
                }else {
                    drawn++
                }
            });
            var hwp  = Math.round(h_won / played * 100)
            var gwp  = Math.round(g_won / played * 100)
            var dp = 100-(hwp+gwp)

            return(
                <div className="h2h">
                    <div>
                        <div className="top">
                            <div className="club-name">{homeName} win</div>
                            <div className="win-percentage">{hwp}%</div>
                            <div className="line white">
                                <div className="line-orange right" style={{width: hwp + "%"}}/>
                            </div>
                            <div className="win-number">{h_won}</div>
                        </div>
                        <div className="top">
                            <div className="club-name">draw</div>
                            <div className="win-percentage">{dp}%</div>
                            <div className="line white">
                                <div className="line-orange grey right " style={{width: dp + "%"}}/>
                            </div>
                            <div className="win-number">{drawn}</div>
                        </div>
                        <div className="top">
                            <div className="club-name">{guestName} win</div>
                            <div className="win-percentage">{gwp}%</div>
                            <div className="line white">
                                <div className="line-orange right " style={{width: gwp + "%"}}/>
                            </div>
                            <div className="win-number">{g_won}</div>
                        </div>
                    </div>
                </div>            
            )
        }
        
        return ""
    }

    render (){
        const {match} = this.props;
        if (match){
            if (match.statistics.head_to_head && match.statistics.head_to_head.matches
                && match.statistics.head_to_head.matches.list
                && match.statistics.head_to_head.matches.list.length > 0){
   
                var home = homeTeamId(match);
                var guest = guestTeamId(match);

                var data = match.statistics.head_to_head
                            
                if (data.competitions && data.competitions.list)
                    data.competitions.list.forEach(c => {
                        completeCompetition(c, data.countries.list);
                    });
            
                if (data.leagues && data.leagues.list)
                    data.leagues.list.forEach(l => {
                    completeLeague(l, data.competitions.list);
                });
            
                if (data.teams && data.teams.list)
                data.teams.list.forEach(t => {
                    completeTeam(t, data.countries.list);
                });

                if (data.matches && data.matches.list)
                data.matches.list.forEach(m => {
                    completeMatch(m, data)
                });

                return (
                <div className="h2h">
                    <div className="page-title">Head to head</div>    
                    <div className="statistics-content">
                        {this.H2h(home, guest)}
                    </div>
                    {data.matches.list.map(m=>                            
                       <H2HCell key={m.id} match={m}/>
                    )}
                </div>
                )
            }
        }

        return("")
    }
}

export default H2H;