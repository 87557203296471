import React from 'react';
import Match from "../Matches/Match"
import icon_next from "../../images/row_header_next.png"
import icon_ended from "../../images/row_header_ended.png"
import icon_live from "../../images/row_header_live.png"
import ExternalOdds from '../Advertising/ExternalOdds';

class KickoffMatches extends React.Component {

  showList(list, title, icon, checkdate) {
    if (list.length > 0)
    return(
      <div style={{margin: '0 5px 15px 5px'}}>
        <h1>
          <div><p style={{float: 'left'}}>{title}</p><p style={{textAlign: 'right'}}>[{list.length}]</p></div>
        </h1>
        {list.map((match, index) =>
        <div> 
          <Match key={match.id} match={match} i={index}/>
          {/*index==0 && <ExternalOdds></ExternalOdds>*/}
        </div>
        )}
      </div>
    )
    return null;
  }

  render(){
    const { matches, liveOnly } = this.props;
    const live = matches[0];
    const next = matches[1];
    const ended = matches[2];


    return (
      <div>
        {this.showList(live, "LIVE", icon_live, 0) }
        {this.showList(next, "SCHEDULED", icon_next, 0) }
        {this.showList(ended, "ENDED", icon_ended, 0) }
      </div>
      );
      
  }
}

export default KickoffMatches;
